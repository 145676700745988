import './ProfileSummary.scss'
import React, {} from 'react';
import Avatar from "../Avatar";

type Props = {
    extraText?: string
    profile: Profile
}

const ProfileSummary: React.FC<Props> = ({
    profile,
    extraText
}) => {
    const classYearElement = profile?.details?.find(detail => detail.element?.code === 'gradyear');
    const classYear = classYearElement ? classYearElement.elementDetail.displayText : null;
    return (
        <div className="c-profileSummary">
            {profile && (
                <>
                    {profile.artifact && (
                        <Avatar className="c-profileSummary__avatar" artifact={profile.artifact} />
                    )}
                    <div className="c-profileSummary__info">
                        <h3 className="c-profileSummary__name">
                            {profile.firstName} {profile.lastName}
                        </h3>
                        {classYear && (
                            <p>Class of {classYear}</p>
                        )}
                        {extraText && (
                            <p>{extraText}</p>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}
export default ProfileSummary;
