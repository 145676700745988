import './FeedPostAndComment.scss';

import React from 'react';
import { useHistory, useRouteMatch } from "react-router";
import classNames from "classnames";
import moment from "moment";

import Thread from "../../../types/Thread";

import buildImageSrc from "../../../utils/buildImageSrc";
import { isObjectNullOrEmpty } from "../../../utils/utils";

import Card from "../../Card";
import H6 from "../../H6";
import ParagraphsFromNewlines from "../../ParagraphsFromNewlines";
import TableMoreMenu from "../../Table/TableMoreMenu";
import ThreadComment from "../../ThreadComment";

type Props = {
    className?: string
    thread: Thread
}

const FeedPostAndComment: React.FC<Props> = ({
    className,
    thread,
}) => {
    const match = useRouteMatch();
    const history = useHistory();

    if(isObjectNullOrEmpty(thread) || thread.type !== 'T') {
        return null;
    }

    const classes = classNames(
        'c-feed-post-and-comment c-feed-item',
        className,
        {
            'a-feed-item--flagged': thread.focusComment ? thread.focusComment.flagged : thread.flagged,
            'a-feed-item--removed-by-mods': thread.removedByMods || thread.focusComment?.removedByMods,
            'a-feed-item--super-approved': thread.focusComment ? thread.focusComment.superApproval : thread.superApproval,
        }
    );

    return (
        <Card className={classes}>
            <header className="c-feed-post-and-comment__header">
                <div className="c-feed-post-and-comment__group">
                    <strong>{isObjectNullOrEmpty(thread.focusComment) ? 'Post' : 'Comment'}</strong> in <strong onClick={() => history.push(`/school/${match?.params?.schoolId}/group/${thread.forumTopic.forumTopicId}/activity`)}>{thread.forumTopic.name}</strong> on {moment(thread.publishedAt).format('MMM DD, YYYY [at] h:mm A')}
                </div>

                <div className="c-feed-post-and-comment__flagged-at">
                    {thread.flaggedCount > 0 && (
                        <>
                            Flagged {moment(thread.lastFlaggedAt).format('MMM DD, YYYY [at] h:mm A')}
                        </>
                    )}

                    <TableMoreMenu
                        item={thread}
                        onlyModerationOptions
                    />
                </div>
            </header>

            {thread.artifacts && thread.artifacts[0] && (
                <img className="c-feed-post-and-comment__artifact" src={buildImageSrc(thread.artifacts[0], '667')} alt="posted on abuzz" />
            )}

            {thread.title != null && (
                <H6>
                    {thread.title}
                </H6>
            )}

            <ParagraphsFromNewlines
                text={thread.description}
            />

            {!isObjectNullOrEmpty(thread.focusComment) && (
                <ThreadComment
                    comment={thread.focusComment}
                    disableReply
                    isReply={false}
                    thread={thread}
                />
            )}
        </Card>
    );
};

export default FeedPostAndComment;
