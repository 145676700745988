import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import clone from 'clone';

import { getStudentGroups } from "../../../../../../store/slices/students";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import ActivityIndicator from "../../../../../../components/ActivityIndicator";
import Feed from "../../../../../../components/Feed";
import TableFlaggedOnlyToggle from "../../../../../../components/Table/TableFlaggedOnlyToggle";

const ViewStudentGroups: React.FC = () => {
    const dispatch = useAppDispatch();

    const [atEnd, setAtEnd] = useState<boolean>(false);
    const [isInfiniteScrolling, setIsInfiniteScrolling] = useState<boolean>(false);

    const { isGettingStudentGroups, student, studentGroups, studentGroupsMetadata } = useTypedSelector((state) => state.students);

    const handleFlaggedFilterChange = async (filterByFlaggedOnly) => {
        try {
            let clonedMetadata = clone(studentGroupsMetadata);
            clonedMetadata.flagged = filterByFlaggedOnly;
            clonedMetadata.page_num = 0;
            let res = await dispatch(getStudentGroups({profileId: student.profileId, schoolId: student.tenant.tenantId, studentGroupsMetadata: clonedMetadata})).unwrap();
            setAtEnd(res.isAtEnd);
        } catch(err) {
            console.log('ViewStudentGroups handleFlaggedFilterChange err', err);
        }
    };

    const handleInfiniteScroll = async () => {
        if(isGettingStudentGroups || isInfiniteScrolling || atEnd) {
            return;
        }

        try {
            setIsInfiniteScrolling(true);
            let res: any = await dispatch(getStudentGroups({isUpdate: true, profileId: student.profileId, schoolId: student.tenant.tenantId})).unwrap();
            setAtEnd(res.isAtEnd);
        } catch(err) {
            console.log('ViewStudentGroups handleInfiniteScroll err', err);
            setAtEnd(true);
        } finally {
            setIsInfiniteScrolling(false);
        }
    };

    return (
        <div className="view-student__posts view-student__content">
            <TableFlaggedOnlyToggle
                onToggleChange={handleFlaggedFilterChange}
                value={studentGroupsMetadata.flagged}
            />

            {isGettingStudentGroups ? (
                <div className="view-student__activity-indicator">
                    <ActivityIndicator />
                </div>
            ): (
                <>
                    {studentGroups.length === 0 ? (
                        <div className="school-feed__empty">
                            There's nothing here.
                        </div>
                    ) : (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleInfiniteScroll}
                            hasMore={!atEnd}
                        >
                            <Feed
                                items={studentGroups}
                            />

                            {isInfiniteScrolling && (
                                <div className="school-feed__infinite-scrolling-indicator">
                                    <ActivityIndicator color="white" />
                                </div>
                            )}

                            {atEnd && !isInfiniteScrolling && (
                                <div className="school-feed__at-end">
                                    End of feed
                                </div>
                            )}
                        </InfiniteScroll>
                    )}
                </>
            )}
        </div>
    );
};

export default ViewStudentGroups;
