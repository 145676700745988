// const ENV = {
//     demo: {
//         apiUrl: process.env.REACT_APP_API_URL,
//         googleAnalyticsUaCode: 'UA-xxxxxxx-x',
//         websocketUrl: 'https://demo-dm.abuzz.me/',
//     },
//     dev: {
//         apiUrl: process.env.REACT_APP_LOCAL_API ? 'http://abuzzadmin.test:3000/api/' : process.env.REACT_APP_API_URL,
//         googleAnalyticsUaCode: 'UA-xxxxxxx-x',
//         websocketUrl: 'https://dev-dm.abuzz.me/',
//     },
//     staging: {
//         apiUrl: process.env.REACT_APP_API_URL,
//         googleAnalyticsUaCode: 'UA-xxxxxxx-x',
//         websocketUrl: 'https://staging-dm.abuzz.me/',
//     },
//     production: {
//         apiUrl: process.env.REACT_APP_API_URL,
//         googleAnalyticsUaCode: 'UA-xxxxxxx-x',
//         websocketUrl: 'https://dm.abuzz.me/',
//     },
// };
//
// function getActiveEnvironment() {
//     const activeEnvironment = process.env.REACT_APP_ENVIRONMENT;
//
//     if (['demo', 'dev', 'staging', 'production'].indexOf(activeEnvironment) === -1) {
//         throw new Error('Invalid REACT_APP_ENVIRONMENT: ' + activeEnvironment);
//     }
//
//     return activeEnvironment;
// }
//
// // console.log('Export environment: ' + getActiveEnvironment());
// export default ENV[getActiveEnvironment()];
const Environment = {
    apiUrl: process.env.REACT_APP_API_URL,
    googleAnalyticsUaCode: process.env.REACT_APP_GOOGLE_ANALYTICS_UA_CODE,
    websocketUrl: process.env.REACT_APP_WEBSOCKET_URL
}
console.log('Environment: ', Environment);
export default Environment;
