import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import IError from "../../types/IError";
import Metadata from "../../types/Metadata";
import Thread from "../../types/Thread";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import { generateThread, generateThreadsMetadata } from "../../utils/generators";
import { clonePostWithDeletedComment, clonePostWithNewComment } from "../../utils/reducerHelpers";

import { deleteCommentInGroupFeed, updateGroupFeed } from "./groups";
import { RootState } from '../reducers';
import { addCommentToFeed, deleteCommentInFeed } from "./feed";
import { addError } from "./errors";


type DeleteThreadProps = {
    thread?: Thread
}

export const deleteThread = createAsyncThunk(
    'threads/deleteThread',
    async ({thread}: DeleteThreadProps = {}, {dispatch, getState}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            if(!thread) {
                thread = (getState() as RootState).threads.thread;
            }
            console.log('deleting')
            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.threads.delete(tenantId, thread.postId));
            console.log(res);
            return {
                ...res,
                postId: thread.postId,
            };
        } catch(err) {
            console.log('delete thread err', err);
            err.friendlyMessage = 'Error deleting the thread. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

type GetThreadProps = {
    postId?: number | string
    schoolId?: number | string
}
export const getThread = createAsyncThunk(
    'threads/getThread',
    async ({postId, schoolId}: GetThreadProps = {}, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);
            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }
            const res = await new Request(token).get(PATHS.threads.getById(schoolId, postId));
            let thread = res.data.data;
            return thread;
        } catch(err) {
            console.log('getThread', err);
            err.friendlyMessage = 'Error getting this thread. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type SaveThreadProps = {
    thread?: Thread
}

export const saveThread = createAsyncThunk(
    'thread/saveThread',
    async ({thread}: SaveThreadProps, {dispatch, getState}) => {
        const { auth: { token }, schools: { activeSchool: { tenantId , postAsProfile: { profileId } } } } = (getState() as RootState);

        if(!thread) {
            thread = (getState() as RootState).threads.thread;
        }

        if (!thread.includePoll && !thread.postId) {
            delete thread.poll;
        }

        thread = clone(thread);

        thread.forumTopicId = thread.forumTopic.forumTopicId;

        let path = PATHS.threads.create(tenantId);
        let request = new Request(token);
        let reqFunc = request.post;

        if(thread.postId) {
            path = PATHS.threads.update(tenantId, thread.postId);
            reqFunc = request.put;
        } else {
            thread = {
                ...thread,
                tenantId,
                profileId
            };
        }

        try {
            const res = await reqFunc(path, thread);
            dispatch(updateGroupFeed(res.data.data));
            return res;
        } catch(err) {
            console.log('save thread err', err);
            err.friendlyMessage = 'Error saving this thread. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);


type GetThreadsProps = {
    isUpdate?: boolean
    schoolId?: number
    threadsMetadata?: Metadata
}

export const getThreads = createAsyncThunk(
    'threads/getThreads',
    async ({isUpdate, schoolId, threadsMetadata}: GetThreadsProps, {dispatch, getState}) => {
        try {

            if(!threadsMetadata) {
                threadsMetadata = clone((getState() as RootState).threads.threadsMetadata);
            } else {
                threadsMetadata = {...threadsMetadata}
            }
            threadsMetadata.type = 'T';

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.content.getContent(schoolId, buildQueryString(threadsMetadata)));
            let threads = res.data.data.items;
            threadsMetadata.total = res.data.data.meta.total;
            return {threads, threadsMetadata};
        } catch(err) {
            console.log('getThreads', err);
            err.friendlyMessage = 'Error getting the list of threads. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);


type SaveThreadCommentProps = {
    threadId?: number
    comment?: Thread
    parentUuid?: string
}

export const saveThreadComment = createAsyncThunk(
    'thread/saveThreadComment',
    async ({comment, parentUuid, threadId}: SaveThreadCommentProps, {dispatch, getState}) => {
        const { auth: { token }, schools: { activeSchool: { tenantId } } } = (getState() as RootState);

        let path = PATHS.threads.createComment(tenantId, threadId);
        let request = new Request(token);

        const payload = {
            comment,
            parentUuid
        };

        try {
            const res = await request.post(path, payload);
            const result = {...res.data.data, threadId, parentUuid};
            dispatch(addCommentToFeed(result));
            // Removing this on 5/17/2022 because it doesn't appear to be necessary,
            // and leads to duplicate comments in some cases - Paul
            // dispatch(addCommentToGroupFeed(result));
            return result;
        } catch(err) {
            console.log('save comment err', err);
            err.friendlyMessage = 'Error saving this comment. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);


type DeleteThreadCommentProps = {
    threadId?: number | string
    commentUuid?: string
}

export const deleteThreadComment = createAsyncThunk(
    'threads/deleteThreadComment',
    async ({threadId, commentUuid}: DeleteThreadCommentProps = {}, {dispatch, getState}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            await new Request((getState() as RootState).auth.token).delete(PATHS.threads.deleteComment(tenantId, threadId, commentUuid));
            dispatch(deleteCommentInFeed({threadId, commentUuid}))
            dispatch(deleteCommentInGroupFeed({threadId, commentUuid}))
            return {threadId, commentUuid};
        } catch(err) {
            console.log('delete comment err', err);
            err.friendlyMessage = 'Error deleting the comment. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

interface ThreadsState {
    deleteThreadError: IError
    getThreadError: IError
    getThreadsError: IError
    isDeletingThread: boolean
    isGettingThread: boolean
    isGettingThreads: boolean
    isSavingThread: boolean
    saveThreadError: IError
    searchTerm: string
    thread: Thread
    threads: Array<Thread>
    threadsMetadata: Metadata
    isSavingThreadComment: boolean
    saveThreadCommentError: IError
    isDeletingThreadComment: boolean
    deleteThreadCommentError: IError
}

const initialState: ThreadsState = {
    isGettingThread: false,
    getThreadError: undefined,
    isSavingThread: false,
    saveThreadError: {},
    searchTerm: '',
    thread: generateThread(),
    threads: [],
    threadsMetadata: generateThreadsMetadata(),
    isDeletingThread: false,
    isGettingThreads: false,
    deleteThreadError: undefined,
    getThreadsError: undefined,
    isDeletingThreadComment: false,
    deleteThreadCommentError: undefined,
    isSavingThreadComment: false,
    saveThreadCommentError: undefined,
};

export const threadsSlice = createSlice({
    name: 'threads',
    initialState,
    reducers: {
        clearThread: (state) => {
            state.thread = generateThread();
        },
        clearThreadsMetadata: (state) => {
            state.threadsMetadata = generateThreadsMetadata();
            state.searchTerm = '';
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setThread: (state, action) => {
            state.thread = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteThread.pending, (state) => {
            state.deleteThreadError = undefined;
            state.isDeletingThread = true;
        });
        addCase(deleteThread.fulfilled, (state, action) => {
            state.isDeletingThread = false;
            state.thread = generateThread();
        });
        addCase(deleteThread.rejected, (state, action) => {
            state.deleteThreadError = action.error as IError;
            state.isDeletingThread = false;
        });

        addCase(getThread.pending, (state) => {
            state.getThreadError = undefined;
            state.isGettingThread = true;
        });
        addCase(getThread.fulfilled, (state, action) => {
            state.isGettingThread = false;
            state.thread = action.payload;
        });
        addCase(getThread.rejected, (state, action) => {
            state.getThreadError = action.error as IError;
            state.isGettingThread = false;
        });

        addCase(getThreads.pending, (state, action) => {
            state.getThreadsError = undefined;
            state.isGettingThreads = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.threadsMetadata) {
                state.threadsMetadata = action.meta.arg.threadsMetadata;
            }
        });
        addCase(getThreads.fulfilled, (state, action) => {
            state.threads = action.payload.threads;
            state.threadsMetadata = action.payload.threadsMetadata;
            state.isGettingThreads = false;
        });
        addCase(getThreads.rejected, (state, action) => {
            state.getThreadsError = action.error;
            state.isGettingThreads = false;
        });

        addCase(saveThread.pending, (state) => {
            state.isSavingThread = true;
            state.saveThreadError = undefined;
        });
        addCase(saveThread.fulfilled, (state, action) => {
            state.isSavingThread = false;
            state.thread = generateThread();
        });
        addCase(saveThread.rejected, (state, action) => {
            state.saveThreadError = action.error as IError;
            state.isSavingThread = false;
        });

        addCase(saveThreadComment.pending, (state) => {
            state.saveThreadCommentError = undefined;
            state.isSavingThreadComment = true;
        });
        addCase(saveThreadComment.fulfilled, (state, action) => {
            state.isSavingThreadComment = false;
            if (state.thread.threadComments) {
                state.thread = clonePostWithNewComment(state.thread, action.payload.parentUuid, action.payload);
            }
        });
        addCase(saveThreadComment.rejected, (state, action) => {
            state.saveThreadCommentError = action.error as IError;
            state.isSavingThreadComment = false;
        });

        addCase(deleteThreadComment.pending, (state) => {
            state.deleteThreadCommentError = undefined;
            state.isDeletingThreadComment = true;
        });
        addCase(deleteThreadComment.fulfilled, (state, action) => {
            state.isDeletingThreadComment = false;
            state.thread = clonePostWithDeletedComment(state.thread, action.payload.commentUuid);
        });
        addCase(deleteThreadComment.rejected, (state, action) => {
            state.deleteThreadCommentError = action.error as IError;
            state.isDeletingThreadComment = false;
        });
    }
});

export const { clearThread, clearThreadsMetadata, setSearchTerm, setThread } = threadsSlice.actions;

export default threadsSlice.reducer;
