import './SchoolsList.scss';

import React, { useCallback, useEffect, useMemo } from 'react';
import clone from 'clone';
import { useHistory, useRouteMatch } from "react-router";

import TableColumn from "../../../types/TableColumn";

import { CAPABILITIES, hasRole, ROLE_STRINGS, ROLES, userCan } from "../../../utils/roles";
import { isArrayNullOrEmpty } from "../../../utils/utils";

import { clearSchoolsMetadata, getSchools, setActiveSchool, setSearchTerm } from '../../../store/slices/schools';
import { useAppDispatch } from "../../../store";
import { useTypedSelector } from '../../../store/reducers';

import ActivityIndicator from "../../../components/ActivityIndicator";
import Avatar from '../../../components/Avatar';
import H5 from "../../../components/H5";
import Icon from "../../../components/Icon";
import PageHeader from "../../../components/PageHeader";
import Pill from "../../../components/Pill";
import Table from "../../../components/Table";
import TableFilterRow from "../../../components/Table/TableFilterRow";
import TableSearch from "../../../components/TableSearch";


type Props = {
    onRowClick?: Function
}

const SchoolsList: React.FC<Props> = ({
    onRowClick
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const { roles } = useTypedSelector(store => store.auth);
    const { isGettingSchools, schools, schoolsMetadata, searchTerm } = useTypedSelector(store => store.schools);

    useEffect(() => {
        return () => {
            dispatch(clearSchoolsMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getSchools({schoolsMetadata: { ...schoolsMetadata, page_num: 0, }}));
            } catch(err) {
                console.log('SchoolsList initialize err', err);
            }
        };

        if(hasRole(roles, ROLES.SUPER_ADMIN)) {
            initialize();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roles]);

    const columns = useMemo(
        () => {
            let columnsArray: Array<TableColumn> = [
                {
                    Header: 'Name',
                    id: 'school',
                    Cell: ({row: { original }}) => (
                        <div className="schools-table__school">
                            <Avatar
                                className="schools-table__avatar"
                                artifact={original.artifact}
                                flagged={original.flagged || original.flaggedContent}
                            />

                            <div className="schools-table__name">
                                {original.name}
                            </div>
                        </div>
                    ),
                    hasImage: true,
                },
            ];

            if(hasRole(roles, ROLES.SUPER_ADMIN)) {
                columnsArray = [
                    ...columnsArray,
                    {
                        Header: 'Location',
                        accessor: (d) => {
                            if(!d.city || !d.state) {
                                return null;
                            }

                            return `${d.city}, ${d.state}`;
                        }
                    }, {
                        Header: 'Admin Users',
                        accessor: ''
                    }, {
                        Header: 'Student Users',
                        accessor: (d) => {
                            return d?.lifetimeStats?.details?.find((ls) => ls.type === 'headcount').value
                        }
                    }, {
                        Header: 'Active Deals',
                        accessor: (d) => {
                            return d?.lifetimeStats?.details?.find((ls) => ls.type === 'deal_count').value
                        }
                    }, {
                        Header: 'Status',
                        id: 'status',
                        Cell: ({row: { original }}) => {
                            if(original.status !== 'A') {
                                return null;
                            }

                            return (
                                <Pill type="active" />
                            )
                        },
                    },
                ];

                /*if(!onRowClick) {
                    columnsArray.push({
                        Header: '',
                        id: 'more',
                        Cell: ({row: { original }}) => (
                            <Button
                                className="c-table__more-button"
                                noStyles
                            >
                                <Icon type="more" />
                            </Button>
                        )
                    });
                }*/
            } else {
                columnsArray = [
                    ...columnsArray, {
                        Header: 'Role',
                        accessor: (d) => ROLE_STRINGS[d.roleType]
                    }
                ]
            }
            return columnsArray;
        },
        [roles]
    );

    const data = useMemo(
        () => {
            if (hasRole(roles, ROLES.SUPER_ADMIN)) {
                return schools;
            }
            let schoolArray = roles.map((role) => {
                let tenant = {
                    ...role.tenant,
                    roleType: role.type
                };
                return tenant;
            });
            if (searchTerm) {
                schoolArray = schoolArray.filter((school) => school.name.toLowerCase().includes(searchTerm.toLowerCase()));
            }
            return schoolArray;
        },
        [roles, schools, searchTerm]
    );

    const handleRowPress = ({original: school}) => {
        if(!onRowClick) {
            dispatch(setActiveSchool(school));
            if (userCan(CAPABILITIES.VIEW_SCHOOL_DASHBOARD, school.roleType)) {
                history.push(`/school/${school.tenantId}/student/dashboard`);
            } else {
                history.push(`/school/${school.tenantId}/student/feed`);
            }
        } else {
            onRowClick(school);
        }
    };

    const onMetadataChange = async (changes, isUpdate) => {
        if (!hasRole(roles, ROLES.SUPER_ADMIN)) {
            return;
        }

        try {
            let clonedMetadata = clone(schoolsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            return await dispatch(getSchools({isUpdate, schoolsMetadata: clonedMetadata}));
        } catch(err) {
            console.log('SchoolsList onMetadataChange err', err);
        }
    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="schools-list">
            <PageHeader>
                <H5>
                    <Icon type="navigation-tenants" />

                    Schools
                </H5>
            </PageHeader>

            <TableFilterRow>
                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search School Names"
                    searchTerm={searchTerm}
                    size="small"
                />
            </TableFilterRow>

            {isGettingSchools || (isArrayNullOrEmpty(data) && searchTerm.length === 0) ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && searchTerm.length > 0 ? (
                        <div className="c-table__no-results">
                            No results found for "{searchTerm}"
                        </div>
                    ) : (
                        <Table
                            columns={columns}
                            data={data}
                            getData={onMetadataChange}
                            pageNumber={schoolsMetadata.page_num}
                            pageSize={schoolsMetadata.page_size}
                            sortBy={schoolsMetadata.sort}
                            sortOrder={schoolsMetadata.order}
                            totalNumberOfItems={schoolsMetadata.total}
                            onRowClick={handleRowPress}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default SchoolsList;
