import React, { useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import Profile from '../../../../../../types/Profile';

import { isArrayNullOrEmpty } from '../../../../../../utils/utils';

import { clearAssignStudentTagsError } from '../../../../../../store/slices/students';
import { clearDeleteAlumniError, deleteAlumni } from '../../../../../../store/slices/alumni';
import { setNewRecipient } from '../../../../../../store/slices/directMessages';
import { useAppDispatch } from '../../../../../../store';
import { useTypedSelector } from '../../../../../../store/reducers';

import ErrorInline from '../../../../../Errors/ErrorInline';
import ManageStudentTags from '../../../SchoolStudents/ManageTags';
import Modal from '../../../../../../components/Modal';
import SuccessModal from '../../../../../../components/Modal/SuccessModal';
import TableMoreMenu from '../../../../../../components/Table/TableMoreMenu';

type Props = {
    getAfterDelete?: () => void
    item: Profile
}

const AlumniTableMoreMenu: React.FC<Props> = ({
    getAfterDelete,
    item,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {params} = useRouteMatch();

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [showTagsModal, setShowTagsModal] = useState<boolean>(false);
    const [showTagsSuccessModal, setShowTagsSuccessModal] = useState<boolean>(false);

    const { postAsProfile } = useTypedSelector((state) => state.schools.activeSchool);
    const { deleteAlumniError , isDeletingAlumni} = useTypedSelector((state) => state.alumni);

    const handleCloseManageTagsModal = () => {
        setShowTagsModal(false);
        dispatch(clearAssignStudentTagsError());
    };

    const handleDeleteAlumni = async () => {
        try {
            await dispatch(deleteAlumni({profileId: item.profileId})).unwrap();
            setShowConfirmDeleteModal(false);
            getAfterDelete && getAfterDelete();
        } catch (err) {
            console.log('AlumniTableMoreMenu handleDeleteAlumni err', err);
        }
    };

    const customActions = useMemo(
        () => {
            let actions = [];

            const itemIsMe = postAsProfile?.profileId === item.profileId;

            if (postAsProfile && !itemIsMe) {
                actions.push({
                    icon: 'dms',
                    onClick: () => {
                        if (item.conversationId) {
                            history.push(`/school/${params.schoolId}/alumni/messages/conversation/${item.conversationId}`);
                        } else {
                            dispatch(setNewRecipient(item));
                            history.push(`/school/${params.schoolId}/alumni/messages/conversation/new`);
                        }
                    },
                    text: 'Message',
                });

                actions.push({
                    icon: 'user-remove',
                    onClick: async () => {
                        setShowConfirmDeleteModal(true);
                    },
                    text: 'Remove',
                });
            }

            actions.push({
                icon: 'tag',
                onClick: async () => {
                    setShowTagsModal(true);
                },
                text: 'Manage Tags',
            });

            return actions;

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [],
    );

    if (isArrayNullOrEmpty(customActions)) {
        return null;
    }

    return (
        <>
            <TableMoreMenu
                customOptions={customActions}
                getAfterModerate={getAfterDelete}
                item={item}
                type="profile"
            />

            <Modal
                declineButtonOnClick={handleCloseManageTagsModal}
                show={showTagsModal}
                title="Manage Student Tags"
            >
                <ManageStudentTags
                    closeModal={handleCloseManageTagsModal}
                    user={item}
                    onSuccess={() => {
                        setShowTagsModal(false);
                        setShowTagsSuccessModal(true);
                    }}
                />
            </Modal>

            <SuccessModal
                buttonOnClick={() => {
                    setShowTagsSuccessModal(false);
                }}
                buttonText="Done"
                show={showTagsSuccessModal}
                title="Nice job!"
            >
                <p>
                    Tags have been modified.
                </p>
            </SuccessModal>

            <Modal
                confirmButtonOnClick={handleDeleteAlumni}
                confirmButtonText="Remove"
                declineButtonOnClick={() => {
                    setShowConfirmDeleteModal(false);
                    dispatch(clearDeleteAlumniError());
                }}
                declineButtonText="Cancel"
                show={showConfirmDeleteModal}
                showActivityIndicator={isDeletingAlumni}
                title="Are you sure?"
            >
                <p>
                    Are you sure you want to delete this alumni account? This action cannot be undone.
                </p>

                <ErrorInline error={deleteAlumniError}/>
            </Modal>
        </>
    );
};

export default AlumniTableMoreMenu;
