
import React, {useEffect,  useState} from 'react';
import { DragDropContext, Droppable, Draggable, OnDragEndResponder } from "react-beautiful-dnd";
import {useRouteMatch } from 'react-router';

import {
    deleteGivingCategory,
    getGivingCategories,
    reorderGivingCategories,
    setGivingCategory,
    setGivingCategories,
    featureGivingCategoryOrOpportunity,
    unFeatureGivingCategoryOrOpportunity
} from "../../../../../store/slices/giving";
import {useAppDispatch} from "../../../../../store";
import {useTypedSelector} from "../../../../../store/reducers";


import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Button, {ButtonSizes, ButtonThemes} from "../../../../../components/Button";
import Card from "../../../../../components/Card";
import GivingCategory from "../../../../../types/GivingCategory";
import Icon from "../../../../../components/Icon";
import Modal from "../../../../../components/Modal";
import TableRowImage from "../../../../../components/Table/TableRowImage";


type Props = {
    handleEditClick: Function,
}
const GivingCategories: React.FC<Props> = ({handleEditClick}) => {
    const dispatch = useAppDispatch();
    const [initialized, setInitialized] = useState(false);
    const match = useRouteMatch();
    const {
        isDeletingGivingCategory,
        isGettingGivingCategories,
        givingCategory,
        givingCategories,
        isFeaturingGivingCategoryOrOpportunity,
        isUnFeaturingGivingCategoryOrOpportunity,
    } = useTypedSelector((state) => state.giving);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
    const [showFeatureValidationModal, setShowFeatureValidationModal] = useState<boolean>(false);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getGivingCategories({schoolId: match?.params?.schoolId})).unwrap();
            } catch(err) {
                console.log('SchoolGiving initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        if (!initialized) {
            initialize();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDelete = async () => {
        console.log('Giving Category handleDelete' );
        try {
            await dispatch(deleteGivingCategory({})).unwrap();

            dispatch(getGivingCategories({}));
        } catch (err) {
            console.warn('Giving Category handleDelete err', err)
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    const handleSaveNewOrder = async (reorderedCategories) => {
        try {
            const newOrder = reorderedCategories.map((q) => q.givingCategoryId);
            await dispatch(reorderGivingCategories({categoryIds: newOrder}));
        } catch(err) {
            console.warn('Giving Categories handleSaveNewOrder err', err);
        }
    };

    const onDragEnd = (result: OnDragEndResponder) => {
        // a little function to help us with reordering the result
        const reorder = (list: Array<GivingCategory>, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };

        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedCategories: Array<GivingCategory> = reorder(
            givingCategories,
            result.source.index,
            result.destination.index,
        );

        dispatch(setGivingCategories(reorderedCategories));
        handleSaveNewOrder(reorderedCategories);
    };

    const handleFeatureClick = (category: GivingCategory) => {
        dispatch(setGivingCategory(category));

        if (!category.artifact || !category.description) {
            setShowFeatureValidationModal(true);
            return;
        }
        dispatch(featureGivingCategoryOrOpportunity({givingCategoryId: category.givingCategoryId}));
    }

    const handleUnFeatureGivingOpportunity = () => {
        dispatch(unFeatureGivingCategoryOrOpportunity());
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>

            {isGettingGivingCategories ? (
                <ActivityIndicator />
            ) : (
                <>
                    {givingCategories && (
                        <Card className="givingCategory__categories-container">

                            <Droppable droppableId="givingCategory-list">
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {givingCategories.map((cat, i) => (
                                            <Draggable
                                                draggableId={cat.givingCategoryId.toString()}
                                                index={i}
                                                key={cat.givingCategoryId}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        className="draggableItems__created-row"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className="c-draggableItem-row">
                                                            <div className="c-draggableItem-row__drag-icon">
                                                                <Icon type="drag" />
                                                            </div>

                                                            {cat.artifact && (
                                                                <TableRowImage
                                                                    artifact={cat.artifact}
                                                                    className="c-draggableItem-row__image"
                                                                />
                                                            )}

                                                            <div className="c-draggableItem-row__content">
                                                                <div className="c-draggableItem-row__title">
                                                                    {cat.name}
                                                                    {cat.featured && (
                                                                        <span title="Featured" className="c-draggableItem-row__title__featured-icon" >
                                                                            <Icon type="star" />
                                                                        </span>
                                                                    )}
                                                                </div>

                                                                <div className="c-draggableItem-row__link">
                                                                    {cat.description}
                                                                </div>
                                                            </div>

                                                            <div className="c-draggableItem-row__actions">

                                                                {cat.featured ? (
                                                                    <Button
                                                                        className="destructive"
                                                                        size={ButtonSizes.Small}
                                                                        theme={ButtonThemes.Secondary}
                                                                        showActivityIndicator={isUnFeaturingGivingCategoryOrOpportunity && givingCategory.givingCategoryId === cat.givingCategoryId}
                                                                        onClick={() => handleUnFeatureGivingOpportunity()}
                                                                    >
                                                                        Un-Feature
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        onClick={() => handleFeatureClick(cat)}
                                                                        size={ButtonSizes.Small}
                                                                        showActivityIndicator={isFeaturingGivingCategoryOrOpportunity && givingCategory.givingCategoryId === cat.givingCategoryId}
                                                                    >
                                                                        Feature
                                                                    </Button>
                                                                )}
                                                                <Button
                                                                    onClick={() => handleEditClick(cat)}
                                                                    size={ButtonSizes.Small}
                                                                >
                                                                    Edit
                                                                </Button>

                                                                <Button
                                                                    onClick={() => {
                                                                        dispatch(setGivingCategory(cat));
                                                                        setShowDeleteConfirmationModal(true);
                                                                    }}
                                                                    theme={ButtonThemes.Link}
                                                                    className="destructive"
                                                                    size={ButtonSizes.Small}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </Card>
                    )}
                </>
                )}
            <Modal
                title="Confirm Delete"
                show={showDeleteConfirmationModal}
                confirmButtonOnClick={handleDelete}
                confirmButtonText="Yes, Delete"
                showActivityIndicator={isDeletingGivingCategory}
                declineButtonOnClick={() => setShowDeleteConfirmationModal(false)}
                declineButtonText="Cancel"
            >
                Are you sure you want to delete this category?
            </Modal>

            <Modal
                title="Before you can feature this giving category..."
                show={showFeatureValidationModal}
                confirmButtonOnClick={() => {
                    handleEditClick(givingCategory);
                    setShowFeatureValidationModal(false);
                }}
                confirmButtonText="Edit"
                declineButtonOnClick={() => setShowFeatureValidationModal(false)}
                declineButtonText="Cancel"
            >
                A giving category must have a description and an image to be featured.
            </Modal>


        </DragDropContext>
    );
};

export default GivingCategories;
