import './CreateEditEvent.scss';

import React from 'react';

import { useTypedSelector } from '../../../../store/reducers';

import CreateEditEventForm from './CreateEditEventForm';

type Props = {
    close?: Function
}

const CreateEditEvent: React.FC<Props> = ({
    close
}) => {
    const { event } = useTypedSelector((store) => store.events);

    return (
        <div className="create-edit-event">
            <CreateEditEventForm
                close={close}
                post={event}
            />
        </div>
    );
};

export default CreateEditEvent;
