import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";
import moment from 'moment';
import _isNil from 'lodash/isNil';

import ChatMessage from "../../types/ChatMessage";
import EventPost from "../../types/EventPost";
import Group from "../../types/Group";
import IError from "../../types/IError";
import Metadata from "../../types/Metadata";
import Profile from "../../types/Profile";
import Tag from "../../types/Tag";
import Thread from "../../types/Thread";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import { sanitizeDetails } from "../../utils/elements";
import {
    cloneFeedWithUpdatedPost,
    cloneListWithUpdatedGroup,
    cloneStudentsWithNewTags
} from "../../utils/reducerHelpers";
import { buildErrorObject } from "../../utils/errors";
import {
    generateEventsMetadata,
    generateGroupsMetadata,
    generateProfile,
    generateStudentGroupChatMessagesMetadata,
    generateStudentPostsAndCommentsMetadata,
    generateStudentsMetadata,
} from "../../utils/generators";
import { isArrayNullOrEmpty } from "../../utils/utils";

import { addError } from "./errors";
import { RootState } from '../reducers';
import { moderateContent } from "./moderation";
import { PostTypes } from "../../utils/enums";
import ProfileImport from "../../types/ProfileImport";

type DeleteStudentProps = {
    profileId?: number
    schoolId?: number | string
}

export const deleteStudent = createAsyncThunk(
    'students/deleteStudent',
    async ({profileId, schoolId}: DeleteStudentProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            await new Request((getState() as RootState).auth.token).delete(PATHS.students.delete(schoolId, profileId));
            return profileId;
        } catch(err) {
            console.log('deleteStudent error', err);
            let friendlyMessage = 'Error removing this student. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type GetImportJobsProps = {
    importJobsMetadata?: Metadata
    isUpdate?: boolean
    schoolId?: number | string
}

export const getImportJobs = createAsyncThunk(
    'students/getImportJobs',
    async ({importJobsMetadata, isUpdate, schoolId}: GetImportJobsProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!importJobsMetadata) {
                importJobsMetadata = clone((getState() as RootState).students.importJobsMetadata);
            } else {
                importJobsMetadata = {...importJobsMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.pastImports.getPastImports(schoolId, buildQueryString(importJobsMetadata)));
            let importJobs = res.data.data.items;
            importJobsMetadata.total = res.data.data.meta.total;
            return {importJobs, importJobsMetadata};
        } catch(err) {
            console.log('getImportJobs error', err);
            let friendlyMessage = 'Error getting a list of past import jobs. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type GetAllTagsProps = {
    getAllTagsMetadata?: Metadata
    isUpdate?: boolean
    schoolId?: number | string
}

export const getAllTags = createAsyncThunk(
    'students/getAllTags',
    async ({getAllTagsMetadata, isUpdate, schoolId}: GetAllTagsProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!getAllTagsMetadata) {
                getAllTagsMetadata = clone((getState() as RootState).students.getAllTagsMetadata);
            } else {
                getAllTagsMetadata = {...getAllTagsMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.students.getAllTags(schoolId, buildQueryString(getAllTagsMetadata)));
            let allTags = res.data.data.items;
            getAllTagsMetadata.total = res.data.data.meta.total;
            return {allTags, getAllTagsMetadata};
        } catch(err) {
            console.log('getAllTags error', err);
            let friendlyMessage = 'Error getting a list of tags. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type AssignTagsProps = {
    schoolId?: number
    tags: Array<Tag>
    newTags: Array<string>
    profileId: number | string
}

export const assignTags = createAsyncThunk(
    'students/assignTags',
    async ({schoolId, tags, newTags, profileId}: AssignTagsProps, {dispatch, getState, rejectWithValue}) => {
        if(!schoolId) {
            schoolId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        let payload = {tags, newTags};

        try {
            const res = await new Request((getState() as RootState).auth.token).put(PATHS.students.assignTags(schoolId, profileId), payload);
            return res;
        } catch(err) {
            let friendlyMessage = 'Error assigning tags. Please try again.';
            if (err.response?.data?.message) {
                friendlyMessage = err.response.data.message;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            console.log('errorObject', errorObject);
            return rejectWithValue(errorObject);
        }
    }
);

type GetOnlineStudentsProps = {
    isUpdate?: boolean
    schoolId?: number
    onlineStudentsMetadata?: Metadata
}

export const getOnlineStudents = createAsyncThunk(
    'students/getOnlineStudents',
    async ({isUpdate, schoolId, onlineStudentsMetadata}: GetOnlineStudentsProps, {dispatch, getState}) => {
        try {
            if(!onlineStudentsMetadata) {
                onlineStudentsMetadata = clone((getState() as RootState).students.onlineStudentsMetadata);
            } else {
                onlineStudentsMetadata = {...onlineStudentsMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            if(isUpdate) {
                onlineStudentsMetadata.page_num = onlineStudentsMetadata.page_num + 1;
            } else {
                onlineStudentsMetadata.page_num = 0;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.students.getOnlineStudents(schoolId, buildQueryString(onlineStudentsMetadata)));
            let onlineStudents = res.data.data.items;
            if(isUpdate) {
                onlineStudents = (getState() as RootState).students.onlineStudents.concat(onlineStudents);
            }

            onlineStudentsMetadata.total = res.data.data.meta.total;

            return {onlineStudents, onlineStudentsMetadata, isAtEnd: res.data.data.items.length === 0};
        } catch(err) {
            console.log('getOnlineStudents', err);
            err.friendlyMessage = 'Error getting the list of online students. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);



type GetStudentEventsProps = {
    isUpdate?: boolean
    profileId: number
    schoolId?: number | string
    studentEventsMetadata?: Metadata
}

export const getStudentEvents = createAsyncThunk(
    'students/getStudentEvents',
    async ({isUpdate, profileId, schoolId, studentEventsMetadata}: GetStudentEventsProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            if(!studentEventsMetadata) {
                studentEventsMetadata = clone((getState() as RootState).students.studentEventsMetadata);
            } else {
                studentEventsMetadata = {...studentEventsMetadata};
            }

            studentEventsMetadata.profile_id = profileId;
            studentEventsMetadata.type = 'E';

            if(isUpdate) {
                studentEventsMetadata.page_num++;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.content.getContent(schoolId as number, buildQueryString(studentEventsMetadata)));
            let studentEvents = res.data.data.items;
            studentEventsMetadata.total = res.data.data.meta.total;

            if(isUpdate) {
                studentEvents = (getState() as RootState).students.studentEvents.concat(studentEvents);
            }

            return {studentEvents, studentEventsMetadata, isAtEnd: isArrayNullOrEmpty(res.data.data.items)};
        } catch(err) {
            console.log('getStudentEvents error', err);
            const friendlyMessage = 'Error getting a list of student events. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type GetStudentGroupChatMessagesProps = {
    isUpdate?: boolean
    profileId: number
    schoolId?: number | string
    studentGroupChatMessagesMetadata?: Metadata
}

export const getStudentGroupChatMessages = createAsyncThunk(
    'students/getStudentGroupChatMessages',
    async ({isUpdate, profileId, schoolId, studentGroupChatMessagesMetadata}: GetStudentGroupChatMessagesProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            if(!studentGroupChatMessagesMetadata) {
                studentGroupChatMessagesMetadata = clone((getState() as RootState).students.studentGroupChatMessagesMetadata);
            } else {
                studentGroupChatMessagesMetadata = {...studentGroupChatMessagesMetadata};
            }

            if(isUpdate) {
                studentGroupChatMessagesMetadata.page_num++;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.students.getGroupChatMessages(schoolId, profileId, buildQueryString(studentGroupChatMessagesMetadata)));
            let studentGroupChatMessages = res.data.data.items;
            studentGroupChatMessagesMetadata.total = res.data.data.meta.total;

            if(isUpdate) {
                studentGroupChatMessages = (getState() as RootState).students.studentGroupChatMessages.concat(studentGroupChatMessages);
            }

            const studentGroupChatMessagesByMonth = organizeGroupChatMessagesByMonth(studentGroupChatMessages, studentGroupChatMessagesMetadata.flagged ? 'lastFlaggedAt' : 'createdAt');

            return {studentGroupChatMessages, studentGroupChatMessagesMetadata, studentGroupChatMessagesByMonth, isAtEnd: isArrayNullOrEmpty(res.data.data.items)};
        } catch(err) {
            console.log('getStudentGroupChatMessages error', err);
            const friendlyMessage = 'Error getting a list of student group chat messages. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

const organizeGroupChatMessagesByMonth = (messages: Array<ChatMessage>, keyNameToSortBy: 'createdAt' | 'lastFlaggedAt') => {
    let messagesByDate = {};

    messages.forEach((message) => {
        let date = moment(message[keyNameToSortBy]).format('Y-MM');

        if(messagesByDate[date]) {
            messagesByDate[date].messages.push(message);
        } else {
            messagesByDate[date] = {
                label: moment(message[keyNameToSortBy]).format('MMMM YYYY'),
                messages: [message],
            };
        }
    });

    return messagesByDate;
};

type GetStudentGroupsProps = {
    isUpdate?: boolean
    profileId: number
    schoolId?: number | string
    studentGroupsMetadata?: Metadata
}

export const getStudentGroups = createAsyncThunk(
    'students/getStudentGroups',
    async ({isUpdate, profileId, schoolId, studentGroupsMetadata}: GetStudentGroupsProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            if(!studentGroupsMetadata) {
                studentGroupsMetadata = clone((getState() as RootState).students.studentGroupsMetadata);
            } else {
                studentGroupsMetadata = {...studentGroupsMetadata};
            }

            studentGroupsMetadata.profile_id = profileId;

            if(isUpdate) {
                studentGroupsMetadata.page_num++;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.groups.get(schoolId as number, buildQueryString(studentGroupsMetadata)));
            let studentGroups = res.data.data.items;
            studentGroupsMetadata.total = res.data.data.meta.total;

            if(isUpdate) {
                studentGroups = (getState() as RootState).students.studentGroups.concat(studentGroups);
            }

            return {studentGroups, studentGroupsMetadata, isAtEnd: isArrayNullOrEmpty(res.data.data.items)};
        } catch(err) {
            console.log('getStudentGroups error', err);
            const friendlyMessage = 'Error getting a list of student groups. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type GetStudentPostsAndCommentsProps = {
    isUpdate?: boolean
    profileId: number
    schoolId?: number | string
    studentPostsAndCommentsMetadata?: Metadata
}

export const getStudentPostsAndComments = createAsyncThunk(
    'students/getStudentPostsAndComments',
    async ({isUpdate, profileId, schoolId, studentPostsAndCommentsMetadata}: GetStudentPostsAndCommentsProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            if(!studentPostsAndCommentsMetadata) {
                studentPostsAndCommentsMetadata = clone((getState() as RootState).students.studentPostsAndCommentsMetadata);
            } else {
                studentPostsAndCommentsMetadata = {...studentPostsAndCommentsMetadata};
            }

            if(isUpdate) {
                studentPostsAndCommentsMetadata.page_num++;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.students.getPostsAndComments(schoolId, profileId, buildQueryString(studentPostsAndCommentsMetadata)));
            let studentPostsAndComments = res.data.data.items;
            studentPostsAndCommentsMetadata.total = res.data.data.meta.total;

            if(isUpdate) {
                studentPostsAndComments = (getState() as RootState).students.studentPostsAndComments.concat(studentPostsAndComments);
            }

            const studentPostsAndCommentsByMonth = organizePostsAndCommentsByMonth(studentPostsAndComments, studentPostsAndCommentsMetadata.flagged ? 'lastFlaggedAt' : 'publishedAt');

            return {studentPostsAndComments, studentPostsAndCommentsByMonth, studentPostsAndCommentsMetadata, isAtEnd: isArrayNullOrEmpty(res.data.data.items)};
        } catch(err) {
            console.log('getStudentPostsAndComments error', err);
            const friendlyMessage = 'Error getting a list of student posts and comments. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

const organizePostsAndCommentsByMonth = (threads: Array<Thread>, keyNameToSortBy: 'lastFlaggedAt' | 'publishedAt') => {
    let threadsByDate = {};

    threads.forEach((thread) => {
        let date = moment(thread[keyNameToSortBy]).format('Y-MM');

        if(threadsByDate[date]) {
            threadsByDate[date].threads.push(thread);
        } else {
            threadsByDate[date] = {
                label: moment(thread[keyNameToSortBy]).format('MMMM YYYY'),
                threads: [thread],
            };
        }
    });

    return threadsByDate;
};

type GetStudentByIdProps = {
    hideSpinner?: boolean
    profileId?: number
    schoolId?: number | string
}

export const getStudentById = createAsyncThunk(
    'students/getStudentById',
    async ({hideSpinner, profileId, schoolId}: GetStudentByIdProps, {getState, rejectWithValue}) => {
        try {
            if(!profileId) {
                profileId = clone((getState() as RootState).students.student.profileId);
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.students.getStudentById(schoolId, profileId));
            let student = sanitizeDetails(res.data.data);

            return {student};
        } catch(err) {
            console.log('getStudentById error', err);
            const friendlyMessage = 'Error getting that student profile. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type GetStudentsProps = {
    isUpdate?: boolean
    schoolId?: number | string
    studentsMetadata?: Metadata
}

export const getStudents = createAsyncThunk(
    'students/getStudents',
    async ({isUpdate, schoolId, studentsMetadata}: GetStudentsProps, {dispatch, getState}) => {
        try {
            if(!studentsMetadata) {
                studentsMetadata = clone((getState() as RootState).students.studentsMetadata);
            } else {
                studentsMetadata = {...studentsMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.students.getStudents(schoolId, buildQueryString(studentsMetadata)));
            let students = res.data.data.items;
            students.forEach((s, i) => {
                students[i] = sanitizeDetails(s);
            })
            studentsMetadata.total = res.data.data.meta.total;
            return {students, studentsMetadata};
        } catch(err) {
            console.log('getStudents error', err);
            err.friendlyMessage = 'Error getting a list of students. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type InviteStudentsViaCsvImportProps = {
    csvFile: File
    schoolId?: number | string
}

export const inviteStudentsViaCsvImport = createAsyncThunk(
    'students/inviteStudentsViaCsvImport',
    async ({csvFile, schoolId}: InviteStudentsViaCsvImportProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            let formData = new FormData();
            formData.append('csv', csvFile);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const res = await new Request((getState() as RootState).auth.token).post(PATHS.students.inviteViaCsvImport(schoolId), formData, config);
            return res;
        } catch(err) {
            console.log('InviteStudentsViaCsvImportProps error', err);
            let friendlyMessage = 'Error importing users via CSV. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);



type InviteStudentsViaEmailListProps = {
    forumTopicId?: number
    emailCsv: string
    schoolId?: number
}

export const inviteStudentsViaEmailList = createAsyncThunk(
    'students/inviteStudentsViaEmailList',
    async ({schoolId}: InviteStudentsViaEmailListProps, {dispatch, getState, rejectWithValue}) => {
        if(!schoolId) {
            schoolId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        // Remove spaces
        let emailCsv = (getState() as RootState).students.inviteStudentsViaEmailListCsv;
        emailCsv = emailCsv.replace(/\s/g, '');

        try {
            const res = await new Request((getState() as RootState).auth.token).post(PATHS.students.inviteViaEmail(schoolId), {emails: emailCsv});
            return res;
        } catch(err) {
            let friendlyMessage = 'Error inviting students to Abuzz. Please try again.';
            if (err.response?.data?.message) {
                friendlyMessage = err.response.data.message;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type ReSendPendingInvitesProps = {
    tenantId?: number
    profileImportId?: number
}

export const reSendPendingInvites = createAsyncThunk(
    'students/reSendInvitesPendingAccounts',
    async ({tenantId, profileImportId}: ReSendPendingInvitesProps, {dispatch, getState, rejectWithValue}) => {
        if(!tenantId) {
            tenantId = (getState() as RootState).schools.activeSchool.tenantId;
        }

        try {
            const res = await new Request((getState() as RootState).auth.token).post(PATHS.pastImports.resendinvites(tenantId, profileImportId));
            return res.data;
        } catch(err) {
            let friendlyMessage = 'Error resending invites to pending accounts. Please try again.';
            if (err.response?.data?.message) {
                friendlyMessage = err.response.data.message;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    }
);

type SetAlternateEmailProps = {
    alternateEmail: string
    profileId: number
    schoolId?: number
}

export const setAlternateEmailAddress = createAsyncThunk(
    'students/setAlternateEmailAddress',
    async ({ schoolId, profileId, alternateEmail }: SetAlternateEmailProps, {
        getState,
        rejectWithValue,
    }) => {
        try {
            if (!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            await new Request((getState() as RootState).auth.token).post(
                PATHS.students.setAlternateEmail(schoolId, profileId), {
                    emailAddress: alternateEmail,
                });

        } catch (err) {
            let friendlyMessage = 'Error setting the alternate email address. Please try again.';
            if (err.response?.data?.message) {
                friendlyMessage = err.response.data.message;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            return rejectWithValue(errorObject);
        }
    },
);


interface StudentsState {
    allTags: Array<Tag>,
    assignStudentTagsError: IError,
    deleteStudentError?: IError
    getAllTagsError: IError,
    getAllTagsMetadata: Metadata,
    getImportJobsError?: IError
    getOnlineStudentsError?: IError
    getStudentError?: IError
    getStudentEventsError?: IError
    getStudentGroupChatMessagesError?: IError
    getStudentGroupsError?: IError
    getStudentPostAndCommentsError?: IError
    getStudentsError?: IError
    importJobs: Array<ProfileImport>
    importJobsMetadata: Metadata,
    inviteStudentsViaCsvImportError?: IError
    inviteStudentsViaEmailListCsv: string
    inviteStudentsViaEmailListError?: IError
    isAssigningStudentTags: boolean,
    isDeletingStudent: boolean
    isGettingAllTags: boolean,
    isGettingImportJobs: boolean
    isGettingOnlineStudents: boolean
    isGettingStudent: boolean
    isGettingStudentEvents: boolean
    isGettingStudentGroupChatMessages: boolean
    isGettingStudentGroups: boolean
    isGettingStudentPostsAndComments: boolean
    isGettingStudents: boolean
    isInvitingStudentsViaCsvImport: boolean
    isInvitingStudentsViaEmailList: boolean
    isResendPendingInvites: boolean
    isSettingAlternateEmail: boolean
    onlineStudents: Array<Profile>
    onlineStudentsMetadata: Metadata
    resendPendingInvitesError?: IError
    searchTerm: string
    student: Profile,
    studentEvents: Array<EventPost>
    studentEventsMetadata: Metadata
    studentGroupChatMessages: Array<ChatMessage>
    studentGroupChatMessagesByMonth: any
    studentGroupChatMessagesMetadata: Metadata
    studentGroups: Array<Group>
    studentGroupsMetadata: Metadata
    studentPostsAndComments: Array<Thread>
    studentPostsAndCommentsByMonth: any
    studentPostsAndCommentsMetadata: Metadata
    students: Array<Profile>
    studentsMetadata: Metadata
    studentSetAlternateEmailError?: IError
}

const initialState: StudentsState = {
    importJobs: [],
    importJobsMetadata: {
        page_size: 10,
        page_num: 0,
        order: 'desc',
        sort: 'createdAt',
        search: ''
    },
    getAllTagsMetadata: {
        page_size: 10000,
        page_num: 0,
        order: 'desc',
        sort: 'createdAt',
        search: ''
    },
    allTags: [],
    getAllTagsError: undefined,
    isAssigningStudentTags: false,
    assignStudentTagsError: undefined,
    inviteStudentsViaEmailListCsv: '',
    onlineStudents: [],
    onlineStudentsMetadata: {
        page_size: 20,
        page_num: 0,
        order: 'asc',
        sort: '',
        search: ''
    },
    student: generateProfile(),
    studentEvents: [],
    studentEventsMetadata: generateEventsMetadata(),
    studentGroupChatMessages: [],
    studentGroupChatMessagesByMonth: {},
    studentGroupChatMessagesMetadata: generateStudentGroupChatMessagesMetadata(),
    studentGroups: [],
    studentGroupsMetadata: generateGroupsMetadata(),
    studentPostsAndComments: [],
    studentPostsAndCommentsByMonth: {},
    studentPostsAndCommentsMetadata: generateStudentPostsAndCommentsMetadata(),
    students: [],
    studentsMetadata: generateStudentsMetadata(),
    searchTerm: '',
    isDeletingStudent: false,
    isGettingAllTags: false,
    isGettingImportJobs: false,
    isGettingOnlineStudents: false,
    isGettingStudent: false,
    isGettingStudentEvents: false,
    isGettingStudentGroupChatMessages: false,
    isGettingStudentGroups: false,
    isGettingStudentPostsAndComments: false,
    isGettingStudents: false,
    isInvitingStudentsViaCsvImport: false,
    isInvitingStudentsViaEmailList: false,
    isResendPendingInvites: false,
    isSettingAlternateEmail: false,
    deleteStudentError: undefined,
    getImportJobsError: undefined,
    getOnlineStudentsError: undefined,
    getStudentError: undefined,
    getStudentEventsError: undefined,
    getStudentGroupChatMessagesError: undefined,
    getStudentGroupsError: undefined,
    getStudentPostAndCommentsError: undefined,
    getStudentsError: undefined,
    inviteStudentsViaCsvImportError: undefined,
    inviteStudentsViaEmailListError: undefined,
};

export const studentsSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        clearAssignStudentTagsError: (state) => {
            state.assignStudentTagsError = undefined;
        },
        clearDeleteStudentError: (state) => {
            state.deleteStudentError = undefined;
        },
        clearInviteStudentsViaCsvImportError: (state) => {
            state.inviteStudentsViaCsvImportError = undefined;
        },
        clearInviteStudentsViaEmailListError: (state) => {
            state.inviteStudentsViaEmailListError = undefined;
        },
        clearStudent: (state) => {
            state.student = generateProfile();
            state.studentEvents = [];
            state.studentGroupChatMessages = [];
            state.studentGroupChatMessagesByMonth = {};
            state.studentGroups = [];
            state.studentPostsAndComments = [];
            state.studentPostsAndCommentsByMonth = {};
            state.studentEventsMetadata = generateEventsMetadata();
            state.studentGroupChatMessagesMetadata = generateStudentGroupChatMessagesMetadata();
            state.studentGroupsMetadata = generateGroupsMetadata();
            state.studentPostsAndCommentsMetadata = generateStudentPostsAndCommentsMetadata();
        },
        clearStudentsMetadata: (state) => {
            state.studentsMetadata = generateStudentsMetadata();
            state.searchTerm = '';
        },
        setInviteStudentsViaCsvImportError: (state, action) => {
            state.inviteStudentsViaCsvImportError = action.payload;
        },
        setInviteStudentsViaEmailListCsv: (state, action) => {
            state.inviteStudentsViaEmailListCsv = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setStudent: (state, action) => {
            state.student = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteStudent.pending, (state) => {
            state.deleteStudentError = undefined;
            state.isDeletingStudent = true;
        });
        addCase(deleteStudent.fulfilled, (state, action) => {
            const deletedProfileId = action.payload;
            state.students = clone(state.students).filter(student => student.profileId !== deletedProfileId);
            state.isDeletingStudent = false;
        });
        addCase(deleteStudent.rejected, (state, action) => {
            state.deleteStudentError = action.payload;
            state.isDeletingStudent = false;
        });

        addCase(getImportJobs.pending, (state, action) => {
            state.getImportJobsError = undefined;
            state.isGettingImportJobs = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.importJobsMetadata) {
                state.importJobsMetadata = action.meta.arg.importJobsMetadata;
            }
        });
        addCase(getImportJobs.fulfilled, (state, action) => {
            state.importJobs = action.payload.importJobs;
            state.importJobsMetadata = action.payload.importJobsMetadata;
            state.isGettingImportJobs = false;
        });
        addCase(getImportJobs.rejected, (state, action) => {
            state.getImportJobsError = action.error;
            state.isGettingImportJobs = false;
        });

        addCase(getAllTags.pending, (state, action) => {
            state.getAllTagsError = undefined;
            state.isGettingAllTags = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.getAllTagsMetadata) {
                state.getAllTagsMetadata = action.meta.arg.getAllTagsMetadata;
            }
        });
        addCase(getAllTags.fulfilled, (state, action) => {
            state.allTags = action.payload.allTags;
            state.getAllTagsMetadata = action.payload.getAllTagsMetadata;
            state.isGettingAllTags = false;
        });
        addCase(getAllTags.rejected, (state, action) => {
            state.getAllTagsError = action.payload;
            state.isGettingAllTags = false;
        });

        addCase(assignTags.pending, (state, action) => {
            state.assignStudentTagsError = undefined;
            state.isAssigningStudentTags = true;
        });
        addCase(assignTags.fulfilled, (state, action) => {
            const modifiedStudent = action.payload.data.data;
            state.students = cloneStudentsWithNewTags(state.students, modifiedStudent);
            state.isAssigningStudentTags = false;
        });
        addCase(assignTags.rejected, (state, action) => {
            state.assignStudentTagsError = action.payload;
            state.isAssigningStudentTags = false;
        });


        addCase(getOnlineStudents.pending, (state, action) => {
            state.getOnlineStudentsError = undefined;
            state.isGettingOnlineStudents = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.onlineStudentsMetadata) {
                state.onlineStudentsMetadata = action.meta.arg.onlineStudentsMetadata;
            }
        });
        addCase(getOnlineStudents.fulfilled, (state, action) => {
            state.onlineStudents = action.payload.onlineStudents;
            state.onlineStudentsMetadata = action.payload.onlineStudentsMetadata;
            state.isGettingOnlineStudents = false;
        });
        addCase(getOnlineStudents.rejected, (state, action) => {
            state.getOnlineStudentsError = action.error;
            state.isGettingOnlineStudents = false;
        });

        addCase(getStudentById.pending, (state, action) => {
            state.getStudentError = undefined;
            state.isGettingStudent = action.meta?.arg?.hideSpinner !== true;
        });
        addCase(getStudentById.fulfilled, (state, action) => {
            state.student = action.payload.student;
            state.isGettingStudent = false;
        });
        addCase(getStudentById.rejected, (state, action) => {
            state.getStudentError = action.error;
            state.isGettingStudent = false;
        });

        addCase(getStudentEvents.pending, (state, action) => {
            state.getStudentEventsError = undefined;
            state.isGettingStudentEvents = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.studentEventsMetadata) {
                state.studentEventsMetadata = action.meta.arg.studentEventsMetadata;
            }
        });
        addCase(getStudentEvents.fulfilled, (state, action) => {
            state.studentEvents = action.payload.studentEvents;
            state.studentEventsMetadata = action.payload.studentEventsMetadata;
            state.isGettingStudentEvents = false;
        });
        addCase(getStudentEvents.rejected, (state, action) => {
            state.getStudentEventsError = action.error;
            state.isGettingStudentEvents = false;
        });

        addCase(getStudentGroupChatMessages.pending, (state, action) => {
            state.getStudentGroupChatMessagesError = undefined;
            state.isGettingStudentGroupChatMessages = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.studentGroupChatMessagesMetadata) {
                state.studentGroupChatMessagesMetadata = action.meta.arg.studentGroupChatMessagesMetadata;
            }
        });
        addCase(getStudentGroupChatMessages.fulfilled, (state, action) => {
            state.studentGroupChatMessages = action.payload.studentGroupChatMessages;
            state.studentGroupChatMessagesByMonth = action.payload.studentGroupChatMessagesByMonth;
            state.studentGroupChatMessagesMetadata = action.payload.studentGroupChatMessagesMetadata;
            state.isGettingStudentGroupChatMessages = false;
        });
        addCase(getStudentGroupChatMessages.rejected, (state, action) => {
            state.getStudentGroupChatMessagesError = action.error;
            state.isGettingStudentGroupChatMessages = false;
        });

        addCase(getStudentGroups.pending, (state, action) => {
            state.getStudentGroupsError = undefined;
            state.isGettingStudentGroups = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.studentGroupsMetadata) {
                state.studentGroupsMetadata = action.meta.arg.studentGroupsMetadata;
            }
        });
        addCase(getStudentGroups.fulfilled, (state, action) => {
            state.studentGroups = action.payload.studentGroups;
            state.studentGroupsMetadata = action.payload.studentGroupsMetadata;
            state.isGettingStudentGroups = false;
        });
        addCase(getStudentGroups.rejected, (state, action) => {
            state.getStudentGroupsError = action.error;
            state.isGettingStudentGroups = false;
        });

        addCase(getStudentPostsAndComments.pending, (state, action) => {
            state.getStudentPostAndCommentsError = undefined;
            state.isGettingStudentPostsAndComments = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.studentPostsAndCommentsMetadata) {
                state.studentPostsAndCommentsMetadata = action.meta.arg.studentPostsAndCommentsMetadata;
            }
        });
        addCase(getStudentPostsAndComments.fulfilled, (state, action) => {
            state.studentPostsAndComments = action.payload.studentPostsAndComments;
            state.studentPostsAndCommentsByMonth = action.payload.studentPostsAndCommentsByMonth;
            state.studentPostsAndCommentsMetadata = action.payload.studentPostsAndCommentsMetadata;
            state.isGettingStudentPostsAndComments = false;
        });
        addCase(getStudentPostsAndComments.rejected, (state, action) => {
            state.getStudentPostAndCommentsError = action.error;
            state.isGettingStudentPostsAndComments = false;
        });

        addCase(getStudents.pending, (state, action) => {
            state.getStudentsError = undefined;
            state.isGettingStudents = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.studentsMetadata) {
                state.studentsMetadata = action.meta.arg.studentsMetadata;
            }
        });
        addCase(getStudents.fulfilled, (state, action) => {
            state.students = action.payload.students;
            state.studentsMetadata = action.payload.studentsMetadata;
            state.isGettingStudents = false;
        });
        addCase(getStudents.rejected, (state, action) => {
            state.getStudentsError = action.error;
            state.isGettingStudents = false;
        });

        addCase(inviteStudentsViaCsvImport.pending, (state) => {
            state.inviteStudentsViaCsvImportError = undefined;
            state.isInvitingStudentsViaCsvImport = true;
        });
        addCase(inviteStudentsViaCsvImport.fulfilled, (state) => {
            state.isInvitingStudentsViaCsvImport = false;
        });
        addCase(inviteStudentsViaCsvImport.rejected, (state, action) => {
            state.inviteStudentsViaCsvImportError = action.payload as IError;
            state.isInvitingStudentsViaCsvImport = false;
        });

        addCase(inviteStudentsViaEmailList.pending, (state) => {
            state.inviteStudentsViaEmailListError = undefined;
            state.isInvitingStudentsViaEmailList = true;
        });
        addCase(inviteStudentsViaEmailList.fulfilled, (state) => {
            state.isInvitingStudentsViaEmailList = false;
            state.inviteStudentsViaEmailListCsv = '';
        });
        addCase(inviteStudentsViaEmailList.rejected, (state, action) => {
            state.inviteStudentsViaEmailListError = action.payload;
            state.isInvitingStudentsViaEmailList = false;
        });

        addCase(moderateContent.fulfilled, (state, action) => {
            //state.studentPostsAndComments = cloneFeedWithUpdatedPost(state.studentPostsAndComments, action.payload.post);
            state.studentEvents = cloneFeedWithUpdatedPost(state.studentEvents, action.payload.post);
            state.studentGroups = cloneListWithUpdatedGroup(state.studentGroups, action.payload.group);

            if(action.payload.profile) {
                if(state.student.profileId === action.payload.profile.profileId) {
                    state.student = action.payload.profile;
                }

                let clonedStudents = clone(state.students);
                const foundIndex = clonedStudents.findIndex((student) => student.profileId === action.payload.profile.profileId);
                if(!_isNil(foundIndex)) {
                    clonedStudents[foundIndex] = action.payload.profile;
                    state.students = clonedStudents;
                }
            }



            if(action.payload.groupChatMessage) {
                let clonedStudentGroupChatMessages = clone(state.studentGroupChatMessages);
                const foundIndex = clonedStudentGroupChatMessages.findIndex((fi) => fi.forumTopicMessageId === action.payload.groupChatMessage.forumTopicMessageId);
                if(!_isNil(foundIndex)) {
                    clonedStudentGroupChatMessages[foundIndex] = action.payload.groupChatMessage;
                }
                state.studentGroupChatMessages = clonedStudentGroupChatMessages;

                let clonedStudentGroupChatMessagesByMonth = clone(state.studentGroupChatMessagesByMonth);
                Object.keys(clonedStudentGroupChatMessagesByMonth).forEach((month) => {
                    const foundIndex = clonedStudentGroupChatMessagesByMonth[month].messages.findIndex((fi) => fi.forumTopicMessageId === action.payload.groupChatMessage.forumTopicMessageId);
                    if(!_isNil(foundIndex)) {
                        clonedStudentGroupChatMessagesByMonth[month].messages[foundIndex] = action.payload.groupChatMessage;
                    }
                });
                state.studentGroupChatMessagesByMonth = clonedStudentGroupChatMessagesByMonth;
            }

            if(action.payload.post?.type === PostTypes.Thread) {
                let clonedStudentPostsAndComments = clone(state.studentPostsAndComments);
                clonedStudentPostsAndComments.forEach((pc, i) => {
                    if (pc.postId === action.payload.post.postId) {
                        if (action.payload.post.focusComment) {
                            if (pc.focusComment?.postCommentId === action.payload.post.focusComment.postCommentId) {
                                clonedStudentPostsAndComments[i] = action.payload.post;
                            }
                        } else {
                            // Updating these separately to support what happens if we remove a post that also has comments listed
                            clonedStudentPostsAndComments[i].removedByMods = action.payload.post.removedByMods;
                            clonedStudentPostsAndComments[i].superApproval = action.payload.post.superApproval;
                            clonedStudentPostsAndComments[i].flagged = action.payload.post.flagged;
                        }
                    }
                });

                let clonedStudentPostsAndCommentsByMonth = clone(state.studentPostsAndCommentsByMonth);
                Object.keys(clonedStudentPostsAndCommentsByMonth).forEach((month) => {
                    clonedStudentPostsAndCommentsByMonth[month].threads.forEach((pc, i) => {
                        if (pc.postId === action.payload.post.postId) {
                            if (action.payload.post.focusComment) {
                                if (pc.focusComment?.postCommentId === action.payload.post.focusComment.postCommentId) {
                                    clonedStudentPostsAndCommentsByMonth[month].threads[i] = action.payload.post;
                                }
                            } else {
                                // Updating these separately to support what happens if we remove a post that also has comments listed
                                clonedStudentPostsAndCommentsByMonth[month].threads[i].removedByMods = action.payload.post.removedByMods;
                                clonedStudentPostsAndCommentsByMonth[month].threads[i].superApproval = action.payload.post.superApproval;
                                clonedStudentPostsAndCommentsByMonth[month].threads[i].flagged = action.payload.post.flagged;
                            }
                        }
                    })
                });

                state.studentPostsAndComments = clonedStudentPostsAndComments;
                state.studentPostsAndCommentsByMonth = clonedStudentPostsAndCommentsByMonth;
            }
        });
        addCase(reSendPendingInvites.pending, (state) => {
            state.resendPendingInvitesError = undefined;
            state.isResendPendingInvites = true;
        });
        addCase(reSendPendingInvites.fulfilled, (state) => {
            state.isResendPendingInvites = false;
        });
        addCase(reSendPendingInvites.rejected, (state, action) => {
            state.resendPendingInvitesError = action.error;
            state.isResendPendingInvites = false;
        });
        addCase(setAlternateEmailAddress.pending, (state, action) => {
            state.isSettingAlternateEmail = true;
        });
        addCase(setAlternateEmailAddress.fulfilled, (state, action) => {
            state.isSettingAlternateEmail = false;
        });
        addCase(setAlternateEmailAddress.rejected, (state, action) => {
            state.studentSetAlternateEmailError = action.payload;
            state.isSettingAlternateEmail = false;
        });
    }
});

export const { clearAssignStudentTagsError, clearDeleteStudentError, clearInviteStudentsViaCsvImportError, clearInviteStudentsViaEmailListError, clearStudent, clearStudentsMetadata, setInviteStudentsViaCsvImportError, setInviteStudentsViaEmailListCsv, setSearchTerm, setStudent } = studentsSlice.actions;

export default studentsSlice.reducer;
