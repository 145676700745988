import React, { useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from "react-router";

import Profile from "../../../../../../types/Profile";

import { handleTextChange } from '../../../../../../utils/handle-changes';
import { isArrayNullOrEmpty } from "../../../../../../utils/utils";
import { ProfileStatuses } from "../../../../../../utils/enums";

import { clearAssignStudentTagsError } from '../../../../../../store/slices/students';
import {
    clearDeclineAdmitError,
    clearDeleteAdmitError,
    clearPromoteAdmitToStudentError, clearUpdateAdmitError, clearWelcomeMessage,
    declineAdmit,
    deleteAdmit, setWelcomeMessage, updateAdmitWelcomeMessage,
} from '../../../../../../store/slices/admits';
import { setNewRecipient } from "../../../../../../store/slices/directMessages";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import ErrorInline from "../../../../../Errors/ErrorInline";
import ManageStudentTags from "../../../SchoolStudents/ManageTags";
import Modal from "../../../../../../components/Modal";
import PromoteStudentForm from './PromoteStudentForm';
import SuccessModal from "../../../../../../components/Modal/SuccessModal";
import TableMoreMenu from "../../../../../../components/Table/TableMoreMenu";
import Textbox from '../../../../../../components/Textbox';

type Props = {
    getAfterDelete?: () => void
    item: Profile
}

const AdmitsTableMoreMenu: React.FC<Props> = ({
    getAfterDelete,
    item,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const [alternateEmail, setAlternateEmail] = useState<string | null>(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [showDeclineConfirmModal, setShowDeclineConfirmModal] = useState<boolean>(false);
    const [showPromoteConfirmModal, setShowPromoteConfirmModal] = useState<boolean>(false);
    const [showUpdateWelcomeMessageModal, setShowUpdateWelcomeMessageModal] = useState<boolean>(false);
    const [showTagsModal, setShowTagsModal] = useState<boolean>(false);
    const [showTagsSuccessModal, setShowTagsSuccessModal] = useState<boolean>(false);

    const { postAsProfile } = useTypedSelector((state) => state.schools.activeSchool);
    const { declineAdmitError, deleteAdmitError, isDecliningAdmit, isDeletingAdmit, isPromotingAdmitToStudent, isUpdatingAdmit, promoteAdmitToStudentError, updateAdmitError, welcomeMessage } = useTypedSelector((state) => state.admits);

    const handleCloseManageTagsModal = () => {
        setShowTagsModal(false);
        dispatch(clearAssignStudentTagsError());
    };

    const handleDeclineAdmit = async () => {
        try {
            await dispatch(declineAdmit({admitProfileId: item.admitProfile.admitProfileId})).unwrap();
            setShowDeclineConfirmModal(false);
            getAfterDelete && getAfterDelete();
        } catch(err) {
            console.log('AdmitsTableMoreMenu handleDeclineAdmit err', err);
        }
    };

    const handleDeleteAdmit = async () => {
        try {
            await dispatch(deleteAdmit({admitProfileId: item.admitProfile.admitProfileId})).unwrap();
            setShowConfirmDeleteModal(false);
            getAfterDelete && getAfterDelete();
        } catch(err) {
            console.log('AdmitsTableMoreMenu handleDeleteAdmit err', err);
        }
    };

    const handleUpdateWelcomeMessageSubmit = async () => {
        try {
            await dispatch(updateAdmitWelcomeMessage({
                admitProfileId: item.admitProfile.admitProfileId,
                welcomeMessage
            })).unwrap();
            setShowUpdateWelcomeMessageModal(false);
            dispatch(clearWelcomeMessage());
            getAfterDelete && getAfterDelete();
        } catch(err) {
            console.log('AdmitsTableMoreMenu handleUpdateWelcomeMessageSubmit err', err);
        }
    }

    const handleSetWelcomeMessageText = (name: string, text: string) => {
        dispatch(setWelcomeMessage(text));
    }

    const customActions = useMemo(
        () => {
            let actions = [];

            const itemIsMe = postAsProfile?.profileId === item.profileId;

            actions.push({
                disabled: item.admitProfile.status !== ProfileStatuses.Active,
                icon: 'plus-circle',
                onClick: async () => {
                    setShowPromoteConfirmModal(true);
                },
                text: 'Promote to Student',
            });

            actions.push({
                icon: 'minus-circle',
                onClick: async () => {
                    setShowDeclineConfirmModal(true);
                },
                text: 'Decline Admit',
            });

            if(postAsProfile && !itemIsMe) {
                actions.push({
                    icon: 'dms',
                    onClick: () => {
                        if(item.conversationId) {
                            history.push(`/school/${params.schoolId}/admit/messages/conversation/${item.conversationId}`);
                        } else {
                            dispatch(setNewRecipient(item));
                            history.push(`/school/${params.schoolId}/admit/messages/conversation/new`);
                        }
                    },
                    text: 'Message',
                });

                actions.push({
                    icon: 'user-remove',
                    onClick: async () => {
                        setShowConfirmDeleteModal(true);
                    },
                    text: 'Remove',
                });
            }

            actions.push({
                icon: 'tag',
                onClick: async () => {
                    setShowTagsModal(true);
                },
                text: 'Manage Tags',
            });

            actions.push({
                icon: 'edit',
                onClick: async () => {
                    setShowUpdateWelcomeMessageModal(true);
                },
                text: 'Update Welcome Message',
            });

            return actions;

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    if(isArrayNullOrEmpty(customActions)) {
        return null;
    }

    return (
        <>
            <TableMoreMenu
                customOptions={customActions}
                hideModerationOptions
                getAfterModerate={getAfterDelete}
                item={item}
                type="profile"
            />

            <Modal
                declineButtonOnClick={handleCloseManageTagsModal}
                show={showTagsModal}
                title="Manage Student Tags"
            >
                <ManageStudentTags
                    closeModal={handleCloseManageTagsModal}
                    user={item}
                    onSuccess={() => {
                        setShowTagsModal(false);
                        setShowTagsSuccessModal(true);
                    }}
                />
            </Modal>

            <SuccessModal
                buttonOnClick={() => {
                    setShowTagsSuccessModal(false);
                }}
                buttonText="Done"
                show={showTagsSuccessModal}
                title="Nice job!"
            >
                <p>
                    Tags have been modified.
                </p>
            </SuccessModal>

            <Modal
                confirmButtonOnClick={handleDeclineAdmit}
                confirmButtonText="Decline"
                declineButtonOnClick={() => {
                    setShowDeclineConfirmModal(false);
                    dispatch(clearDeclineAdmitError());
                }}
                declineButtonText="Cancel"
                show={showDeclineConfirmModal}
                showActivityIndicator={isDecliningAdmit}
                title="Decline Admit?"
            >
                <p>
                    Are you sure you want to decline this admit? This action cannot be undone.
                </p>

                <ErrorInline error={declineAdmitError} />
            </Modal>

            <Modal
                confirmButtonOnClick={handleDeleteAdmit}
                confirmButtonText="Remove"
                declineButtonOnClick={() => {
                    setShowConfirmDeleteModal(false);
                    dispatch(clearDeleteAdmitError());
                }}
                declineButtonText="Cancel"
                show={showConfirmDeleteModal}
                showActivityIndicator={isDeletingAdmit}
                title="Are you sure?"
            >
                <p>
                    Are you sure you want to delete this admit account? This action cannot be undone.
                </p>

                <ErrorInline error={deleteAdmitError} />
            </Modal>

            <Modal
                confirmButtonOnClick={handleUpdateWelcomeMessageSubmit}
                confirmButtonText="Save"
                declineButtonOnClick={() => {
                    setShowUpdateWelcomeMessageModal(false);
                    dispatch(clearWelcomeMessage());
                    dispatch(clearUpdateAdmitError());
                }}
                declineButtonText="Cancel"
                show={showUpdateWelcomeMessageModal}
                showActivityIndicator={isUpdatingAdmit}
                title="Update Welcome Message"
            >
                <Textbox
                    id={item.admitProfile.admitProfileId + 'welcome-msg-txt'}
                    onChange={(event) => {
                        handleTextChange(handleSetWelcomeMessageText, event)
                    }}
                    required={true}
                    type="textarea"
                    value={item.admitProfile.welcomeMessage}
                />

                <ErrorInline error={updateAdmitError} />
            </Modal>

            <Modal
                declineButtonOnClick={() => {
                    setShowPromoteConfirmModal(false);
                    dispatch(clearPromoteAdmitToStudentError());
                    setTimeout(() => setAlternateEmail(null), 500);
                }}
                show={showPromoteConfirmModal}
                showActivityIndicator={isPromotingAdmitToStudent}
                title="Promote to Student?"
            >
                <p>
                    This action will promote an admit user to a student user immediately. Would you like to add their
                    new school email address to their account? They’ll continue to be able to log in with either email
                    address.
                </p>

                <PromoteStudentForm
                    alternateEmail={alternateEmail}
                    close={() => {
                        setShowPromoteConfirmModal(false);
                        getAfterDelete && getAfterDelete();
                    }}
                    profile={item}
                    setAlternateEmail={setAlternateEmail}
                />

                <ErrorInline error={promoteAdmitToStudentError} />
            </Modal>
        </>
    );
};

export default AdmitsTableMoreMenu;
