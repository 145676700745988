import './Giving.scss';

import React, { useEffect } from 'react';

import { useTypedSelector } from '../../../../store/reducers';
import { useAppDispatch } from '../../../../store';
import { clearGivingStatus, getGivingStatus } from '../../../../store/slices/schools';

import ActivityIndicator from '../../../../components/ActivityIndicator';
import Button from '../../../../components/Button';
import ButtonRow from '../../../../components/ButtonRow';
import GivingOpportunityTable from './GivingOpportunityTable';
import UpdateGivingStatus from './UpdateGivingStatus';
import Icon from '../../../../components/Icon';
import { StripeAccountStatus } from '../../../../types/StripeAccountStatus';

const Giving: React.FC = () => {
    const dispatch = useAppDispatch();

    const {activeSchool, givingStatus, isGettingGivingStatus} = useTypedSelector(state => state.schools);
    const [showUpdateGivingStatus, setShowUpdateGivingStatus] = React.useState(false);

    useEffect(() => {
        const init = async () => {
            if (isGettingGivingStatus) {
                return;
            }

            await dispatch(getGivingStatus({schoolId: activeSchool.tenantId})).unwrap();
        };

        init();

        return () => {
            dispatch(clearGivingStatus());
        };
    }, []);

    return (
        <div>
            <div className="giving_title-container">
                <Icon type="navigation-giving"/>
                <div className="giving_title-text">Giving</div>
            </div>

            {isGettingGivingStatus ? <ActivityIndicator size="large"/> : (
                <div>

                    {givingStatus.active && (
                        <div className="giving_active-container">
                            <div>
                                <h2>App Status</h2>
                                <p className={givingStatus.active ? 'giving_active-status-value' : 'giving_inactive-status-value'}>
                                    {givingStatus.active ? 'Enabled' : 'Disabled'}
                                </p>

                                <h2>Stripe Account Status</h2>
                                <p className={activeSchool.stripeAccountStatus === StripeAccountStatus.Complete ? 'giving_active-status-value' : 'giving_inactive-status-value'}>
                                    {activeSchool.stripeAccountStatus.toString().charAt(0).toUpperCase() + activeSchool.stripeAccountStatus.slice(1)}
                                </p>
                            </div>

                            <div>
                                <h2>Billing Contact Email Address</h2>
                                <p className="giving_active-value">
                                    {givingStatus.billingContactEmailAddress}
                                </p>

                                <h2>Minimum Donation Amount</h2>
                                <p className="giving_active-value">
                                    {'$' + givingStatus.minimumDonationUsdCents / 100 + '.00'}
                                </p>
                            </div>

                            <div>
                                {givingStatus.opportunities &&
                                    givingStatus.opportunities.length > 0 && (
                                        <GivingOpportunityTable
                                            opportunities={givingStatus.opportunities}
                                        />
                                    )}
                            </div>

                        </div>
                    )}

                    <ButtonRow>
                        {givingStatus.loginLink && (
                            <Button onClick={() => {
                                // Open the login link in a new tab
                                window.open(givingStatus.loginLink, '_blank');
                            }}>
                                Login To Stripe Account Dashboard
                            </Button>
                        )}

                        <Button onClick={() => setShowUpdateGivingStatus(true)}>
                            Update Giving Details
                        </Button>
                    </ButtonRow>

                    <UpdateGivingStatus
                        school={activeSchool}
                        showForm={showUpdateGivingStatus}
                        setShowForm={setShowUpdateGivingStatus}
                    />
                </div>
            )}
        </div>
    );
};

export default Giving;
