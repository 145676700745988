import './ListAdmits.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clone from 'clone';
import { useRouteMatch } from "react-router";

import { isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../../../../utils/utils";
import { AdmissionStatus, ListTypes, ProfileStatuses, ProfileStatusLabels } from '../../../../../utils/enums';

import {
    clearAdmitsMetadata,
    clearInviteAdmitsViaCsvImportError,
    getAdmits,
    setSearchTerm,
} from '../../../../../store/slices/admits';
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import AdmitsTableMoreMenu from "./AdmitsTableMoreMenu";
import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Avatar from "../../../../../components/Avatar";
import Button, { ButtonSizes } from "../../../../../components/Button";
import GridList, { GridListTypes } from "../../../../../components/GridList";
import H5 from "../../../../../components/H5";
import Icon from "../../../../../components/Icon";
import InviteStudentsViaCsvImport from "../../SchoolStudents/ListStudents/InviteStudentsViaCsvImport";
import ListTypeSelector from "../../../../../components/ListTypeSelector";
import Modal from "../../../../../components/Modal";
import PageHeader from "../../../../../components/PageHeader";
import { ProfileNounLookup } from "../../SchoolStudents/ListStudents";
import SuccessModal from "../../../../../components/Modal/SuccessModal";
import Table from "../../../../../components/Table";
import TableColumn from "../../../../../types/TableColumn";
import TableFilterRow from "../../../../../components/Table/TableFilterRow";
import TableFilterRowDivider from "../../../../../components/Table/TableFilterRow/TableFilterRowDivider";
import TableSearch from "../../../../../components/TableSearch";
import ToggleSwitch from '../../../../../components/ToggleSwitch';
import TableFilterMenu from '../../../../../components/Table/TableFilterMenu';
import TableFilterMenuOption from '../../../../../types/TableFilterMenuOption';

const ListAdmits: React.FC = () => {
    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState<boolean>(false);
    const [selectedListType, setSelectedListType] = useState<ListTypes>(ListTypes.Table);
    const [showUploadCsvModal, setShowUploadCsvModal] = useState<boolean>(false);
    const [showUploadCsvSuccessModal, setShowUploadCsvSuccessModal] = useState<boolean>(false);

    const { admits, isGettingAdmits, admitsMetadata, searchTerm } = useTypedSelector((state) => state.admits);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getAdmits({
                    schoolId: params?.schoolId
                })).unwrap();
            } catch(err) {
                console.log('ListAdmits initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            dispatch(clearAdmitsMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Name',
                    id: 'student',
                    Cell: ({row: { original }}) => (
                        <div className="users-table__user">
                            <Avatar
                                artifact={original.artifact}
                                flagged={original.flagged || original.flaggedContent}
                            />

                            <div className="users-table__name">
                                {original.firstName} {original.lastName}
                            </div>
                        </div>
                    ),
                    hasImage: true,
                    sortBy: 'name',
                }, {
                    Header: 'Status',
                    accessor: (d) => ProfileStatusLabels[d.admitProfile?.status],
                }, {
                    Header: 'Email',
                    accessor: 'emailAddress',
                }, {
                    Header: 'Counselor',
                    accessor: (d) => isObjectNullOrEmpty(d.admitProfile?.assignedUser) ? '--' : `${d.admitProfile.assignedUser.firstName} ${d.admitProfile.assignedUser.lastName}`,
                }, {
                    Header: 'Tags',
                    id: 'tags',
                    Cell: ({row: { original }}) => {
                        let tags = original.tags ? original.tags.map((t) => t.tag.text) : [];
                        return (
                            <div className="users-table__majors">
                                {isArrayNullOrEmpty(tags) ? '--' : tags.join(', ')}
                            </div>
                        )
                    },
                }, {
                    Header: 'Class Year',
                    id: 'classYear',
                    Cell: ({row: { original }}) => {
                        return (
                            <div className="users-table__majors">
                                {isArrayNullOrEmpty(original.gradyear) || !original.gradyear[0] ? '--' : (original.gradyear[0].displayText || original.gradyear[0].description)}
                            </div>
                        )
                    },
                    sortBy: 'classYear'
                }, {
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => renderMoreMenu(original)
                }
            ];

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const renderMoreMenu = (admit) => {
        return (
            <AdmitsTableMoreMenu
                getAfterDelete={() => onMetadataChange({}, false)}
                item={admit}
            />
        );
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(admitsMetadata);
            
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            if (changes.hasOwnProperty('status')) {
                clonedMetadata.page_num = 0;
                if (changes.status === 0) {
                    delete clonedMetadata.status;
                }
            }

            if (changes.admission_status === 0) {
                clonedMetadata.page_num = 0;
                if (!changes.admission_status) {
                    delete clonedMetadata.admission_status;
                }
            }

            return await dispatch(getAdmits({isUpdate, admitsMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('ListStudents onMetadataChange err', err);
        }
    };

    const filterOptions: Array<TableFilterMenuOption> = useMemo(
        () => {
            return (
                [{
                    isActive: !admitsMetadata.admission_status,
                    onSelect: () => {
                        onMetadataChange({
                            admission_status: 0
                        }, false);
                    },
                    text: "All"
                },{
                    isActive: admitsMetadata.admission_status === AdmissionStatus.Admit,
                    onSelect: () => {
                        onMetadataChange({
                            admission_status: AdmissionStatus.Admit
                        }, false);
                    },
                    text: "Admits"
                }, {
                    isActive: admitsMetadata.admission_status === AdmissionStatus.Promoted,
                    onSelect: () => {
                        onMetadataChange({
                            admission_status: AdmissionStatus.Promoted
                        }, false);
                    },
                    text: "Promoted"
                }, {
                    isActive: admitsMetadata.admission_status === AdmissionStatus.Declined,
                    onSelect: () => {
                        onMetadataChange({
                            admission_status: AdmissionStatus.Declined
                        }, false);
                    },
                    text: "Declined"
                }]
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [admitsMetadata]
    );

    const accountFilterOptions: Array<TableFilterMenuOption> = useMemo(
        () => {
            return (
                [{
                    isActive: !admitsMetadata.status,
                    onSelect: () => {
                        onMetadataChange({
                            status: 0
                        }, false);
                    },
                    text: "All"
                },{
                    isActive: admitsMetadata.status === ProfileStatuses.Active,
                    onSelect: () => {
                        onMetadataChange({
                            status: ProfileStatuses.Active
                        }, false);
                    },
                    text: "Active"
                }, {
                    isActive: admitsMetadata.status === ProfileStatuses.Pending,
                    onSelect: () => {
                        onMetadataChange({
                            status: ProfileStatuses.Pending
                        }, false);
                    },
                    text: "Pending"
                }]
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [admitsMetadata]
    );

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="list-admits">
            <PageHeader>
                <H5>
                    <Icon type="navigation-users" /> Admits
                </H5>
            </PageHeader>

            <TableFilterRow>
                <TableFilterMenu
                    label="Admission Status"
                    options={filterOptions}
                />

                <TableFilterRowDivider />

                <TableFilterMenu
                    label="Account Status"
                    options={accountFilterOptions}
                />

                <TableFilterRowDivider />

                <ToggleSwitch
                    checked={admitsMetadata.assigned_to_me}
                    label="Assigned To Me"
                    name="assignedToMe"
                    onClick={() => onMetadataChange({assigned_to_me: !admitsMetadata.assigned_to_me}, false)}
                />

                <TableFilterRowDivider />

                <ListTypeSelector
                    onListTypeSelected={(listType) => setSelectedListType(listType)}
                    selected={selectedListType}
                />

                <TableFilterRowDivider />

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search Admits"
                    searchTerm={searchTerm}
                    size="small"
                />

                <Button
                    onClick={() => setShowUploadCsvModal(true)}
                    size={ButtonSizes.Small}
                >
                    Add Admits
                </Button>
            </TableFilterRow>

            {isGettingAdmits ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(admits) && initialized ? (
                        <>
                            No students to display.
                        </>
                    ) : (
                        <>
                            {selectedListType === ListTypes.Table ? (
                                <Table
                                    columns={columns}
                                    data={admits}
                                    getData={onMetadataChange}
                                    pageNumber={admitsMetadata.page_num}
                                    pageSize={admitsMetadata.page_size}
                                    sortBy={admitsMetadata.sort}
                                    sortOrder={admitsMetadata.order}
                                    totalNumberOfItems={admitsMetadata.total}
                                />
                            ) : (
                                <GridList
                                    /*createCardButtonOnClick={() => setShowNewUserModal(true)}
                                    createCardButtonText="Create an Admin"*/
                                    isLastPage={Math.ceil(admitsMetadata.total / admitsMetadata.page_size) === admitsMetadata.page_num + 1}
                                    items={admits}
                                    listType={GridListTypes.Student}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: admitsMetadata.page_num + 1})}
                                    onPreviousPageClick={() => onMetadataChange({page_num: admitsMetadata.page_num - 1})}
                                    pageNumber={admitsMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <Modal
                className="c-success-modal"
                declineButtonOnClick={() => {
                    setShowUploadCsvModal(false);
                    dispatch(clearInviteAdmitsViaCsvImportError());
                }}
                show={showUploadCsvModal}
                title="Import Admits"
            >
                <InviteStudentsViaCsvImport
                    noun={ProfileNounLookup.admit}
                    onSuccess={() => {
                        setShowUploadCsvModal(false);
                        setShowUploadCsvSuccessModal(true);
                    }}
                />
            </Modal>

            <SuccessModal
                buttonOnClick={() => {
                    setShowUploadCsvSuccessModal(false);
                }}
                buttonText="Done"
                show={showUploadCsvSuccessModal}
                title="Nice job!"
            >
                <p>
                    Invite(s) have been sent to the specified users.
                </p>
            </SuccessModal>
        </div>
    );
};

export default ListAdmits;
