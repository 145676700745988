import './A.scss';

import React, {MouseEventHandler} from 'react';
import { Link } from 'react-router-dom';

type Props = {
    children: React.ReactNode
    className?: string
    href?: string
    onClick?: MouseEventHandler
    state?: string
    target?: string
    to?: string
}

const A: React.FC<Props> = ({
    children,
    className = '',
    state,
    to,
    ...otherProps
}) => {
    if(to != null) {
        return (
            <Link
                className={`c-link a-link--to ${className}`}
                to={{
                    pathname: to,
                    state
                }}
                {...otherProps}
            >
                {children}
            </Link>
        );
    } else {
        return (
            <a
                className={`c-link a-link--href ${className}`}
                {...otherProps}
            >
                {children}
            </a>
        );
    }
};

export default A;
