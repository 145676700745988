import './ProfileTypesSelector.scss';

import React from 'react';
import { useRouteMatch } from "react-router";

import EventPost from "../../types/EventPost";
import IntegrationEvent from '../../types/IntegrationEvent';
import NewsStory from "../../types/NewsStory";
import PushNotification from "../../types/PushNotification";
import Question from "../../types/Question";
import Thread from "../../types/Thread";

import { ProfileTypes } from "../../utils/enums";

import Checkbox from "../Checkbox";
import H5 from "../H5";
import RadioGroup from "../RadioGroup";

type Props = {
    hideProfileTypes?: Array<ProfileTypes>
    item: EventPost | NewsStory | PushNotification | Question | Thread | IntegrationEvent
    onChange: (name: string, value: boolean) => void
}

const ProfileTypesSelector: React.FC<Props> = ({
    hideProfileTypes,
    item,
    onChange,
}) => {
    const { params } = useRouteMatch();

    const { admitVisibility, alumniVisibility, studentVisibility } = item;

    return (
        <div className="c-profile-type-selector">
            <H5>
                Publish To
            </H5>

            <RadioGroup horizontal>
                <Checkbox
                    checked={admitVisibility || false}
                    disabled={params.profileType === ProfileTypes.Admit}
                    id={`chkProfileTypesAdmit`}
                    label="Admit"
                    name="admitVisibility"
                    onChange={() => onChange('admitVisibility', !admitVisibility)}
                />

                <Checkbox
                    checked={alumniVisibility || false}
                    disabled={params.profileType === ProfileTypes.Alumni}
                    id={`chkProfileTypesAlumni`}
                    label="Alumni"
                    name="alumniVisibility"
                    onChange={() => onChange('alumniVisibility', !alumniVisibility)}
                />

                <Checkbox
                    checked={studentVisibility || false}
                    disabled={params.profileType === ProfileTypes.Student}
                    id={`chkProfileTypesStudent`}
                    label="Student"
                    name="studentVisibility"
                    onChange={() => onChange('studentVisibility', !studentVisibility)}
                />
            </RadioGroup>
        </div>
    );
};

export default ProfileTypesSelector;
