import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from 'clone';

import IError from '../../types/IError';
import GivingCategory from '../../types/GivingCategory';
import GivingOpportunity from '../../types/GivingOpportunity';
import Metadata from '../../types/Metadata';

import { generateGivingCategoriesMetadata, generateGivingOpportunitiesMetadata } from '../../utils/generators';
import PATHS, { buildQueryString } from '../../utils/paths';
import { buildErrorObject } from '../../utils/errors';
import Request from '../../utils/request';
import { generateGivingCategory, generateGivingOpportunity } from '../../utils/generators';

import { addError } from './errors';
import { RootState } from '../reducers';

type GetGivingCategoriesProps = {
    schoolId?: number;
};
export const getGivingCategories = createAsyncThunk(
    'giving/getGivingCategories',
    async ({ schoolId }: GetGivingCategoriesProps, { dispatch, getState, rejectWithValue }) => {
        try {
            if (!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(
                PATHS.giving.getCategories(schoolId, 'page_size=10000&page_num=0'),
            );
            console.log('getGivingCategories', res);
            let givingCategories = res.data.data.items;
            return { givingCategories };
        } catch (err) {
            console.log('getGivingCategories', err);
            const friendlyMessage = 'Error getting the list of giving categories. Please try again.';

            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type ReorderGivingCategoriesProps = {
    categoryIds?: Array<number>
}

export const reorderGivingCategories = createAsyncThunk(
    'giving/reorderCategories',
    async({ categoryIds }: ReorderGivingCategoriesProps, {dispatch, getState, rejectWithValue}) => {
        const { tenantId } = (getState() as RootState).schools.activeSchool;

        try {
            if(!categoryIds) {
                categoryIds = (getState() as RootState).giving.givingCategories.map((cat: GivingCategory) => cat.givingCategoryId);
            }
            const res = await new Request((getState() as RootState).auth.token).put(PATHS.giving.reorderCategory(tenantId), {givingCategoryIds: categoryIds});
            console.log('reorder res', res);

            return res;
        } catch(err) {
            console.log('reorder frequently asked questions error', err);
            let friendlyMessage = 'Error reordering the questions. Please try again.';
            if (err.response?.data?.error) {
                friendlyMessage = err.response.data.error;
            }
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(err));
            return rejectWithValue(errorObject);
        }
    }
)


type GetGivingOpportunitiesProps = {
    isUpdate?: boolean;
    schoolId?: number;
    givingOpportunitiesMetadata?: Metadata;
};
export const getGivingOpportunities = createAsyncThunk(
    'giving/getGivingOpportunities',
    async (
        { isUpdate, schoolId, givingOpportunitiesMetadata }: GetGivingOpportunitiesProps,
        { dispatch, getState, rejectWithValue },
    ) => {
        try {
            if (!givingOpportunitiesMetadata) {
                givingOpportunitiesMetadata = clone((getState() as RootState).giving.givingOpportunitiesMetadata);
            } else {
                givingOpportunitiesMetadata = { ...givingOpportunitiesMetadata };
            }

            if (!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(
                PATHS.giving.getOpportunities(schoolId, buildQueryString(givingOpportunitiesMetadata)),
            );
            console.log('getGivingOpportunities', res);
            let givingOpportunities = res.data.data.items;
            givingOpportunitiesMetadata.total = res.data.data.meta.total;
            return { givingOpportunities, givingOpportunitiesMetadata };
        } catch (err) {
            console.log('getGivingOpportunities', err);
            const friendlyMessage = 'Error getting the list of giving categories. Please try again.';

            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type SaveGivingCategoryProps = {
    givingCategory?: GivingCategory;
};

export const saveGivingCategory = createAsyncThunk(
    'giving/saveGivingCategory',
    async ({ givingCategory }: SaveGivingCategoryProps, { dispatch, getState, rejectWithValue }) => {
        console.log('saveGivingCategory');
        try {
            const { auth: { token }, schools: { activeSchool: { tenantId } } } = (getState() as RootState);


            let path = PATHS.giving.createCategory(tenantId);
            let request = new Request(token);
            let reqFunc = request.post;
            let isUpdate = false;

            if(givingCategory.givingCategoryId) {
                path = PATHS.giving.updateCategory(tenantId, givingCategory.givingCategoryId);
                reqFunc = request.put;
                isUpdate = true;
            }

            const res = await reqFunc(path, givingCategory);

            console.log('createGivingCategory', res);
            return { category: res.data.data, isUpdate };

        } catch (err) {
            console.log('createGivingCategory', err);
            const friendlyMessage = 'Error saving the class note. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type SaveGivingOpportunityProps = {
    givingOpportunity?: GivingOpportunity;
};
export const saveGivingOpportunity = createAsyncThunk(
    'giving/saveGivingOpportunity',
    async ({ givingOpportunity }: SaveGivingOpportunityProps, { dispatch, getState, rejectWithValue }) => {
        try {
            const { auth: { token }, schools: { activeSchool: { tenantId } } } = (getState() as RootState);

            let path = PATHS.giving.createCategory(tenantId);
            let request = new Request(token);
            let reqFunc = request.post;
            let isUpdate = false;

            if(givingOpportunity.givingOpportunityId) {
                path = PATHS.giving.updateOpportunity(tenantId, givingOpportunity.givingOpportunityId);
                reqFunc = request.put;
                isUpdate = true;
            }

            const res = await reqFunc(path, givingOpportunity);

            return { opportunity: res.data.data, isUpdate };
        } catch (err) {
            console.warn('createGivingOpportunity', err);
            const friendlyMessage = 'Error saving the giving opportunity. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type DeleteGivingOpportunityProps = {
    opportunityId: number;
};
export const deleteGivingOpportunity = createAsyncThunk(
    'giving/deleteGivingOpportunity',
    async ({ opportunityId }: DeleteGivingOpportunityProps, { dispatch, getState, rejectWithValue }) => {
        try {
            const {
                auth: { token },
                schools: { activeSchool },
            } = getState() as RootState;
            const res = await new Request(token).delete(
                PATHS.giving.deleteOpportunity(activeSchool.tenantId, opportunityId),
            );
            return res.data.data;
        } catch (err) {
            console.warn('deleteGivingOpportunity', err);
            const friendlyMessage = 'Error deleting the giving opportunity. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type DeleteGivingCategoryProps = {
    givingCategory?: GivingCategory;
};
export const deleteGivingCategory = createAsyncThunk(
    'giving/deleteGivingCategory',
    async ({ givingCategory }: DeleteGivingCategoryProps, { dispatch, getState, rejectWithValue }) => {
        try {
            if (!givingCategory) {
                givingCategory = (getState() as RootState).giving.givingCategory;
            }
            const {
                auth: { token },
                schools: { activeSchool },
            } = getState() as RootState;

            const res = await new Request(token).delete(
                PATHS.giving.deleteCategory(activeSchool.tenantId, givingCategory.givingCategoryId),
            );
            return {
                ...res,
                id: givingCategory.givingCategoryId,
            };
        } catch (err) {
            console.warn('deleteGivingCategory', err);
            const friendlyMessage = 'Error deleting the giving category. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

type FeatureGivingCategoryOrOpportunityProps = {
    givingCategoryId?: number;
    givingOpportunityId?: number;
};
export const featureGivingCategoryOrOpportunity = createAsyncThunk(
    'giving/featureGivingCategoryOrOpportunity',
    async ({ givingCategoryId, givingOpportunityId }: FeatureGivingCategoryOrOpportunityProps, { dispatch, getState, rejectWithValue }) => {
        try {
            const {
                auth: { token },
                schools: { activeSchool },
            } = getState() as RootState;

            const res = await new Request(token).post(
                PATHS.giving.feature(activeSchool.tenantId),
                { givingCategoryId, givingOpportunityId },
            );
            return res.data.data;
        } catch (err) {
            console.warn('featureGivingCategoryOrOpportunity', err);
            const friendlyMessage = 'Error featuring the giving category or opportunity. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);


export const unFeatureGivingCategoryOrOpportunity = createAsyncThunk(
    'giving/unFeatureGivingCategoryOrOpportunity',
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {

            const {
                auth: { token },
                schools: { activeSchool },
            } = getState() as RootState;

            const res = await new Request(token).put(
                PATHS.giving.unFeature(activeSchool.tenantId),
            );
            return res.data.data;
        } catch (err) {
            console.warn('unFeatureGivingCategoryOrOpportunity', err);
            const friendlyMessage = 'Error un-featuring the giving category or opportunity. Please try again.';
            let errorObject = buildErrorObject({
                serverError: err.response?.data,
                friendlyMessage,
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    },
);

export interface GivingState {
    givingOpportunities: GivingOpportunity[];
    givingOpportunitiesMetadata: Metadata;
    isGettingGivingOpportunities: boolean;
    getGivingOpportunitiesError?: IError;

    givingCategories: GivingCategory[];
    givingCategoriesMetadata: Metadata;
    isGettingGivingCategories: boolean;
    getGivingCategoriesError?: IError;

    givingCategory: GivingCategory;
    isSavingGivingCategory: boolean;
    saveGivingCategoryError?: IError;
    isDeletingGivingCategory: boolean;
    deleteGivingCategoryError?: IError;

    givingOpportunity: GivingOpportunity;
    isSavingGivingOpportunity: boolean;
    saveGivingOpportunityError?: IError;
    isDeletingGivingOpportunity: boolean;
    deleteGivingOpportunityError?: IError;
    givingOpportunitySearchTerm?: string;

    isFeaturingGivingCategoryOrOpportunity: boolean;
    featureGivingCategoryOrOpportunityError?: IError;

    isUnFeaturingGivingCategoryOrOpportunity: boolean;
    unFeatureGivingCategoryOrOpportunityError?: IError;


    // searchTerm: string;
    // updateClassNoteError?: IError;
}

const initialState: GivingState = {
    givingOpportunities: [],
    givingOpportunitiesMetadata: generateGivingOpportunitiesMetadata(),
    getGivingOpportunitiesError: undefined,
    isGettingGivingOpportunities: false,

    givingCategories: [],
    givingCategoriesMetadata: generateGivingCategoriesMetadata(),
    isGettingGivingCategories: false,
    getGivingCategoriesError: undefined,

    givingCategory: generateGivingCategory(),
    isSavingGivingCategory: false,
    saveGivingCategoryError: undefined,
    isDeletingGivingCategory: false,
    deleteGivingCategoryError: undefined,

    givingOpportunity: generateGivingOpportunity(),
    isSavingGivingOpportunity: false,
    saveGivingOpportunityError: undefined,
    isDeletingGivingOpportunity: false,
    deleteGivingOpportunityError: undefined,
    givingOpportunitySearchTerm: '',

    isFeaturingGivingCategoryOrOpportunity: false,
    featureGivingCategoryOrOpportunityError: undefined,

    isUnFeaturingGivingCategoryOrOpportunity: false,
    unFeatureGivingCategoryOrOpportunityError: undefined,
};

const givingSlice = createSlice({
    name: 'giving',
    initialState,
    reducers: {
        clearGivingCategory: state => {
            state.givingCategory = generateGivingCategory();
        },
        setGivingCategory: (state, action) => {
            state.givingCategory = action.payload;
        },
        clearGivingOpportunity: state => {
            state.givingOpportunity = generateGivingOpportunity();
        },
        setGivingOpportunity: (state, action) => {
            state.givingOpportunity = action.payload;
        },
        setGivingCategories: (state, action) => {
            state.givingCategories = action.payload;
        },
        setGivingOpportunitySearchTerm: (state, {payload}) => {
            state.givingOpportunitySearchTerm = payload;
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(getGivingCategories.pending, (state, action) => {
            state.isGettingGivingCategories = true;
            state.getGivingCategoriesError = undefined;
        });
        addCase(getGivingCategories.fulfilled, (state, action) => {
            state.isGettingGivingCategories = false;
            state.givingCategories = action.payload.givingCategories;
        });
        addCase(getGivingCategories.rejected, (state, action) => {
            state.isGettingGivingCategories = false;
            state.getGivingCategoriesError = action.error;
        });

        addCase(getGivingOpportunities.pending, (state, action) => {
            state.isGettingGivingOpportunities = true;
            state.getGivingOpportunitiesError = undefined;

            if (action.meta?.arg?.givingOpportunitiesMetadata) {
                state.givingOpportunitiesMetadata = action.meta.arg.givingOpportunitiesMetadata;
            }
        });
        addCase(getGivingOpportunities.fulfilled, (state, action) => {
            state.isGettingGivingOpportunities = false;
            state.givingOpportunities = action.payload.givingOpportunities;
            state.givingOpportunitiesMetadata = action.payload.givingOpportunitiesMetadata;
        });
        addCase(getGivingOpportunities.rejected, (state, action) => {
            state.isGettingGivingOpportunities = false;
            state.getGivingOpportunitiesError = action.error;
        });

        addCase(saveGivingCategory.pending, state => {
            state.isSavingGivingCategory = true;
            state.saveGivingCategoryError = undefined;
        });
        addCase(saveGivingCategory.fulfilled, (state, action) => {
            state.isSavingGivingCategory = false;
            if(action.payload.isUpdate) {
                const foundIndex = state.givingCategories.findIndex((cat) => cat.givingCategoryId === action.payload.category.givingCategoryId);
                if(foundIndex > -1) {
                    state.givingCategories[foundIndex] = action.payload.category;
                }
            } else {
                state.givingCategories = [
                    ...state.givingCategories,
                    action.payload.category,
                ]
            }
        });

        addCase(saveGivingOpportunity.pending, state => {
            state.isSavingGivingOpportunity = true;
            state.saveGivingOpportunityError = undefined;
        });
        addCase(saveGivingOpportunity.fulfilled, (state, action) => {
            state.isSavingGivingOpportunity = false;
            if(action.payload.isUpdate) {
                const foundIndex = state.givingOpportunities.findIndex((opportunity) => opportunity.givingOpportunityId === action.payload.opportunity.givingOpportunityId);
                if(foundIndex > -1) {
                    state.givingOpportunities[foundIndex] = action.payload.opportunity;
                }
            } else {
                state.givingOpportunities = [
                    ...state.givingOpportunities,
                    action.payload.opportunity,
                ]
            }
        });
        addCase(saveGivingOpportunity.rejected, (state, action) => {
            state.isSavingGivingOpportunity = false;
            state.saveGivingOpportunityError = action.error;
        });


        addCase(deleteGivingOpportunity.pending, state => {
            state.isDeletingGivingOpportunity = true;
            state.deleteGivingOpportunityError = undefined;
        });
        addCase(deleteGivingOpportunity.fulfilled, (state, action) => {
            state.isDeletingGivingOpportunity = false;
            state.givingOpportunities = state.givingOpportunities.filter((op) => op.givingOpportunityId !== action.payload.givingOpportunityId);
        });
        addCase(deleteGivingOpportunity.rejected, (state, action) => {
            state.isDeletingGivingOpportunity = false;
            state.deleteGivingOpportunityError = action.error;
        });

        addCase(deleteGivingCategory.pending, state => {
            state.isDeletingGivingCategory = true;
            state.deleteGivingCategoryError = undefined;
        });
        addCase(deleteGivingCategory.fulfilled, (state, action) => {
            state.isDeletingGivingCategory = false;
            state.givingCategories = state.givingCategories.filter((cat) => cat.givingCategoryId !== action.payload.id);
        });
        addCase(deleteGivingCategory.rejected, (state, action) => {
            state.isDeletingGivingCategory = false;
            state.deleteGivingCategoryError = action.error;
        });

        addCase(featureGivingCategoryOrOpportunity.pending, state => {
            state.isFeaturingGivingCategoryOrOpportunity = true;
            state.featureGivingCategoryOrOpportunityError = undefined;
        });
        addCase(featureGivingCategoryOrOpportunity.fulfilled, (state, action) => {
            state.isFeaturingGivingCategoryOrOpportunity = false;
            state.featureGivingCategoryOrOpportunityError = undefined;
            state.givingOpportunities.forEach((opportunity) => {
                opportunity.featured = false;
            });

            state.givingCategories.forEach((category) => {
                category.featured = false;
            });

            let givingCategoryId = null;
            let givingOpportunityId = null;

            if (action.payload.type === "category") {
                givingCategoryId = action.payload.category.givingCategoryId;
            }

            if (action.payload.type === "opportunity") {
                givingOpportunityId = action.payload.opportunity.givingOpportunityId;
            }

            if (givingCategoryId) {
                const category = state.givingCategories.find((cat) => cat.givingCategoryId === givingCategoryId);
                if (category) {
                    category.featured = true;
                }
            }

            if (givingOpportunityId) {
                const opportunity = state.givingOpportunities.find((op) => op.givingOpportunityId === givingOpportunityId);
                if (opportunity) {
                    opportunity.featured = true;
                }
            }
        });
        addCase(featureGivingCategoryOrOpportunity.rejected, (state, action) => {
            state.isFeaturingGivingCategoryOrOpportunity = false;
            state.featureGivingCategoryOrOpportunityError = action.error;
        });


        addCase(unFeatureGivingCategoryOrOpportunity.pending, state => {
            state.isUnFeaturingGivingCategoryOrOpportunity = true;
            state.unFeatureGivingCategoryOrOpportunityError = undefined;
        });
        addCase(unFeatureGivingCategoryOrOpportunity.fulfilled, (state, action) => {
            state.isUnFeaturingGivingCategoryOrOpportunity = false;
            state.unFeatureGivingCategoryOrOpportunityError = undefined;
            state.givingOpportunities.forEach((opportunity) => {
                opportunity.featured = false;
            });
            state.givingCategories.forEach((category) => {
                category.featured = false;
            });
        });
        addCase(unFeatureGivingCategoryOrOpportunity.rejected, (state, action) => {
            state.isUnFeaturingGivingCategoryOrOpportunity = false;
            state.unFeatureGivingCategoryOrOpportunityError = action.error;
        });
    },
});

export const {
    clearGivingCategory,
    setGivingCategory,
    clearGivingOpportunity,
    setGivingOpportunity,
    setGivingCategories,
    setGivingOpportunitySearchTerm
} = givingSlice.actions;

export default givingSlice.reducer;
