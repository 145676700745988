import React, { useMemo } from 'react';

import { GivingFundingCategory } from '../../../../../types/GivingFundingCategory';
import GivingOpportunity from '../../../../../types/GivingOpportunity';
import TableColumn from '../../../../../types/TableColumn';

import Table from '../../../../../components/Table';

type GivingOpportunityTableProps = {
    opportunities: GivingOpportunity[]
}

const GivingOpportunityTable: React.FC<GivingOpportunityTableProps> = ({opportunities}) => {
    const formatFundCategory = (fc: GivingFundingCategory) => fc.replace(/_/g, ' ')
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
            .join(' ');

    const formatTotalUsdCentsDonated = (totalUsdCentsDonated: number) => `$${(totalUsdCentsDonated / 100).toFixed(2)}`;

    const columns = useMemo(
        () => {
            let columnsArray: Array<TableColumn> = [
                {
                    Header: 'Funding Category',
                    accessor: (d) => formatFundCategory(d.fundingCategory),
                },
                {
                    Header: 'Total Amount Donated (USD)',
                    accessor: (d) => formatTotalUsdCentsDonated(d.totalUsdCentsDonated || 0),
                },
            ];

            return columnsArray;
        }, []);

    const data = useMemo(
        () => {
            return opportunities;
        }, [opportunities]);

    return (
        <div>
            <Table
                columns={columns}
                data={data}
                getData={() => {}}
                pageNumber={0}
                pageSize={1}
                showPagination={false}
                totalNumberOfItems={opportunities.length}
            />
        </div>
    );
}

export default GivingOpportunityTable;
