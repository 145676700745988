import React, {useState} from 'react';
import ThreadComment from "../../../ThreadComment";
import Icon from "../../../Icon";
import Button, {ButtonThemes} from "../../../Button";
import ThreadCommentForm from "../../../ThreadComment/ThreadCommentForm";
import EventPost from "../../../../types/EventPost";
import Thread from "../../../../types/Thread";

type Props = {
    disableCommenting?: boolean
    inFlaggedContent?: boolean
    post: EventPost | Thread
}

const Comments: React.FC<Props> = ({
    disableCommenting,
    inFlaggedContent,
    post
}) => {
    const [showComments, setShowComments] = useState(false);

    return (
        <>
            {inFlaggedContent ? (
                <div className="c-feed-thread__focus-comment">
                    {post.focusComment && (
                        <ThreadComment
                            comment={post.focusComment}
                            disableReply
                            isReply={false}
                            thread={post}
                        />
                    )}
                </div>
            ) : (
                <>
                    <div className="c-feed-thread__info">
                        <div className="c-feed-thread__info__comments">
                            <Icon type="comments" /> {post.commentCount} Comment{post.commentCount !== 1 && "s"}
                        </div>
                    </div>

                    <div className="c-feed-thread__comments">
                        {post.commentCount > 0 && (
                            <Button
                                className="c-feed-thread__comments__toggle"
                                theme={ButtonThemes.Link}
                                onClick={() => setShowComments(!showComments)}
                            >
                                {showComments ? 'Hide' : 'View'} {post.commentCount === 1 ? "1 comment" : `all ${post.commentCount} comments`}
                            </Button>
                        )}



                        {(post.threadComments && showComments === true) && (
                            <div className="c-feed-thread__comments__comments">
                                {post.threadComments.map((comment) => (
                                    <ThreadComment
                                        comment={comment}
                                        disableReply={disableCommenting}
                                        isReply={false}
                                        key={comment.uuid}
                                        thread={post}
                                    />
                                ))}
                            </div>
                        )}

                        {!disableCommenting && (
                            <ThreadCommentForm
                                thread={post}
                                parentUuid={null}
                                placeholder="Add a comment..."
                                onComplete={() => setShowComments(true)}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default Comments;
