import './SchoolFeed.scss';

import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import clone from 'clone';
import { useRouteMatch } from "react-router";

import { setProfileTypeVisibilityInMetadata } from "../../../../utils/utils";

import { getFeed } from '../../../../store/slices/feed';
import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';

import ActivityAlerts from "./ActivityAlerts";
import ActivityIndicator from '../../../../components/ActivityIndicator';
import Feed from '../../../../components/Feed';
import H5 from '../../../../components/H5';
import Icon from "../../../../components/Icon";
import OtherAdminUsers from "../../../../components/OtherAdminUsers";
import PageHeader from '../../../../components/PageHeader';
import RecentActivity from './RecentActivity';
import TableAuthorFilter from "../../../../components/Table/TableAuthorFilter";
import { ProfileTypes } from "../../../../utils/enums";

const SchoolFeed = () => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();

    const [atEnd, setAtEnd] = useState<boolean>(true);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [isInfiniteScrolling, setIsInfiniteScrolling] = useState<boolean>(false);
    const [filterByUserId, setFilterByUserId] = useState<number>(null);
    const [flaggedContentPage] = useState<boolean>(match.path.includes('/flagged'));

    const { feedItems, feedMetadata, isGettingFeed } = useTypedSelector((state) => state.feed);
    const { activeSchool } = useTypedSelector((state) => state.schools);

    useEffect(() => {
        const initialize = async () => {
            try {
                let clonedMetadata = clone(feedMetadata);
                if(flaggedContentPage) {
                    clonedMetadata.flagged = true;
                } else {
                    delete clonedMetadata.flagged;
                }

                clonedMetadata = setProfileTypeVisibilityInMetadata(clonedMetadata, match?.params?.profileType);

                let res: any = await dispatch(getFeed({schoolId: match?.params?.schoolId, feedMetadata: clonedMetadata})).unwrap();
                setAtEnd(res.isAtEnd);
            } catch(err) {
                console.log('SchoolFeed initialize err', err);
            } finally {
                setInitialized(true);
            }
        };

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.params?.schoolId, match?.params.profileType]);

    const handleInfiniteScroll = async () => {
        if(isGettingFeed || isInfiniteScrolling || atEnd) {
            return;
        }

        try {
            setIsInfiniteScrolling(true);
            let res: any = await dispatch(getFeed({isUpdate: true, schoolId: activeSchool.tenantId}));
            setAtEnd(res.payload.isAtEnd);
        } catch(err) {
            console.log('SchoolFeed handleInfiniteScroll err', err);
            setAtEnd(true);
        } finally {
            setIsInfiniteScrolling(false);
        }
    };

    const handleShowOtherUsersPosts = (userId) => {
        if (userId === filterByUserId) {
            setFilterByUserId(null);
            onMetadataChange({created_by_user_id: null, my_posts: null});
        } else {
            setFilterByUserId(userId);
            onMetadataChange({created_by_user_id: userId, my_posts: true});
        }
    };

    const onMetadataChange = async (changes, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(feedMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };
            let res: any = await dispatch(getFeed({isUpdate, feedMetadata: clonedMetadata}));
            setAtEnd(res.payload.isAtEnd);
        } catch(err) {
            console.log('SchoolFeed onMetadataChange err', err);
        }
    };

    return (
        <div className="school-feed">
            <PageHeader>
                <H5>
                    {flaggedContentPage ? (
                        <><Icon type="flag" />Flagged Content</>
                    ) : (
                        <><Icon type="navigation-activity-feed" />Latest Activity</>
                    )}
                </H5>
            </PageHeader>

            <div className="school-feed__inner">
                <div className="school-feed__left-col">

                    {!flaggedContentPage && (
                        <div className="school-feed__my-content-container">
                            <TableAuthorFilter
                                metadata={feedMetadata}
                                onMetadataChange={onMetadataChange}
                            />
                        </div>
                    )}

                    {feedItems.length === 0 && !isGettingFeed && initialized ? (
                        <div className="school-feed__empty">
                            There's nothing here.
                        </div>
                    ) : (
                        <>
                            {isGettingFeed ? (
                                    <ActivityIndicator />
                                ) : (
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={handleInfiniteScroll}
                                        hasMore={!atEnd}
                                    >
                                        <Feed items={feedItems} />

                                        {isInfiniteScrolling && (
                                            <div className="school-feed__infinite-scrolling-indicator">
                                                <ActivityIndicator color="white" />
                                            </div>
                                        )}

                                        {atEnd && !isInfiniteScrolling && (
                                            <div className="school-feed__at-end">
                                                End of {flaggedContentPage ? 'list' : 'feed'}
                                            </div>
                                        )}
                                    </InfiniteScroll>
                            )}
                        </>
                    )}
                </div>

                {!flaggedContentPage && match?.params.profileType === ProfileTypes.Student && (
                    <div className="school-feed__right-col">
                        <RecentActivity
                            limit={5}
                        />
                        <ActivityAlerts
                            limit={5}
                        />

                        <OtherAdminUsers
                            onClickUser={(user) => handleShowOtherUsersPosts(user.userId)}
                            highlightUserId={filterByUserId}
                            limit={5}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
export default SchoolFeed;
