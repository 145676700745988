import './SchoolEvents.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useHistory, useRouteMatch } from "react-router";
import clone from "clone";

import NewContentMenuOption from "../../../../types/NewContentMenuOption";
import TableColumn from "../../../../types/TableColumn";
import TableFilterMenuOption from "../../../../types/TableFilterMenuOption";

import { isArrayNullOrEmpty, setProfileTypeVisibilityInMetadata } from "../../../../utils/utils";
import { CAPABILITIES, userCan } from '../../../../utils/roles';
import { LIST_TYPES } from "../../../../utils/constants";

import { clearEvent, clearEventsMetadata, getEvents, setSearchTerm } from "../../../../store/slices/events";
import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../store/reducers';

import ActivityIndicator from "../../../../components/ActivityIndicator";
import Button, { ButtonSizes, ButtonThemes } from "../../../../components/Button";
import CreateEditEvent from "../../../Content/Events/CreateEditEvent";
import EventTableMoreMenu from "./EventTableMoreMenu";
import GridList from "../../../../components/GridList";
import H5 from "../../../../components/H5";
import Icon from "../../../../components/Icon";
import ListTypeSelector from "../../../../components/ListTypeSelector";
import NewContentMenu from "../../../../components/NewContentMenu";
import NewContentModal from "../../../../components/NewContentModal";
import PageHeader from "../../../../components/PageHeader";
import Table from "../../../../components/Table";
import TableAuthorFilter from "../../../../components/Table/TableAuthorFilter";
import TableFilterMenu from "../../../../components/Table/TableFilterMenu";
import TableFilterRow from "../../../../components/Table/TableFilterRow";
import TableFilterRowDivider from "../../../../components/Table/TableFilterRow/TableFilterRowDivider";
import TableFlaggedOnlyToggle from "../../../../components/Table/TableFlaggedOnlyToggle";
import TablePublishedAt from '../../../../components/Table/TablePublishedAt';
import TableRowImage from "../../../../components/Table/TableRowImage";
import TableSearch from '../../../../components/TableSearch';
import { ListTypes } from "../../../../utils/enums";

type Props = {
    disablePending?: boolean
    futureOnly?: boolean
    onRowClick?: Function
}

const SchoolEvents: React.FC<Props> = ({
    disablePending,
    futureOnly,
    onRowClick
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const [initialized, setInitialized] = useState(false);
    const [selectedListType, setSelectedListType] = useState<ListTypes>(ListTypes.Table);
    const [showNewContentMenu, setShowNewContentMenu] = useState(false);
    const [showNewContentModal, setShowNewContentModal] = useState(false);

    const { isGettingEvents, events, eventsMetadata, searchTerm } = useTypedSelector((state) => state.events);

    useEffect(() => {
        const initialize = async () => {
            try {
                let clonedEventsMetadata = clone(eventsMetadata);
                clonedEventsMetadata.future_only = futureOnly ? futureOnly : false;
                clonedEventsMetadata = setProfileTypeVisibilityInMetadata(clonedEventsMetadata, params.profileType);
                await dispatch(getEvents({schoolId: params?.schoolId, eventsMetadata: clonedEventsMetadata})).unwrap();
            } catch(err) {
                console.log('SchoolEvents initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        initialize();

        return () => {
            setShowNewContentMenu(false);
            dispatch(clearEvent());
            dispatch(clearEventsMetadata());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Image',
                    Cell: ({row: { original }}) => (
                        <TableRowImage
                            artifact={!isArrayNullOrEmpty(original.artifacts) ? original.artifacts[0] : original.artifact}
                            flagged={original.flagged}
                        />
                    ),
                    hasImage: true
                }, {
                    Header: 'Title',
                    accessor: 'title',
                    sortBy: 'title'
                }, {
                    Header: 'Author',
                    accessor: (d) => {
                        if(d.profile) {
                            return `${d.profile.firstName} ${d.profile.lastName}`;
                        } else {
                            return null;
                        }
                    },
                    sortBy: 'profileFirstName'
                }, {
                    Header: 'Location',
                    accessor: 'location.name',
                    sortBy: 'locationName'
                }, {
                    Header: 'Date',
                    accessor: (d) => (
                        <div className="event-date">
                            {d.startAt ? moment(d.startAt).format('MMM DD, YYYY') : ''}<br />
                            {d.startAt ? `${moment(d.startAt).format('hh:mma')}` : ''}{d.endAt ? <> - {moment(d.endAt).format('h:mma')}</> : ''}
                        </div>
                    ),
                    sortBy: 'startAt'
                }, {
                    Header: 'Date Published',
                    accessor: (d) => <TablePublishedAt post={d} />,
                    sortBy: 'publish'
                }, {
                    Header: 'Views',
                    accessor: 'totalViews',
                },
            ];

            if(!onRowClick) {
                columns.push({
                    Header: '',
                    id: 'actions',
                    Cell: ({row: { original }}) => renderMoreMenu(original)
                });
            }

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onRowClick]
    );

    const data = useMemo(
        () => {
            return events;
        },
        [events]
    );

    const filterOptions: Array<TableFilterMenuOption> = useMemo(
        () => {
            return (
                [{
                    isActive: !eventsMetadata.future_only && !eventsMetadata.past_only,
                    onSelect: () => {
                        onMetadataChange({future_only: false, past_only: false, page_num: 0}, false);
                    },
                    text: "All Events"
                }, {
                    isActive: eventsMetadata.future_only,
                    onSelect: () => {
                        onMetadataChange({future_only: !eventsMetadata.future_only, past_only: false, page_num: 0}, false);
                    },
                    text: "Upcoming"
                }, {
                    isActive: eventsMetadata.past_only,
                    onSelect: () => {
                        onMetadataChange({past_only: !eventsMetadata.past_only, future_only: false, page_num: 0}, false);
                    },
                    text: "Past"
                }]
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [eventsMetadata]
    );

    const newContentMenuOptions: Array<NewContentMenuOption> = useMemo(
        () => {
            return [
                {
                    icon: 'navigation-events',
                    onClick: () => handleSelectNewEventType('event'),
                    subtext: 'Create a new custom event',
                    text: 'New Event',
                }, {
                    icon: 'integration',
                    onClick: () => handleSelectNewEventType('integration'),
                    subtext: 'Publish an event imported from an RSS or API',
                    text: 'RSS or API',
                }
            ]
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const handleRowClick = ({original}) => {
        if(!onRowClick) {
            return null;
        }

        onRowClick(original);
    };

    const handleSelectNewEventType = (type) => {
        if(type === 'event') {
            setShowNewContentModal(true);
        } else {
            history.push(`/school/${params.schoolId}/${params.profileType}/integrations/events/list`);
        }

        setShowNewContentMenu(false);
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(eventsMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes
            };

            if(changes.search != null) {
                clonedMetadata.page_num = 0;
            }

            return await dispatch(getEvents({isUpdate, eventsMetadata: clonedMetadata})).unwrap();
        } catch(err) {
            console.log('SchoolEvents onMetadataChange err', err);
        }
    };

    const renderMoreMenu = (item) => {
        if(item.removedByMods) {
            return null;
        }

        return (
            <EventTableMoreMenu
                getAfterDelete={() => onMetadataChange({}, false)}
                item={item}
                setShowNewContentModal={setShowNewContentModal}
            />
        );
    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="school-events">
            <PageHeader>
                <H5>
                    <Icon type="navigation-events" />

                    Events
                </H5>
            </PageHeader>

            <TableFilterRow>
                <TableFilterMenu
                    label="View"
                    options={filterOptions}
                />

                <TableAuthorFilter
                    metadata={eventsMetadata}
                    onMetadataChange={onMetadataChange}
                />

                <TableFlaggedOnlyToggle
                    label="Flagged only"
                    onToggleChange={() => onMetadataChange({flagged: !eventsMetadata.flagged, page_num: 0}, false)}
                    value={eventsMetadata.flagged}
                />

                <TableFilterRowDivider />

                <ListTypeSelector
                    onListTypeSelected={(listType) => setSelectedListType(listType)}
                    selected={selectedListType}
                />

                <TableFilterRowDivider />

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0,search: searchTerm}, false)}
                    placeholder="Search Events"
                    searchTerm={searchTerm}
                    size="small"
                />

                {!onRowClick && (
                    <Button
                        onClick={() => {
                            if(userCan(CAPABILITIES.MANAGE_INTEGRATIONS)) {
                                setShowNewContentMenu(true);
                            } else {
                                setShowNewContentModal(true);
                            }
                        }}
                        size={ButtonSizes.Small}
                    >
                        New Event
                    </Button>
                )}

                <NewContentMenu
                    close={() => setShowNewContentMenu(false)}
                    options={newContentMenuOptions}
                    show={showNewContentMenu}
                />
            </TableFilterRow>

            {isGettingEvents ? (
                <ActivityIndicator size="large" />
            ) : (
                <>
                    {isArrayNullOrEmpty(data) && initialized ? (
                        <>
                            No events to display. <Button
                                onClick={() => setShowNewContentModal(true)}
                                theme={ButtonThemes.Link}
                            >
                                Create One?
                            </Button>
                        </>
                    ) : (
                        <>
                            {selectedListType === LIST_TYPES.TABLE ? (
                                <Table
                                    columns={columns}
                                    data={data}
                                    disablePending={disablePending}
                                    getData={onMetadataChange}
                                    onRowClick={onRowClick && handleRowClick}
                                    pageNumber={eventsMetadata.page_num}
                                    pageSize={eventsMetadata.page_size}
                                    sortBy={eventsMetadata.sort}
                                    sortOrder={eventsMetadata.order}
                                    totalNumberOfItems={eventsMetadata.total}
                                />
                            ) : (
                                <GridList
                                    createCardButtonText="Create an Event"
                                    createCardNewContentMenuOptions={newContentMenuOptions}
                                    createCardText="Create an event, invite people to save it, or attend with other students"
                                    disablePending={disablePending}
                                    isLastPage={Math.ceil(eventsMetadata.total / eventsMetadata.page_size) === eventsMetadata.page_num + 1}
                                    items={data}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: eventsMetadata.page_num + 1})}
                                    onOverlayClick={onRowClick ? (item) => onRowClick(item) : null}
                                    onPreviousPageClick={() => onMetadataChange({page_num: eventsMetadata.page_num - 1})}
                                    overlayButtonText={onRowClick ? 'Select' : 'Edit'}
                                    pageNumber={eventsMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                <CreateEditEvent
                    close={() => setShowNewContentModal(false)}
                />
            </NewContentModal>
        </div>
    );
};

export default SchoolEvents;
