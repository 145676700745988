import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clone from 'clone';
import { useRouteMatch } from 'react-router';

import { isArrayNullOrEmpty } from '../../../../../utils/utils';
import { ListTypes, ProfileStatusLabels } from '../../../../../utils/enums';

import {
    clearAlumniMetadata,
    clearInviteAlumniViaCsvImportError,
    getAlumni,
    setSearchTerm,
} from '../../../../../store/slices/alumni';
import { useAppDispatch } from '../../../../../store';
import { useTypedSelector } from '../../../../../store/reducers';

import ActivityIndicator from '../../../../../components/ActivityIndicator';
import AlumniTableMoreMenu from './AlumniTableMoreMenu';
import Avatar from '../../../../../components/Avatar';
import Button, { ButtonSizes } from '../../../../../components/Button';
import GridList, { GridListTypes } from '../../../../../components/GridList';
import H5 from '../../../../../components/H5';
import Icon from '../../../../../components/Icon';
import InviteStudentsViaCsvImport from '../../SchoolStudents/ListStudents/InviteStudentsViaCsvImport';
import ListTypeSelector from '../../../../../components/ListTypeSelector';
import Modal from '../../../../../components/Modal';
import PageHeader from '../../../../../components/PageHeader';
import { ProfileNounLookup } from '../../SchoolStudents/ListStudents';
import SuccessModal from '../../../../../components/Modal/SuccessModal';
import Table from '../../../../../components/Table';
import TableColumn from '../../../../../types/TableColumn';
import TableFilterRow from '../../../../../components/Table/TableFilterRow';
import TableFilterRowDivider from '../../../../../components/Table/TableFilterRow/TableFilterRowDivider';
import TableSearch from '../../../../../components/TableSearch';
import Profile from '../../../../../types/Profile';

const ListAlumni: React.FC = () => {
    const dispatch = useAppDispatch();
    const {params} = useRouteMatch();

    const [initialized, setInitialized] = useState<boolean>(false);
    const [selectedListType, setSelectedListType] = useState<ListTypes>(ListTypes.Table);
    const [showUploadCsvModal, setShowUploadCsvModal] = useState<boolean>(false);
    const [showUploadCsvSuccessModal, setShowUploadCsvSuccessModal] = useState<boolean>(false);

    const {activeSchool} = useTypedSelector((state) => state.schools);
    const {alumni, alumniMetadata, isGettingAlumni, searchTerm} = useTypedSelector((state) => state.alumni);

    useEffect(() => {
        const initialize = async () => {
            try {
                await dispatch(getAlumni({
                    schoolId: params?.schoolId,
                })).unwrap();
            } catch (err) {
                console.log('ListAlumni initialize err', err);
            } finally {
                setInitialized(true);
            }
        };

        initialize();

        return () => {
            dispatch(clearAlumniMetadata());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(
        () => {
            let columns: Array<TableColumn> = [
                {
                    Header: 'Name',
                    id: 'student',
                    Cell: ({row: {original}}) => (
                        <div className="users-table__user">
                            <Avatar
                                artifact={original.artifact}
                                flagged={original.flagged || original.flaggedContent}
                            />

                            <div className="users-table__name">
                                {original.firstName} {original.lastName}
                            </div>
                        </div>
                    ),
                    hasImage: true,
                    sortBy: 'name',
                }, {
                    Header: 'Status',
                    accessor: (d) => ProfileStatusLabels[d.status],
                }, {
                    Header: 'Email',
                    accessor: 'emailAddress',
                }, {
                    Header: 'Employer',
                    accessor: 'employer',
                }, {
                    Header: 'Job Title',
                    accessor: 'jobTitle',
                }, {
                    Header: 'Tags',
                    id: 'tags',
                    Cell: ({row: {original}}) => {
                        let tags = original.tags ? original.tags.map((t) => t.tag.text) : [];
                        return (
                            <div className="users-table__majors">
                                {isArrayNullOrEmpty(tags) ? '--' : tags.join(', ')}
                            </div>
                        );
                    },
                }, {
                    Header: 'Class Year',
                    id: 'classYear',
                    Cell: ({row: {original}}) => {
                        return (
                            <div className="users-table__majors">
                                {isArrayNullOrEmpty(original.gradyear) || !original.gradyear[0] ? '--' : (original.gradyear[0].displayText || original.gradyear[0].description)}
                            </div>
                        );
                    },
                    sortBy: 'classYear',
                }, {
                    Header: '',
                    id: 'actions',
                    Cell: ({row: {original}}) => renderMoreMenu(original),
                },
            ];

            return columns;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const renderMoreMenu = (item: Profile) => {
        return (
            <AlumniTableMoreMenu
                getAfterDelete={() => onMetadataChange({}, false)}
                item={item}
            />
        );
    };

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(alumniMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes,
            };
            return await dispatch(getAlumni({schoolId: activeSchool.tenantId, isUpdate, alumniMetadata: clonedMetadata})).unwrap();
        } catch (err) {
            console.log('ListAlumni onMetadataChange err', err);
        }
    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="list-alumni">
            <PageHeader>
                <H5>
                    <Icon type="navigation-users"/> Alumni
                </H5>
            </PageHeader>

            <TableFilterRow>
                <ListTypeSelector
                    onListTypeSelected={(listType) => setSelectedListType(listType)}
                    selected={selectedListType}
                />

                <TableFilterRowDivider/>

                <TableSearch
                    handleChange={(value) => dispatch(setSearchTerm(value))}
                    handleClear={handleClearSearchTerm}
                    handleSubmit={() => onMetadataChange({page_num: 0, search: searchTerm}, false)}
                    placeholder="Search Alumni"
                    searchTerm={searchTerm}
                    size="small"
                />

                <Button
                    onClick={() => setShowUploadCsvModal(true)}
                    size={ButtonSizes.Small}
                >
                    Add Alumni
                </Button>
            </TableFilterRow>

            {isGettingAlumni ? (
                <ActivityIndicator size="large"/>
            ) : (
                <>
                    {isArrayNullOrEmpty(alumni) && initialized ? (
                        <>
                            No alumni to display.
                        </>
                    ) : (
                        <>
                            {selectedListType === ListTypes.Table ? (
                                <Table
                                    columns={columns}
                                    data={alumni}
                                    getData={onMetadataChange}
                                    pageNumber={alumniMetadata.page_num}
                                    pageSize={alumniMetadata.page_size}
                                    sortBy={alumniMetadata.sort}
                                    sortOrder={alumniMetadata.order}
                                    totalNumberOfItems={alumniMetadata.total}
                                />
                            ) : (
                                <GridList
                                    isLastPage={Math.ceil(alumniMetadata.total / alumniMetadata.page_size) === alumniMetadata.page_num + 1}
                                    items={alumni}
                                    listType={GridListTypes.Student}
                                    moreMenuComponent={renderMoreMenu}
                                    onNextPageClick={() => onMetadataChange({page_num: alumniMetadata.page_num + 1})}
                                    onPreviousPageClick={() => onMetadataChange({page_num: alumniMetadata.page_num - 1})}
                                    pageNumber={alumniMetadata.page_num}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            <Modal
                className="c-success-modal"
                declineButtonOnClick={() => {
                    setShowUploadCsvModal(false);
                    dispatch(clearInviteAlumniViaCsvImportError());
                }}
                show={showUploadCsvModal}
                title="Import Alumni"
            >
                <InviteStudentsViaCsvImport
                    noun={ProfileNounLookup.alumni}
                    onSuccess={() => {
                        setShowUploadCsvModal(false);
                        setShowUploadCsvSuccessModal(true);
                    }}
                />
            </Modal>

            <SuccessModal
                buttonOnClick={() => {
                    setShowUploadCsvSuccessModal(false);
                }}
                buttonText="Done"
                show={showUploadCsvSuccessModal}
                title="Nice job!"
            >
                <p>
                    Invite(s) have been sent to the specified users.
                </p>
            </SuccessModal>
        </div>
    );
};

export default ListAlumni;
