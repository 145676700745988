/**
 *
 * @param {Object} artifact - artifact from response
 * @param {string} directory - select from 220, 568, 667, 736, 812, 869, or original
 */

import Artifact from "../types/Artifact";

const buildImageSrc = (artifact: Artifact, directory: '220' | '568' | '667' | '736' | '812' | '869' | 'original') => {
    if(!artifact) {
        return null;
    }
    // console.log('buildImageSrc', artifact);
    if (artifact.type === 'video') {
        return artifact ? `${artifact.baseUrl}${artifact.bucket}/video/${artifact.thumbnailKey}` : '';
    } else {
        return artifact && directory ? `${artifact.baseUrl}${artifact.bucket}/${directory}/${artifact.objectKey}` : '';
    }
};

export default buildImageSrc;
