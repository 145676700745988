import './MainHeader.scss';

import React, { useMemo } from 'react';
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation, useRouteMatch } from "react-router";

import TableFilterMenuOption from "../../types/TableFilterMenuOption";

import { isObjectNullOrEmpty } from '../../utils/utils';

import { useTypedSelector } from '../../store/reducers';

import Avatar from '../Avatar';
import Button, { ButtonSizes } from "../Button";
import Logo from '../Logo';
import MainNav from "./MainNav";
import TableFilterMenu from "../Table/TableFilterMenu";

const MainHeader: React.FC = () => {
    const { activeSchool } = useTypedSelector((state) => state.schools);
    const { params } = useRouteMatch();
    const { pathname } = useLocation();
    const history = useHistory();
    const isMobile = useMediaQuery({ query: '(max-width: 47.5em)' });

    const filterOptions: Array<TableFilterMenuOption> = useMemo(
        () => {
            return (
                [{
                    isActive: pathname.includes('/admit/'),
                    onSelect: () => history.push(activeSchool.admitActive ? `/school/${params.schoolId}/admit/list` : `/school/${params.schoolId}/admit/activate`),
                    text: "Admit"
                }, {
                    isActive: pathname.includes('/student/'),
                    onSelect: () => history.push(activeSchool.studentActive ? `/school/${params.schoolId}/student/feed` : `/school/${params.schoolId}/student/activate`),
                    text: "Campus"
                }, {
                    isActive: pathname.includes('/alumni/'),
                    onSelect: () => history.push(activeSchool.alumniActive ? `/school/${params.schoolId}/alumni/feed` : `/school/${params.schoolId}/alumni/activate`),
                    text: "Alumni"
                }, {
                    isActive: pathname.includes('/admin/'),
                    onSelect: () => history.push(`/school/${params.schoolId}/admin/admins`),
                    text: "Admin"
                }]
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [activeSchool, pathname]
    );

    return (
        <header className="c-main-header">
            <Logo />

            {params.schoolId != null && (
                isMobile ? (
                    <TableFilterMenu options={filterOptions} />
                ) : (
                    <div className="c-main-header__account-type-tabs">
                        {filterOptions.map((option) => (
                            <Button
                                className={`c-main-header__account-type-tab ${option.isActive ? 'a-main-header__account-type-tab--active' : ''}`}
                                onClick={option.onSelect}
                                size={ButtonSizes.Small}
                            >
                                {option.text}
                            </Button>
                        ))}
                    </div>
                )
            )}

            {!isObjectNullOrEmpty(activeSchool) && (
                <div className="c-main-header__active-school">
                    {activeSchool.artifact && (
                        <Avatar
                            artifact={activeSchool.artifact}
                        />
                    )}
                    <div className="c-main-header__active-school-text">
                        {activeSchool.name}
                    </div>
                </div>
            )}

            <Button
                className="c-main-header__menu-button"
                noStyles
                onClick={() => document.body.classList.toggle('open-menu')}
            >
                <div className="c-main-header__menu-button__inner">
                    <div className="c-main-header__menu-line line1" />
                    <div className="c-main-header__menu-line line2">
                        <div className="c-main-header__menu-line line4" />
                    </div>
                    <div className="c-main-header__menu-line line3" />
                </div>
            </Button>

            <MainNav />
        </header>
    );
};

export default MainHeader;
