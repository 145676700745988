import './InviteStudentsViaCsvImport.scss';

import React, { useEffect, useState } from 'react';
import { useRouteMatch } from "react-router";

import { isArrayNullOrEmpty } from "../../../../../../utils/utils";
import { ProfileNounLookup } from "../../ListStudents";

import IFile from "../../../../../../types/IFile";

import {
    clearInviteStudentsViaCsvImportError,
    inviteStudentsViaCsvImport,
    setInviteStudentsViaCsvImportError,
} from "../../../../../../store/slices/students";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import useImport from "../../../../../../hooks/useImport";

import A from "../../../../../../components/A";
import CsvFormError from "../../../../../../components/CsvFormError";
import FileUpload from "../../../../../../components/FileUpload";
import FormRow from "../../../../../../components/FormRow";
import { inviteAdmitsViaCsvImport, setDefaultWelcomeMessage } from "../../../../../../store/slices/admits";
import { inviteAlumniViaCsvImport } from "../../../../../../store/slices/alumni";
import Textbox from "../../../../../../components/Textbox";
import {handleTextChange} from "../../../../../../utils/handle-changes";
import ButtonRow from "../../../../../../components/ButtonRow";
import Button, {ButtonThemes, ButtonTypes} from "../../../../../../components/Button";
import Icon from "../../../../../../components/Icon";
import RadioGroup from "../../../../../../components/RadioGroup";
import Radio from "../../../../../../components/Radio";

type Props = {
    noun: ProfileNounLookup
    onSuccess: Function
}

const InviteStudentsViaCsvImport: React.FC<Props> = (
    {
        noun,
        onSuccess,
    }
) => {
    const MAX_IMPORT_COUNT = 500;

    const dispatch = useAppDispatch();
    const { params } = useRouteMatch();
    const { testImportCount } = useImport();

    const [useTenantMessage, setUseTenantMessage] = useState(false);

    const [csvFile, setCsvFile] = useState(null);
    const [isImporting, setIsImporting] = useState(false);

    const {
        inviteStudentsViaCsvImportError,
        isInvitingStudentsViaCsvImport,
    } = useTypedSelector((state) => state.students);
    const {
        defaultWelcomeMessage,
        inviteAdmitsViaCsvImportError ,
        isInvitingAdmitsViaCsvImport,
    } = useTypedSelector((state) => state.admits);
    const {
        inviteAlumniViaCsvImportError ,
        isInvitingAlumniViaCsvImport,
    } = useTypedSelector((state) => state.alumni);

    const { activeSchool } = useTypedSelector((state) => state.schools);

    const { profile } = useTypedSelector((state) => state.auth);

    useEffect(() => {
        if (profile?.admitWelcomeMessage) {
            dispatch(setDefaultWelcomeMessage(profile.admitWelcomeMessage));
        }
    }, [profile, activeSchool]);

    useEffect(() => {
        setIsImporting(isInvitingStudentsViaCsvImport || isInvitingAdmitsViaCsvImport || isInvitingAlumniViaCsvImport)
    }, [isInvitingAdmitsViaCsvImport, isInvitingAlumniViaCsvImport, isInvitingStudentsViaCsvImport]);

    const handleDrop = async (goodFile, badFiles) => {
        console.log(goodFile, badFiles);

        dispatch(clearInviteStudentsViaCsvImportError());
        try {
            if (!isArrayNullOrEmpty(badFiles)) {
                dispatch(setInviteStudentsViaCsvImportError({message: 'Incorrect file type chosen. Please select another.'}));
            } else {
                setCsvFile(goodFile);
            }
        } catch(err) {
            console.log('InviteStudentsViaCsvImport handleDrop err', err);
        }
    }

    const handleSubmit = async () => {
        // setIsImporting(true)
        if(noun === ProfileNounLookup.student) {
            await dispatch(inviteStudentsViaCsvImport({csvFile})).unwrap();
        }
        else if (noun === ProfileNounLookup.alumni) {
            await dispatch(inviteAlumniViaCsvImport({csvFile})).unwrap();
        }
        else {
            await dispatch(inviteAdmitsViaCsvImport({csvFile, useTenantMessage})).unwrap();
        }
        // setIsImporting(false);
        onSuccess();
    }

    const importValidator = async (file: IFile) => {
        return await testImportCount(file, MAX_IMPORT_COUNT);
    }
    const onImportFailure = () => {
        dispatch(setInviteStudentsViaCsvImportError({error: `  You can only import up to ${MAX_IMPORT_COUNT} users at a time.  `}));
    };

    const handleChange = (name, value) => {
        if (name === 'useTenantMessage') {
            setUseTenantMessage(value);
        } else {
            dispatch(setDefaultWelcomeMessage(value));
        }
    };


    const importError = inviteStudentsViaCsvImportError || inviteAdmitsViaCsvImportError || inviteAlumniViaCsvImportError;


    return (
        <div className="invite-users-via-csv-import-form">
            <FormRow>Upload a properly-formatted CSV file containing {noun.toLowerCase()} data to invite them to the app.</FormRow>

            <FormRow>Download Template: <A href={`/abuzz-${params.profileType}-import-template.csv`}>Click here to download template</A></FormRow>

            <FormRow>
                {csvFile ? (
                    <p className="invite-users-via-csv-import-form__uploaded-file">
                        <Icon type="document-regular" /> {csvFile.name}
                        <Button
                            onClick={() => {
                                setCsvFile(null);
                            }}
                            type={ButtonTypes.Button}
                            theme={ButtonThemes.Link}
                            className="c-delete-file"
                        >
                            <Icon type="x" />
                        </Button>
                    </p>
                ) : (
                    <FileUpload
                        buttonText="Select File"
                        hideProgressBar
                        onDrop={handleDrop}
                        onFailure={onImportFailure}
                        secondaryText="Drag and drop your file here, or select to upload."
                        types=".csv"
                        validator={importValidator}
                    />
                )}
            </FormRow>
            {noun === ProfileNounLookup.admit && (
                <>
                    <FormRow>
                        <RadioGroup horizontal>
                            <Radio
                                checked={!useTenantMessage}
                                id={`chkProfileTypesAlumni`}
                                label="Use My Message"
                                name="useTenantMessage"
                                value={'false'}
                                onChange={() => handleChange('useTenantMessage', false)}
                            />

                            <Radio
                                checked={useTenantMessage}
                                id={`chkProfileTypesAdmit`}
                                label="Use Tenant Message"
                                name="useTenantMessage"
                                value={'true'}
                                onChange={() => handleChange('useTenantMessage', true)}
                            />
                        </RadioGroup>
                    </FormRow>
                    {useTenantMessage ? (
                        <FormRow>
                            <p><strong>Your import will use the school's default welcome message:</strong></p>
                            <p>{activeSchool.admitWelcomeMessage}</p>
                        </FormRow>
                    ) : (
                        <FormRow>
                            <Textbox
                                id="txtWelcomeMessage"
                                label="Welcome Message"
                                name="welcomeMessage"
                                type="textarea"
                                value={defaultWelcomeMessage || ''}
                                onChange={(e) => handleTextChange(handleChange, e)}
                            />
                            <p>You can enter a default welcome message here that will be used for just users you import if a welcome message is not included in the import.</p>
                        </FormRow>
                    )}
                </>
            )}


            {importError &&
                <FormRow>We've found the following error(s) during import. Please fix them and re-upload the file.</FormRow>
            }
            {importError &&
                (importError.error as string)
                .slice(1, importError.error.length - 1)
                .split(',')
                .map(message => message.slice(1, message.length - 1))
                .map(message => <CsvFormError error={{'message': message}}/>)
            }
            {importError && <br />}

            <ButtonRow>
                <Button
                    disabled={!csvFile}
                    onClick={(event) => {
                        event.preventDefault();
                        handleSubmit()
                    }}
                    showActivityIndicator={isImporting}
                    type={ButtonTypes.Submit}
                >
                    Save
                </Button>
            </ButtonRow>

            <A className="invite-users-via-csv-import-form__bottom-link" to={`/school/${params.schoolId}/${params.profileType}/imports`}>
                View Past Imports
            </A>
        </div>
    );
};

export default InviteStudentsViaCsvImport;
