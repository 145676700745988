import './SchoolGiving.scss';
import React, { useCallback, useEffect, useState } from 'react';
import clone from 'clone';
import { Route, Redirect, Switch, useHistory, useRouteMatch } from 'react-router';

import { ListTypes } from '../../../../utils/enums';
import GivingCategory from "../../../../types/GivingCategory";

import {
    getGivingCategories, getGivingOpportunities,
    setGivingOpportunitySearchTerm,
    setGivingCategory,
} from '../../../../store/slices/giving';
import { setSearchTerm } from "../../../../store/slices/news";
import { useAppDispatch } from '../../../../store';
import CreateEditGivingCategory from "../../../Content/Giving/CreateEditGivingCategory";
import CreateEditGivingOpportunity from "../../../Content/Giving/CreateEditGivingOpportunity";
import { useTypedSelector } from '../../../../store/reducers';

import Button, { ButtonSizes } from '../../../../components/Button';
import GivingCategories from "./GivingCategories";
import GivingOpportunities from "./GivingOpportunities";
import H5 from '../../../../components/H5';
import Icon from '../../../../components/Icon';
import ListTypeSelector from '../../../../components/ListTypeSelector';
import NewContentModal from '../../../../components/NewContentModal';
import PageHeader from '../../../../components/PageHeader';
import TableFilterRow from '../../../../components/Table/TableFilterRow';
import TableFilterButtons from '../../../../components/Table/TableFilterButtons';
import TableFilterRowDivider from '../../../../components/Table/TableFilterRow/TableFilterRowDivider';
import TableSearch from '../../../../components/TableSearch';



type Props = {
    disablePending?: boolean
    onRowClick?: Function
}

const SchoolGiving: React.FC<Props> = ({
    onRowClick
}) => {
    const dispatch = useAppDispatch();
    const match = useRouteMatch();
    const history = useHistory();
    const { params } = useRouteMatch();


    const [viewType, setViewType] = useState<'CATEGORIES' | 'OPPORTUNITIES'>('CATEGORIES');
    const [initialized, setInitialized] = useState(false);

    const [selectedListType, setSelectedListType] = useState<ListTypes>(ListTypes.Table);
    const [showNewContentModal, setShowNewContentModal] = useState(false);

    const {givingOpportunitiesMetadata, givingOpportunitySearchTerm} = useTypedSelector((state) => state.giving);

    useEffect(() => {
        const initialize = async () => {
            if (match.params?.categoriesOrOpportunities === 'categories') {
                setViewType('CATEGORIES');
            }
            if (match.params?.categoriesOrOpportunities === 'opportunities') {
                setViewType('OPPORTUNITIES');
            }
            try {
                await dispatch(getGivingCategories({schoolId: match?.params?.schoolId})).unwrap();
            } catch(err) {
                console.warn('SchoolGiving initialize err', err);
            } finally {
                setInitialized(true);
            }
        }

        if (!initialized) {
            initialize();
        }

        return () => {
            setShowNewContentModal(false);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const handleEditClick = (cat: GivingCategory) => {
        dispatch(setGivingCategory(cat));
        setShowNewContentModal(true);
    }

    const onMetadataChange = async (changes: any, isUpdate?: boolean) => {
        try {
            let clonedMetadata = clone(givingOpportunitiesMetadata);
            clonedMetadata = {
                ...clonedMetadata,
                ...changes,
            };
            return await dispatch(getGivingOpportunities({ isUpdate, givingOpportunitiesMetadata: clonedMetadata})).unwrap();
        } catch (err) {
            console.log('getGivingOpportunities onMetadataChange err', err);
        }
    };

    const handleClearSearchTerm = useCallback(() => {
        dispatch(setSearchTerm(''));
        onMetadataChange({search: '', page_num: 0}, false);
    }, [dispatch, onMetadataChange]);

    return (
        <div className="school-deals">
            <PageHeader>
                <H5>
                    <Icon type="classnote" />
                    Giving
                </H5>
            </PageHeader>


            <TableFilterRow>
                <TableFilterButtons
                    buttons={[{
                        isActive: viewType === 'CATEGORIES',
                        onClick: () => {
                            history.push(`/school/${params.schoolId}/${params.profileType}/giving/categories`)
                            setViewType('CATEGORIES')
                        },
                        text: "Categories"
                    }, {
                        isActive: viewType === 'OPPORTUNITIES',
                        onClick: () => {
                            history.push(`/school/${params.schoolId}/${params.profileType}/giving/opportunities`)
                            setViewType('OPPORTUNITIES')
                        },
                        text: "Opportunities"
                    }]}
                />


                {viewType === 'OPPORTUNITIES' ? (
                    <>
                        <TableFilterRowDivider />
                        <ListTypeSelector
                            onListTypeSelected={(listType) => setSelectedListType(listType)}
                            selected={selectedListType}
                        />
                        <TableFilterRowDivider />
                        <TableSearch
                            handleChange={(value) => dispatch(setGivingOpportunitySearchTerm(value))}
                            handleClear={handleClearSearchTerm}
                            handleSubmit={() => onMetadataChange({page_num: 0, search: givingOpportunitySearchTerm}, false)}
                            placeholder="Search Giving Opportunities"
                            searchTerm={givingOpportunitySearchTerm}
                            size="small"
                        />
                    </>
                ) : (
                    <div style={{flex: 1}}></div>
                )}
                <TableFilterRowDivider />

                <div className="school-deals__header-right-col">

                    {!onRowClick && (
                        <Button
                            onClick={() => setShowNewContentModal(true)}
                            size={ButtonSizes.Small}
                        >
                            New {viewType === 'CATEGORIES' ? 'Category' : 'Opportunity'}
                        </Button>
                    )}
                </div>
            </TableFilterRow>

            <Switch>
                <Route exact path="/school/:schoolId/:profileType/giving/categories" render={(props) => (
                    <GivingCategories {...props} handleEditClick={handleEditClick} />
                )} />
                <Route exact path="/school/:schoolId/:profileType/giving/opportunities" render={(props) => (
                    <GivingOpportunities {...props} handleEditClick={handleEditClick} selectedListType={selectedListType} />
                )} />
                <Redirect to="/school/:schoolId/:profileType/giving/categories" />
            </Switch>

            <NewContentModal
                close={() => setShowNewContentModal(false)}
                show={showNewContentModal}
                width="wide"
            >
                {viewType === "CATEGORIES" ? (
                    <CreateEditGivingCategory
                        close={() => setShowNewContentModal(false)}
                    />
                ) : (
                    <CreateEditGivingOpportunity
                        close={() => setShowNewContentModal(false)}
                    />
                )}
            </NewContentModal>
        </div>
    );
};

export default SchoolGiving;
