import React from 'react';

import { deleteEvent, setEvent } from "../../../../../store/slices/events";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import EventPost from "../../../../../types/EventPost";
import TableMoreMenu from "../../../../../components/Table/TableMoreMenu";

type Props = {
    getAfterDelete?: () => void
    item: EventPost
    setShowNewContentModal: (arg0: boolean) => void
}

const EventTableMoreMenu: React.FC<Props> = ({
    getAfterDelete,
    item,
    setShowNewContentModal,
}) => {
    const dispatch = useAppDispatch();

    const { isDeletingEvent } = useTypedSelector((state) => state.events);

    const handleDelete = async (post) => {
        try {
            await dispatch(deleteEvent({event: post})).unwrap();

            if(getAfterDelete) {
                getAfterDelete();
            }
        } catch(err) {
            console.log('EventTableMoreMenu handleDelete err', err);
        }
    };

    const handleEditEvent = (item) => {
        dispatch(setEvent(item));
        setShowNewContentModal(true);
    };

    return (
        <TableMoreMenu
            isDeleting={isDeletingEvent}
            item={item}
            getAfterModerate={getAfterDelete}
            onDeleteClick={() => handleDelete(item)}
            onEditClick={() => handleEditEvent(item)}
            showAttachToPushNotificationOption={item.publishedAt != null || item.status === 'A'}
        />
    );
};

export default EventTableMoreMenu;
