import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import clone from 'clone';

import { getStudentPostsAndComments } from "../../../../../../store/slices/students";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import ActivityIndicator from "../../../../../../components/ActivityIndicator";
import FeedFlaggedBanner from "../../../../../../components/Feed/FeedFlaggedBanner";
import FeedPostAndComment from "../../../../../../components/Feed/FeedPostAndComment";
import H6 from "../../../../../../components/H6";
import TableFlaggedOnlyToggle from "../../../../../../components/Table/TableFlaggedOnlyToggle";

const ViewStudentPosts: React.FC = () => {
    const dispatch = useAppDispatch();

    const [atEnd, setAtEnd] = useState<boolean>(false);
    const [isInfiniteScrolling, setIsInfiniteScrolling] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);

    const { isGettingStudentPostsAndComments, student, studentPostsAndComments, studentPostsAndCommentsByMonth, studentPostsAndCommentsMetadata } = useTypedSelector((state) => state.students);

    useEffect(() => {
        if((isGettingStudentPostsAndComments || studentPostsAndComments.length > 0) && !initialized) {
            setInitialized(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingStudentPostsAndComments]);

    const handleFlaggedFilterChange = async (filterByFlaggedOnly) => {
        try {
            let clonedMetadata = clone(studentPostsAndCommentsMetadata);
            clonedMetadata.flagged = filterByFlaggedOnly;
            clonedMetadata.page_num = 0;
            let res = await dispatch(getStudentPostsAndComments({profileId: student.profileId, schoolId: student.tenant.tenantId, studentPostsAndCommentsMetadata: clonedMetadata})).unwrap();
            setAtEnd(res.isAtEnd);
        } catch(err) {
            console.log('ViewStudentPosts handleFlaggedFilterChange err', err);
        }
    };

    const handleInfiniteScroll = async () => {
        if(isGettingStudentPostsAndComments || isInfiniteScrolling || atEnd) {
            return;
        }

        try {
            setIsInfiniteScrolling(true);
            let res: any = await dispatch(getStudentPostsAndComments({isUpdate: true, profileId: student.profileId, schoolId: student.tenant.tenantId})).unwrap();
            setAtEnd(res.isAtEnd);
        } catch(err) {
            console.log('ViewStudentPosts handleInfiniteScroll err', err);
            setAtEnd(true);
        } finally {
            setIsInfiniteScrolling(false);
        }
    };

    return (
        <div className="view-student__posts view-student__content">
            <TableFlaggedOnlyToggle
                onToggleChange={handleFlaggedFilterChange}
                value={studentPostsAndCommentsMetadata.flagged}
            />

            {isGettingStudentPostsAndComments || !initialized ? (
                <div className="view-student__activity-indicator">
                    <ActivityIndicator />
                </div>
            ) : (
                <>
                    {studentPostsAndComments.length === 0 ? (
                        <div className="school-feed__empty">
                            There's nothing here.
                        </div>
                    ) : (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleInfiniteScroll}
                            hasMore={!atEnd}
                        >
                            {Object.keys(studentPostsAndCommentsByMonth).map((month) => (
                                <div className="view-student__content__month" key={month}>
                                    <H6>
                                        {studentPostsAndCommentsByMonth[month].label}
                                    </H6>

                                    {studentPostsAndCommentsByMonth[month].threads?.map((thread) => (
                                        <React.Fragment key={thread.focusComment ? `comment_${thread.focusComment.postCommentId}` : `thread_${thread.postId}`}>
                                            <FeedFlaggedBanner item={thread} />
                                            <FeedPostAndComment thread={thread} />
                                        </React.Fragment>
                                    ))}
                                </div>
                            ))}

                            {isInfiniteScrolling && (
                                <div className="school-feed__infinite-scrolling-indicator">
                                    <ActivityIndicator color="white" />
                                </div>
                            )}

                            {atEnd && !isInfiniteScrolling && (
                                <div className="school-feed__at-end">
                                    End of feed
                                </div>
                            )}
                        </InfiniteScroll>
                    )}
                </>
            )}
        </div>
    );
};

export default ViewStudentPosts;
