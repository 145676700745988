import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import EventsMetadata from "../../types/EventsMetadata";
import EventPost from "../../types/EventPost";
import IError from "../../types/IError";
import Metadata from "../../types/Metadata";

import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import {generateEvent, generateEventsMetadata} from '../../utils/generators';

import { addError } from "./errors";
import { RootState } from '../reducers';

type DeleteEventProps = {
    event?: EventPost
}

export const deleteEvent = createAsyncThunk(
    'events/deleteEvents',
    async ({event}: DeleteEventProps = {}, {dispatch, getState}) => {
        const {tenantId} = (getState() as RootState).schools.activeSchool;
        try {
            if(!event) {
                event = (getState() as RootState).events.event;
            }

            const res = await new Request((getState() as RootState).auth.token).delete(PATHS.events.delete(tenantId, event.postId));
            return {
                ...res,
                postId: event.postId,
            };
        } catch(err) {
            console.log('delete event err', err);
            err.friendlyMessage = 'Error deleting the event. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

type GetEventsProps = {
    isUpdate?: boolean
    schoolId?: number
    eventsMetadata?: Metadata
}

export const getEvents = createAsyncThunk(
    'events/getEvents',
    async ({isUpdate, schoolId, eventsMetadata}: GetEventsProps, {dispatch, getState}) => {
        try {
            if(!eventsMetadata) {
                eventsMetadata = clone((getState() as RootState).events.eventsMetadata);
            } else {
                eventsMetadata = {...eventsMetadata}
            }
            eventsMetadata.type = 'E';

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.content.getContent(schoolId, buildQueryString(eventsMetadata)));
            let events = res.data.data.items;
            eventsMetadata.total = res.data.data.meta.total;
            return {events, eventsMetadata};
        } catch(err) {
            console.log('getEvents', err);
            err.friendlyMessage = 'Error getting the list of events. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetEventProps = {
    postId?: number | string
    schoolId?: number | string
}

export const getEvent = createAsyncThunk(
    'events/getEvent',
    async ({postId, schoolId}: GetEventProps = {}, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);

            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }

            const res = await new Request(token).get(PATHS.events.getById(schoolId, postId));
            return res.data.data;
        } catch(err) {
            console.log('getEvent', err);
            err.friendlyMessage = 'Error getting the event. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

export const saveEvent = createAsyncThunk(
    'events/saveEvent',
    async(_: void, {dispatch, getState}) => {
        const { auth: { token }, schools: { activeSchool: { tenantId , postAsProfile: { profileId } } } } = (getState() as RootState);
        let { event } = (getState() as RootState).events;

        let path = PATHS.events.create(tenantId);
        let request = new Request(token);
        let reqFunc = request.post;

        if(event.postId) {
            path = PATHS.events.update(tenantId, event.postId);
            reqFunc = request.put;
        } else {
            event = {
                ...event,
                tenantId,
                profileId
            }
        }

        try {
            const res = await reqFunc(path, event);
            return res;
        } catch(err) {
            console.log('saveEvent error', err);
            err.friendlyMessage = 'Error saving the event. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
)

interface EventsState {
    event: EventPost
    events: Array<EventPost>
    eventsMetadata: EventsMetadata
    isDeletingEvent: boolean
    isGettingEvent: boolean
    isGettingEvents: boolean
    isSavingEvent: boolean
    deleteEventError: IError
    getEventError: IError
    getEventsError: IError
    saveEventError: IError
    searchTerm: string
}

const initialState: EventsState = {
    event: generateEvent(),
    events: [],
    eventsMetadata: generateEventsMetadata(),
    searchTerm: '',
    isDeletingEvent: false,
    isGettingEvent: false,
    isGettingEvents: false,
    isSavingEvent: false,
    deleteEventError: undefined,
    getEventError: undefined,
    getEventsError: undefined,
    saveEventError: undefined
};

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        clearEvent: (state) => {
            state.event = generateEvent();
        },
        clearEventsMetadata: (state) => {
            state.eventsMetadata = generateEventsMetadata();
            state.searchTerm = '';
        },
        setEvent: (state, action) => {
            state.event = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteEvent.pending, (state) => {
            state.deleteEventError = undefined;
            state.isDeletingEvent = true;
        });
        addCase(deleteEvent.fulfilled, (state, action) => {
            state.isDeletingEvent = false;
            state.event = generateEvent();
        });
        addCase(deleteEvent.rejected, (state, action) => {
            state.deleteEventError = action.error as IError;
            state.isDeletingEvent = false;
        });

        addCase(getEvent.pending, (state) => {
            state.getEventError = undefined;
            state.isGettingEvent = true;
        });
        addCase(getEvent.fulfilled, (state, action) => {
            state.isGettingEvent = false;
            state.event = action.payload;
        });
        addCase(getEvent.rejected, (state, action) => {
            state.getEventError = action.error as IError;
            state.isGettingEvent = false;
        });

        addCase(getEvents.pending, (state, action) => {
            state.getEventsError = undefined;
            state.isGettingEvents = action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.eventsMetadata) {
                state.eventsMetadata = action.meta.arg.eventsMetadata;
            }
        });
        addCase(getEvents.fulfilled, (state, action) => {
            state.events = action.payload.events;
            state.eventsMetadata = action.payload.eventsMetadata;
            state.isGettingEvents = false;
        });
        addCase(getEvents.rejected, (state, action) => {
            state.getEventsError = action.error as IError;
            state.isGettingEvents = false;
        });

        addCase(saveEvent.pending, (state) => {
            state.saveEventError = undefined;
            state.isSavingEvent = true;
        });
        addCase(saveEvent.fulfilled, (state, action) => {
            state.isSavingEvent = false;
            state.event = generateEvent();
        });
        addCase(saveEvent.rejected, (state, action) => {
            state.saveEventError = action.error as IError;
            state.isSavingEvent = false;
        });
    }
});

export const { clearEvent, clearEventsMetadata, setEvent, setSearchTerm } = eventsSlice.actions;

export default eventsSlice.reducer;
