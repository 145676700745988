import './ViewStudent.scss';

import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router";

import Tab from "../../../../../types/Tab";

import { isArrayNullOrEmpty, isObjectNullOrEmpty, renderHometown } from "../../../../../utils/utils";

import {
    clearStudent,
    getStudentById,
    getStudentEvents, getStudentGroupChatMessages,
    getStudentGroups,
    getStudentPostsAndComments
} from "../../../../../store/slices/students";
import { useAppDispatch } from "../../../../../store";
import { useTypedSelector } from "../../../../../store/reducers";

import ActivityIndicator from "../../../../../components/ActivityIndicator";
import Avatar from "../../../../../components/Avatar";
import Button from "../../../../../components/Button";
import H4 from "../../../../../components/H4";
import Icon from "../../../../../components/Icon";
import ParagraphsFromNewlines from "../../../../../components/ParagraphsFromNewlines";
import TabNav from "../../../../../components/TabNav";
import ViewStudentPosts from "./ViewStudentPosts";
import ViewStudentGroups from "./ViewStudentGroups";
import ViewStudentChats from "./ViewStudentChats";
import ViewStudentEvents from "./ViewStudentEvents";
import FlaggedIcon from "../../../../../components/Icon/FlaggedIcon";
import StudentsTableMoreMenu from "../ListStudents/StudentsTableMoreMenu";

const ViewStudent: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const { params } = useRouteMatch();

    const { isGettingStudent, student } = useTypedSelector((state) => state.students);

    useEffect(() => {
        const initialize = async () => {
            if(params.profileId && params.schoolId) {
                const profileId = parseInt(params.profileId);

                if(!isGettingStudent) {
                    await dispatch(getStudentById({
                        hideSpinner: student.profileId === profileId,
                        profileId,
                        schoolId: params.schoolId,
                    }));
                }
            }
        }

        initialize();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.profileId, params.schoolId]);

    useEffect(() => {
        if(student.profileId) {
            dispatch(getStudentPostsAndComments({ profileId: student.profileId, schoolId: student.tenant.tenantId }));
            dispatch(getStudentGroupChatMessages({ profileId: student.profileId, schoolId: student.tenant.tenantId }));
            dispatch(getStudentGroups({ profileId: student.profileId, schoolId: student.tenant.tenantId }));
            dispatch(getStudentEvents({ profileId: student.profileId, schoolId: student.tenant.tenantId }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [student.profileId]);

    useEffect(() => {
        return () => {
            setTimeout(() => dispatch(clearStudent()), 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderMajorsAndMinors = () => {
        let displayString = '';

        if(!isArrayNullOrEmpty(student.major)) {
            displayString += 'Major: ';

            const majors = student.major.map((m) => m.displayText || m.description);
            displayString += majors.join(', ');

            if(!isArrayNullOrEmpty(student.minor)) {
                displayString += ' / ';
            }
        }

        if(!isArrayNullOrEmpty(student.minor)) {
            displayString += `Minor: ${student.minor[0].displayText || student.minor[0].description}`;
        }

        return displayString;
    }

    const TABS: Array<Tab> = [
        {
            isActive: location.pathname.includes('/posts'),
            onClick: () => history.push(`/school/${student.tenant.tenantId}/${params.profileType}/students/${student.profileId}/posts`),
            text: 'Discussions & Comments',
        }, {
            isActive: location.pathname.includes('/chats'),
            onClick: () => history.push(`/school/${student.tenant.tenantId}/${params.profileType}/students/${student.profileId}/chats`),
            text: 'Chats',
        }, {
            isActive: location.pathname.includes('/groups'),
            onClick: () => history.push(`/school/${student.tenant.tenantId}/${params.profileType}/students/${student.profileId}/groups`),
            text: 'Groups',
        }, {
            isActive: location.pathname.includes('/events'),
            onClick: () => history.push(`/school/${student.tenant.tenantId}/${params.profileType}/students/${student.profileId}/events`),
            text: 'Events',
        }
    ];

    if(isGettingStudent || isObjectNullOrEmpty(student) || student.profileId !== parseInt(params.profileId)) {
        return (
            <div className="view-student">
                <ActivityIndicator />
            </div>
        );
    }

    return (
        <div className={`view-student ${student.removedByMods ? 'a-view-student--removed-by-mods' : ''}`}>
            <Button
                className="view-student__back-button"
                noStyles
                onClick={() => {
                    history.push(`/school/${params?.schoolId}/${params.profileType}/students`);
                }}
            >
                <Icon type="arrow-left" />Students
            </Button>

            <header className="view-student__header">
                <div className="view-student__header-avatar-container">
                    <Avatar
                        artifact={student.artifact}
                        className="view-student__header-artifact"
                        flagged={student.flagged || student.flaggedContent}
                    />

                    {!student.removedByMods && (
                        <StudentsTableMoreMenu
                            item={student}
                        />
                    )}
                </div>

                <div className="view-student__header-meta">
                    <H4>
                        {student.firstName} {student.lastName}
                    </H4>

                    <div className="view-student__header-meta--small">
                        <div className="view-student__header-meta-item">
                            <a href={`mailto:${student.emailAddress}`}>
                                {student.emailAddress}
                            </a>
                        </div>

                        <div className="view-student__header-meta-item">
                            {isArrayNullOrEmpty(student.gradyear) || !student.gradyear[0] ? '' : `Class of ${student.gradyear[0].displayText || student.gradyear[0].description}`}
                        </div>

                        <div className="view-student__header-meta-item">
                            {renderHometown(student.location)}
                        </div>

                        <div className="view-student__header-meta-item">
                            {renderMajorsAndMinors()}
                        </div>
                    </div>
                </div>

                {student.about != null && (
                    <div className="view-student__header-description">
                        <ParagraphsFromNewlines
                            linkify
                            text={student.about}
                        />
                    </div>
                )}

                {(student.flagged || student.flaggedContent) && (
                    <div className="view-student__header-flags">
                        <FlaggedIcon />

                        {student.flagged && student.flaggedContent ? 'This student has been flagged and has flagged content.' :
                            student.flagged ? 'This student has been flagged' : 'This student has flagged content.'}
                    </div>
                )}

                {student.removedByMods && (
                    <div className="view-student__header-flags">
                        <FlaggedIcon />

                        This student has been removed by a moderator.
                    </div>
                )}
            </header>

            <TabNav
                className="view-student__tab-nav"
                tabs={TABS}
            />

            <Switch>
                <Route exact path="/school/:schoolId/:profileType/students/:profileId/posts" component={ViewStudentPosts} />
                <Route exact path="/school/:schoolId/:profileType/students/:profileId/chats" component={ViewStudentChats} />
                <Route exact path="/school/:schoolId/:profileType/students/:profileId/groups" component={ViewStudentGroups} />
                <Route exact path="/school/:schoolId/:profileType/students/:profileId/events" component={ViewStudentEvents} />
            </Switch>
        </div>
    );
};

export default ViewStudent;
