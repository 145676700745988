import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";
import { io, Socket } from 'socket.io-client';
import _uniqBy from "lodash/uniqBy";
import _sortBy from "lodash/sortBy";

import last from "lodash/last";

import Conversation from "../../types/Conversation";
import IError from "../../types/IError";
import Message from "../../types/Message";
import Metadata from "../../types/Metadata";
import Profile from "../../types/Profile";
import {buildErrorObject} from "../../utils/errors";
import environment from "../../utils/environment";
import Request from "../../utils/request";
import PATHS, { buildQueryString } from "../../utils/paths";
import { isArrayNullOrEmpty, isObjectNullOrEmpty } from "../../utils/utils";
import {ProfileTypes, WebsocketStatuses} from "../../utils/enums";

import { RootState } from '../reducers';
import { addError } from "./errors";

interface ServerToClientEvents {
    'connect_failed': () => void
    'error': (data: any) => void
    'dm/message': (data: MessageReceivedFromWebsocketPayloadProps) => void
}

interface ClientToServerEvents {
    'dm/subscribe': (data: {conversationId: number, v?: number}, callback: (result: {success: boolean}) => void) => void
    'dm/unsubscribe': (data: {conversationId: number, v?: number}, callback: (result: {success: boolean}) => void) => void
}

let socket: Socket<ServerToClientEvents, ClientToServerEvents>;
export { socket };

export const connectToWebsocket = createAsyncThunk(
    'directMessages/connectToWebsocket',
    async (_, {dispatch, getState, rejectWithValue}) => {
        try {
            const { token } = (getState() as RootState).auth;

            socket = await io(`${environment.websocketUrl}${PATHS.directMessages.connectToWebsocket()}`, {
                auth: {token},
                transports: ['websocket'],
            });

            await socket.connect();

            socket.on('error', (data) => {
                console.warn('Error in websocket request: ', data);
            });

            socket.on('connect_failed', () => {
                console.warn("Websocket connect_failed");
                dispatch(setWebsocketStatus('connect_failed'));
            })

            socket.on('connect_error', (err) => {
                console.warn("websocket connect_error", err);
                dispatch(setWebsocketStatus('connect_error'));
            })

            socket.on('connect', () => {
                dispatch(setWebsocketStatus('connected'));
            });

            socket.on('disconnect', (reason) => {
                console.warn('socket disconnected', reason);
                dispatch(setWebsocketStatus('disconnected'));
            });

            socket.on('dm/message', (data) => {
                dispatch(processNewMessage(data));
            });

            socket.io.on("close", () => {
                console.log('socket closed')
            });

            socket.io.on("reconnect", () => {
                dispatch(reconnectToWebsocket());
            });

            return {};
        } catch(err: any) {
            console.warn('connectToWebsocket err', err);
            let errorObject = buildErrorObject({
                serverError: err.response,
                friendlyMessage: 'We were unable to connect to this group chat. Please try again.',
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
);

export const reconnectToWebsocket = createAsyncThunk(
    'directMessages/reconnectToWebsocket',
    async (_, {dispatch, getState}) => {
        let subs = (getState() as RootState).directMessages.dmConversationSubscriptions;

        if (!isArrayNullOrEmpty(subs)){
            let topicId = subs[0];
            await dispatch(unsubscribeFromMessages({conversationId: topicId}));
            setTimeout(() => {
                dispatch(subscribeToMessages({conversationId: topicId}));
            }, 1000);
        }
    }
);

type SubscribeToMessagesProps = {
    conversationId: number
}

export const subscribeToMessages = createAsyncThunk(
    'directMessages/subscribeToMessages',
    async ({conversationId}: SubscribeToMessagesProps, {dispatch, getState, rejectWithValue}) => {
        try {
            if (!socket) {
                await dispatch(connectToWebsocket());
            } else if(!socket.connected) {
                await socket.connect();
            }

            // unsub from all lingering subscriptions
            let subs = clone((getState() as RootState).directMessages.dmConversationSubscriptions);
            if(!isArrayNullOrEmpty(subs)) {
                subs.forEach(async (sub) => {
                    await dispatch(unsubscribeFromMessages({conversationId: sub}));
                });

                subs = [];
            }

            socket.emit('dm/subscribe', { conversationId, v: 2 }, result => {
                subs.push(conversationId);
                dispatch(setDmConversationSubscriptions(subs));
            });
        } catch(err: any) {
            console.warn('subscribeToMessages err', err);
            let errorObject = buildErrorObject({
                serverError: err.response,
                friendlyMessage: 'Error subscribing to messages in this feed. Please refresh and try again.',
            });
            dispatch(addError(errorObject));
            return rejectWithValue(errorObject);
        }
    }
);

type UnsubscribeFromMessagesProps = {
    conversationId: number
}

export const unsubscribeFromMessages = createAsyncThunk(
    'directMessages/unsubscribeFromMessages',
    async ({conversationId}: UnsubscribeFromMessagesProps, {dispatch, getState}) => {
        await socket.emit('dm/unsubscribe', {conversationId}, (result: {success: boolean}) => {
            if(result.success === true) {
                let subs = (getState() as RootState).directMessages.dmConversationSubscriptions;
                subs = subs.filter((sub) => sub !== conversationId);
                dispatch(setDmConversationSubscriptions(subs));
            }
        });

        return {success: true};
    }
);

type MessageReceivedFromWebsocketPayloadProps = {
    type: string
    message: Message
    forumTopicMessageId?: number
}

export const processNewMessage = createAsyncThunk(
    'directMessages/processNewMessage',
    async (data: MessageReceivedFromWebsocketPayloadProps, {dispatch, getState}) => {
        switch (data.type) {
            case 'message':
                dispatch(messageReceivedFromWebsocket(data));
                break;
            case 'error':
                console.warn('websocket error (unhandled in UI)', data)
                break;
            case 'delete':
                dispatch(deleteLocalMessage(data.message.conversationMessageId));
                break;
            default:
                break;
        }
    }
);

type MessageReceivedFromWebsocketProps = {
    message: Message,
}

export const messageReceivedFromWebsocket = createAsyncThunk(
    'directMessages/messageReceivedFromWebsocket',
    async ({message}: MessageReceivedFromWebsocketProps, {dispatch, getState, rejectWithValue}) => {
        try {
            const {userId} = (getState() as RootState).auth.profile;
            const postAsProfileId = (getState() as RootState).schools.activeSchool.postAsProfile.profileId;

            message.isOwner = message.sender.profileId === postAsProfileId || message.sender.profileId === userId;

            let messages = [...(getState() as RootState).directMessages.conversation.messages];
            messages.unshift(message);
            dispatch(markAsRead({conversationMessageId: message.conversationMessageId}));
            return  messages;
        } catch (err: any) {
            console.warn('messageReceivedFromWebsocket action error', err);
            let errorObject = buildErrorObject({
                serverError: err.response,
                friendlyMessage: 'We were unable to mark these messages as read. Please try again.',
            });
            return rejectWithValue(errorObject);
        }
    }
);

type GetConversationProps = {
    conversationId: number | string
    schoolId?: number | string
}

export const getConversation = createAsyncThunk(
    'directMessages/getConversation',
    async ({conversationId, schoolId}: GetConversationProps, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);
            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }
            const res = await new Request(token).get(PATHS.directMessages.getConversation(schoolId, conversationId));
            let conversation = res.data.data;
            conversation.messages = conversation.messages.reverse();
            return conversation;
        } catch(err) {
            console.warn('getConversation err', err);
            err.friendlyMessage = 'Error getting this conversation. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetConversationsProps = {
    conversationsMetadata?: Metadata
    hideSpinner?: boolean
    isUpdate?: boolean
    profileType: ProfileTypes
    schoolId?: number
}

export const getConversations = createAsyncThunk(
    'directMessages/getConversations',
    async ({hideSpinner, isUpdate, profileType, schoolId, conversationsMetadata}: GetConversationsProps, {dispatch, getState}) => {
        try {
            if(!conversationsMetadata) {
                conversationsMetadata = clone((getState() as RootState).directMessages.conversationsMetadata);
            } else {
                conversationsMetadata = {...conversationsMetadata}
            }

            if(!schoolId) {
                schoolId = (getState() as RootState).schools.activeSchool.tenantId;
            }

            if(isUpdate) {
                conversationsMetadata.page_num = conversationsMetadata.page_num + 1;
            } else {
                conversationsMetadata.page_num = 0;
            }

            conversationsMetadata.visibility_type = profileType;

            const res = await new Request((getState() as RootState).auth.token).get(PATHS.directMessages.getConversations(schoolId, buildQueryString(conversationsMetadata)));

            conversationsMetadata.atEnd = res.data.data.items.length === 0;

            let conversations = res.data.data.items;
            if(isUpdate) {
                conversations = (getState() as RootState).directMessages.conversations.concat(conversations);
            }

            return {conversations, conversationsMetadata, isAtEnd: res.data.data.items.length === 0};
        } catch(err) {
            console.warn('getConversations', err);
            err.friendlyMessage = 'Error getting the list of conversations. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type MarkAsReadProps = {
    conversationId?: number | string
    schoolId?: number | string
    conversationMessageId?: number | string
}

export const markAsRead = createAsyncThunk(
    'directMessages/markAsRead',
    async ({conversationId, schoolId, conversationMessageId}: MarkAsReadProps, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);
            const { conversation } = (getState() as RootState).directMessages;

            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }

            if(!conversationId) {
                conversationId = conversation.conversationId;
            }

            if(!conversationMessageId) {
                conversationMessageId = conversation.messages[0].conversationMessageId;
            }

            await new Request(token).put(PATHS.directMessages.markAsRead(schoolId, conversationId, conversationMessageId));
            return {conversationId: parseInt(conversationId as string)};
        } catch(err) {
            console.warn('markAsRead err', err);
            throw err;
        }
    }
);

type SendMessageProps = {
    schoolId?: number | string
}

export const sendMessage = createAsyncThunk(
    'directMessages/sendMessage',
    async ({schoolId}: SendMessageProps, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);
            const { conversation, messageImage, newMessage, newRecipient } = (getState() as RootState).directMessages;

            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }

            let recipient;
            if(!isObjectNullOrEmpty(newRecipient)) {
                recipient = newRecipient;
            } else {
                recipient = conversation.recipients.find((r) => r.profileId !== activeSchool.postAsProfile.profileId);
            }


            if(isObjectNullOrEmpty(activeSchool.postAsProfile) || isObjectNullOrEmpty(recipient)) {
                throw new Error('no valid profile at this school');
            }

            const message = {
                recipientId: recipient.profileId,
                artifactId: messageImage,
                parts: [
                    {
                        body: newMessage,
                        mimeType: 'application/json'
                    }
                ]
            };
            const res = await new Request(token).post(PATHS.directMessages.sendMessage(schoolId), message);
            let responseMessage = last(res.data.data.messages);
            responseMessage.isTemp = true;
            if(isObjectNullOrEmpty(conversation)) {
                const conversationId = res.data?.data?.conversationId;
                if (conversationId) {
                    // subscribe to the newly created conversation
                    dispatch(subscribeToMessages({conversationId: conversationId}));
                }
                return {conversation: res.data.data, message: null};
            } else {
                return {conversation: null, message: responseMessage};
            }
        } catch(err) {
            console.warn('sendMessage err', err);
            err.friendlyMessage = 'Error sending your message. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type DeleteMessageProps = {
    messageId: number | string
}

export const deleteMessage = createAsyncThunk(
    'directMessages/deleteMessage',
    async ({messageId}: DeleteMessageProps, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);
            const { conversation } = (getState() as RootState).directMessages;

            const schoolId = activeSchool.tenantId;

            const res = await new Request(token).delete(PATHS.directMessages.deleteMessage(schoolId, conversation.conversationId, messageId));
            return res;
        } catch(err) {
            console.warn('deleteMessage err', err);
            err.friendlyMessage = 'Error deleting your message. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type UpdateMessagesProps = {
    beforeOrAfter: 'before' | 'after'
    hideSpinner?: boolean
    schoolId?: number | string
}

export const updateMessages = createAsyncThunk(
    'directMessages/updateMessages',
    async ({beforeOrAfter, hideSpinner, schoolId}: UpdateMessagesProps, {dispatch, getState}) => {
        try {
            const { auth: { token }, schools: { activeSchool } } = (getState() as RootState);
            const { conversation } = (getState() as RootState).directMessages;

            if(!schoolId) {
                schoolId = activeSchool.tenantId;
            }

            let messageIndex = beforeOrAfter === 'before' ?conversation.messages.length - 1 : 0;
            if(beforeOrAfter === 'after') {
                //hacktastic way to start earlier in the text chain.
                //will replace with betweenId when the backend has it done
                if(conversation?.messages && conversation.messages[3]) {
                    messageIndex = 3;
                }
                while(conversation?.messages && conversation.messages[messageIndex + 1] && conversation.messages[messageIndex].isTemp === true) {
                    messageIndex++;
                }
            }

            const messageId = conversation.messages && conversation.messages[messageIndex].conversationMessageId;
            if(!messageId) {
                return null;
            }
            const res = await new Request(token).get(PATHS.directMessages.updateConversation(schoolId, conversation.conversationId, `${beforeOrAfter}Id`, messageId));
            let messages = res.data.data.messages.reverse();
            if(beforeOrAfter === 'before') {
                messages = conversation.messages.concat(messages);
            } else {
                messages = messages.concat(conversation.messages);
            }
            messages = _uniqBy(messages, 'conversationMessageId');
            messages = _sortBy(messages, 'conversationMessageId').reverse();
            return {messages, atEnd: res.data.data.messages.length === 0};
        } catch(err) {
            console.warn('updateMessages err', err);
            err.friendlyMessage = 'Error getting new messages. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

interface DirectMessagesState {
    conversation: Conversation
    conversations: Array<Conversation>
    conversationsMetadata: Metadata
    dmConversationSubscriptions: Array<number>
    isDeletingMessage: boolean
    deleteMessageError?: IError
    getConversationError?: IError
    getConversationsError?: IError
    isGettingConversation: boolean
    isGettingConversations: boolean
    isMarkingAsRead: boolean
    isSendingMessage: boolean
    isUpdatingMessages: boolean
    markAsReadError: IError
    newMessage?: string
    newRecipient?: Profile
    sendMessageError?: IError
    updateMessagesError: IError
    messageImage?: number
    searchTerm?: string
    websocketStatus: WebsocketStatuses
}

const initialState: DirectMessagesState = {
    conversation: {},
    conversations: [],
    conversationsMetadata: {
        atEnd: false,
        page_num: 0,
        page_size: 20,
        order: 'desc',
        search: '',
        sort: 'updatedAt',
    },
    isDeletingMessage: false,
    deleteMessageError: undefined,
    dmConversationSubscriptions: [],
    newMessage: '',
    newRecipient: undefined,
    isGettingConversation: false,
    isGettingConversations: false,
    isMarkingAsRead: false,
    isSendingMessage: false,
    isUpdatingMessages: false,
    getConversationError: undefined,
    getConversationsError: undefined,
    markAsReadError: undefined,
    sendMessageError: undefined,
    updateMessagesError: undefined,
    messageImage: null,
    searchTerm: undefined,
    websocketStatus: WebsocketStatuses.NotInitialized,
};

export const directMessagesSlice = createSlice({
    name: 'directMessages',
    initialState,
    reducers: {
        clearConversation: (state) => {
            state.conversation = {};
        },
        clearConversations: (state) => {
            state.conversations = [];
        },
        clearNewMessage: (state) => {
            state.newMessage = '';
        },
        clearNewRecipient: (state) => {
            state.newRecipient = undefined;
        },
        deleteLocalMessage: (state, action) => {
            state.conversation.messages = state.conversation.messages.filter((message) => {
                return message.conversationMessageId !== action.payload;
            });
        },
        setConversation: (state, action) => {
            state.conversation = action.payload;
        },
        setDmConversationSubscriptions: (state, action) => {
            state.dmConversationSubscriptions = action.payload;
        },
        setNewMessage: (state, action) => {
            state.newMessage = action.payload;
        },
        setNewMessageArtifact: (state, action) => {
            state.messageImage = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setNewRecipient: (state, action) => {
            state.newRecipient = action.payload;
        },
        setWebsocketStatus: (state, action) => {
            state.websocketStatus = action.payload;
        },
        resetConversationPagination: (state) => {
            state.conversationsMetadata = {
                ...state.conversationsMetadata,
                page_num: 0,
                atEnd: false,
            };
        },
    },
    extraReducers: ({addCase}) => {
        addCase(deleteMessage.pending, (state, action) => {
            state.deleteMessageError = undefined;
            state.isDeletingMessage = true;
        });
        addCase(deleteMessage.fulfilled, (state, action) => {
            state.isDeletingMessage = false;
        });
        addCase(deleteMessage.rejected, (state, action) => {
            state.deleteMessageError = action.error;
            state.isDeletingMessage = false;
        });

        addCase(getConversation.pending, (state, action) => {
            state.getConversationError = undefined;
            state.isGettingConversation = true;
        });
        addCase(getConversation.fulfilled, (state, action) => {
            state.conversation = action.payload;
            state.isGettingConversation = false;
        });
        addCase(getConversation.rejected, (state, action) => {
            state.getConversationError = action.error;
            state.isGettingConversation = false;
        });

        addCase(getConversations.pending, (state, action) => {
            state.getConversationsError = undefined;
            state.isGettingConversations = action.meta?.arg?.hideSpinner !== true && action.meta?.arg?.isUpdate !== true;
            if(action.meta?.arg?.conversationsMetadata) {
                state.conversationsMetadata = action.meta.arg.conversationsMetadata;
            }
        });
        addCase(getConversations.fulfilled, (state, action) => {
            state.conversations = action.payload.conversations;
            state.conversationsMetadata = action.payload.conversationsMetadata;
            state.isGettingConversations = false;
        });
        addCase(getConversations.rejected, (state, action) => {
            state.getConversationsError = action.error;
            state.isGettingConversations = false;
        });

        addCase(markAsRead.pending, (state, action) => {
            state.markAsReadError = undefined;
            state.isMarkingAsRead = true;
        });
        addCase(markAsRead.fulfilled, (state, action) => {
            let clonedConversation = clone(state.conversation);

            if(isObjectNullOrEmpty(clonedConversation)) {
                return;
            }

            clonedConversation.unreadCount = 0;
            clonedConversation.messages.forEach((m) => m.viewed = 'Y');

            let clonedConversations = clone(state.conversations);
            clonedConversations.forEach((c) => {
                if (c.conversationId === action?.payload?.conversationId) {
                    c.unreadCount = 0;
                    c.messages.forEach((m) => m.viewed = 'Y');
                }
            });

            state.conversation = clonedConversation;
            state.conversations = clonedConversations;
            state.isMarkingAsRead = false;
        });
        addCase(markAsRead.rejected, (state, action) => {
            state.markAsReadError = action.error;
            state.isMarkingAsRead = false;
        });

        addCase(messageReceivedFromWebsocket.fulfilled, (state, action) => {
            state.conversation.messages = action.payload;
        });

        addCase(sendMessage.pending, (state, action) => {
            state.sendMessageError = undefined;
            state.isSendingMessage = true;
        });
        addCase(sendMessage.fulfilled, (state, action) => {
            // If there is no conversation, we need to set it based on the server response
            if(isObjectNullOrEmpty(state.conversation) && !isObjectNullOrEmpty(action.payload.conversation)) {
                state.conversation = action.payload.conversation;
            }
            state.newMessage = '';
            state.isSendingMessage = false;
        });
        addCase(sendMessage.rejected, (state, action) => {
            state.sendMessageError = action.error;
            state.isSendingMessage = false;
        });

        addCase(updateMessages.pending, (state, action) => {
            state.updateMessagesError = undefined;
            state.isUpdatingMessages = action.meta?.arg?.hideSpinner !== true;
        });
        addCase(updateMessages.fulfilled, (state, action) => {
            state.conversation = {
                ...state.conversation,
                messages: action.payload.messages,
            };
            state.isUpdatingMessages = false;
        });
        addCase(updateMessages.rejected, (state, action) => {
            state.updateMessagesError = action.error;
            state.isUpdatingMessages = false;
        });
    }
});

export const {
    clearConversation,
    clearConversations,
    clearNewMessage,
    clearNewRecipient,
    deleteLocalMessage,
    setConversation,
    setDmConversationSubscriptions,
    setNewMessage,
    setNewMessageArtifact,
    setNewRecipient,
    setSearchTerm,
    setWebsocketStatus,
    resetConversationPagination,
} = directMessagesSlice.actions;

export default directMessagesSlice.reducer;
