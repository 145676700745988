import './UpdateGivingStatus.scss';

import React, { useEffect, useState } from 'react';

import { useAppDispatch } from '../../../../../store';
import { useTypedSelector } from '../../../../../store/reducers';
import {
    clearGivingStatusConfig,
    resendStripeAccountSetupEmail,
} from '../../../../../store/slices/schools';

import { StripeAccountStatus } from '../../../../../types/StripeAccountStatus';
import Tenant from '../../../../../types/Tenant';

import Form from '../../../../../components/Form';
import FormColumn from '../../../../../components/FormColumn';
import FormRow from '../../../../../components/FormRow';
import GivingStatusPendingApprovalForm from './GivingStatusPendingApprovalForm';
import Label from '../../../../../components/Label';
import NewContentModal from '../../../../../components/NewContentModal';
import SuccessModal from '../../../../../components/Modal/SuccessModal';
import UpdateGivingStatusForm from './UpdateGivingStatusForm';

type UpdateGivingStatusProps = {
    school: Tenant,
    showForm: boolean,
    setShowForm: (showForm: boolean) => void,
}

const UpdateGivingStatus: React.FC<UpdateGivingStatusProps> = ({
   school,
   showForm,
   setShowForm
}) => {
    const dispatch = useAppDispatch();

    const {
        updateGivingStatusConfig,
        isResendingStripeAccountSetupEmail,
    } = useTypedSelector(state => state.schools);

    const [showPendingApproval, setShowPendingApproval] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

    useEffect(() => {
        if (!school.tenantId) {
            console.error('No tenantId found');
            clearGivingStatusConfig();
            setShowForm(false);
        }

        if (
            school.givingActive &&
            school.billingContactEmailAddress &&
            school.stripeAccountStatus !== StripeAccountStatus.Complete
        ) {
            clearGivingStatusConfig();
            setShowPendingApproval(true);
        }
    }, [school]);

    const handleResendStripeAccountSetupEmail = () => {
        const resend = async () => {
            try {
                await dispatch(resendStripeAccountSetupEmail());
                setShowSuccessModal(true);
            } catch (err) {
                console.error(err);
            }
        };

        resend();
    };

    return (
        <NewContentModal
            show={showForm}
            close={setShowForm}
            contentLabel={'Update Giving Status'}
        >
            <Form>
                <FormColumn className="update-giving-status">

                    {school.tenantId && (
                        <>
                            <FormRow>
                                <Label>
                                    Stripe Account Status: {school.stripeAccountStatus}
                                </Label>
                            </FormRow>
                        </>
                    )}

                    {showForm && school.tenantId && (
                        <UpdateGivingStatusForm
                            config={updateGivingStatusConfig}
                            dispatch={dispatch}
                        />
                    )}

                    {showPendingApproval && school.tenantId && (
                        <GivingStatusPendingApprovalForm
                            activeSchool={school}
                            isResendingStripeAccountSetupEmail={isResendingStripeAccountSetupEmail}
                            handleResendStripeAccountSetupEmail={handleResendStripeAccountSetupEmail}
                        />
                    )}
                </FormColumn>

                <SuccessModal buttonOnClick={() => setShowSuccessModal(false)} show={showSuccessModal}>
                    <p>
                        We've received your request.
                    </p>
                    <p>
                        Please inform the billing contact for {school.name} that we sent an email address
                        to {school.billingContactEmailAddress} for them to update their Stripe Account.
                    </p>
                </SuccessModal>
            </Form>
        </NewContentModal>
    );
};

export default UpdateGivingStatus;

