import './App.scss';

import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { isMobile } from 'react-device-detect';

import { isAuthPage } from '../../utils/utils';
import { LOCALSTORAGE } from '../../utils/constants';
import { determinePathAfterLogin, setLoginRedirectURL } from '../../utils/routing';

import { RootState } from '../../store/reducers';
import { getLoggedInUser, setToken } from "../../store/slices/auth";

import ActivityIndicator from '../../components/ActivityIndicator';
import ActivityReportGenerator from "../Schools/ActivityReportGenerator";
import Auth from '../Auth';
import Engineering from '../Engineering';
import Errors from "../Errors"
import MainContent from '../../components/MainContent';
import MainHeader from '../../components/MainHeader';
import ScrollToTop from './ScrollToTop';
import School from "../Schools/School";
import Schools from "../Schools";
import Giving from '../Giving';

const App = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [hasFetchedProfile, setHasFetchedProfile] = useState(false);

    const { isGettingLoggedInUser } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        const initialize = async () => {
            const authToken = localStorage.getItem(LOCALSTORAGE.ID_TOKEN);
            const { pathname } = window.location;

            if(isMobile) {
                document.body.classList.add('is-mobile');
            }

            setTimeout(() => document.body.classList.add('loaded'), 200);

            if (authToken == null) {
                if(!isAuthPage(pathname)) {
                    setLoginRedirectURL();
                    history.push('/auth/login');
                }
                setHasFetchedProfile(true);
            } else {
                try {
                    let res: any = await dispatch(getLoggedInUser({token: authToken}));
                    dispatch(setToken(authToken));

                    const { roles } = res.payload;

                    if(isAuthPage(window.location.pathname) || window.location.pathname === '/') {
                        let redirectRoute = determinePathAfterLogin(roles);
                        history.push(redirectRoute);
                    }

                    setHasFetchedProfile(true);
                } catch {
                    history.push('/auth/logout');
                    setHasFetchedProfile(true);
                }
            }
        };

        initialize();
    }, [dispatch, history]);

    return (
        <div className="wrapper">
            <ScrollToTop/>

            <Helmet
                defaultTitle="Abuzz"
                titleTemplate="%s | Abuzz Admin"
            />

            {isGettingLoggedInUser || !hasFetchedProfile ? (
                <MainContent>
                    <div className="loading-activity-indicator">
                        <ActivityIndicator size="large" />
                    </div>
                </MainContent>
            ) : (
                <>
                    <Switch>
                        <Route path="/giving" component={MainHeader} />
                        <Route path="/engineering" component={MainHeader} />
                        <Route path="/report-generator" component={MainHeader} />
                        <Route path="/schools" component={MainHeader} />
                        <Route path="/school/:schoolId" component={MainHeader} />
                        <Route path="/super-admin" component={MainHeader} />
                    </Switch>

                    <MainContent>
                        <Switch>
                            <Route path="/auth" component={Auth} />
                            <Route path="/engineering" component={Engineering} />
                            <Route path="/giving" component={Giving} />
                            <Route path="/report-generator" component={ActivityReportGenerator} />
                            <Route path="/schools" component={Schools} />
                            <Route path="/school/:schoolId" component={School} />
                        </Switch>
                    </MainContent>
                </>
            )}
            <Errors />
        </div>
    );
};

export default App;
