import './Auth.scss';

import React, { useEffect } from 'react';
import { Route, Switch } from "react-router";

import AcceptInvitation from "./AcceptInvitation";
import Card from '../../components/Card';
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Logo from '../../components/Logo';
import Logout from "./Logout";
import ResetPassword from "./ResetPassword";
import SessionExpired from "./SessionExpired";

const Auth = () => {
    useEffect(() => {
        document.body.classList.add('auth-pages');

        return () => document.body.classList.remove('auth-pages');
    }, []);

    return (
        <div className="auth-page">
            <Card className="auth-page--inner">
                <div className="auth-page__logo-wrapper">
                    <Logo />
                </div>

                <Switch>
                    <Route path="/auth/login" component={Login} />
                    <Route path="/auth/logout" component={Logout} />
                    <Route path="/auth/forgot-password" component={ForgotPassword} />
                    <Route path="/auth/reset-password" component={ResetPassword} />
                    <Route path="/auth/invitation" component={AcceptInvitation} />
                    <Route path="/auth/expired" component={SessionExpired} />
                </Switch>
            </Card>
        </div>
    );
};

export default Auth;
