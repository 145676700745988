import React from 'react';

import Tenant from '../../../../../types/Tenant';

import Button, { ButtonThemes } from '../../../../../components/Button';
import ButtonRow from '../../../../../components/ButtonRow';
import FormRow from '../../../../../components/FormRow';
import EmailButton from '../../../../../components/EmailButton';


type GivingStatusPendingApprovalFormProps = {
    activeSchool: Tenant
    isResendingStripeAccountSetupEmail: boolean
    handleResendStripeAccountSetupEmail: () => void
}

const GivingStatusPendingApprovalForm: React.FC<GivingStatusPendingApprovalFormProps> = ({
    activeSchool,
    isResendingStripeAccountSetupEmail,
    handleResendStripeAccountSetupEmail,
}) => {
    return (
        <>
            <FormRow>
                Our records indicate that {activeSchool.billingContactEmailAddress} has requested to enable
                giving for {activeSchool.name}. Before the giving feature can be enabled, the contact
                needs to complete their Stripe account setup.
            </FormRow>

            <FormRow>
                If you're having issues with the setup, please contact your Abuzz support member, or send an
                email to {''}
                <EmailButton
                    email={'support@campusabuzz.com'}
                    subject={'I Require Assistance With My Stripe Account Setup'}
                />
                {''} to receive assistance in completing your account setup.
            </FormRow>

            <ButtonRow>
                <Button
                    disabled={isResendingStripeAccountSetupEmail}
                    onClick={handleResendStripeAccountSetupEmail}
                    theme={ButtonThemes.Primary}
                >
                    Resend Stripe Account Setup Email
                </Button>
            </ButtonRow>
        </>
    );
};

export default GivingStatusPendingApprovalForm;
