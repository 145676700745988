import Capability from "../types/Capability";
import Role from "../types/Role";

import store from '../store';
import { isArrayNullOrEmpty, isObjectNullOrEmpty } from './utils';

export const ROLES = {
    ENGINEERING: 'engineering',
    SCHOOL_ADMIN: 'school_admin',
    SUPER_ADMIN: 'super_admin',
    TENANT_ADMIN: 'tenant_admin',
    TENANT_TEAM: 'tenant_team',
    CONTENT_CREATOR: 'content_creator',
};

export const ROLE_STRINGS = {
    school_admin: 'School Administration',
    super_admin: 'Super Admin',
    tenant_admin: 'Tenant Admin',
    tenant_team: 'Tenant Team',
    content_creator: 'Content Creator',
};

// Which roles can do what are all managed here:
export const CAPABILITIES = {
    DM: {
        allowedRoles: [
            ROLES.SCHOOL_ADMIN,
            ROLES.SUPER_ADMIN
        ]
    },
    EDIT_CONTENT: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
            ROLES.CONTENT_CREATOR,
            ROLES.TENANT_TEAM,
            ROLES.SCHOOL_ADMIN,
        ]
    },
    MANAGE_AMBASSADORS: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN
        ]
    },
    MANAGE_GROUPS: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
            ROLES.SCHOOL_ADMIN,
            ROLES.CONTENT_CREATOR,
            ROLES.TENANT_TEAM,
        ]
    },
    MANAGE_PUSH_NOTIFICATIONS: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
            ROLES.SCHOOL_ADMIN,
        ]
    },
    MANAGE_SPONSORS: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
        ]
    },
    CREATE_SPONSORED_POSTS: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
        ]
    },
    MANAGE_PARTNERS: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN
        ]
    },
    MANAGE_STUDENTS: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
            ROLES.SCHOOL_ADMIN
        ]
    },
    MANAGE_LANDING_PAGES: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
            ROLES.SCHOOL_ADMIN
        ],
    },
    VIEW_SCHOOL_FEED: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
            ROLES.SCHOOL_ADMIN
        ]
    },
    VIEW_CONTENT_TABLE: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
            ROLES.CONTENT_CREATOR,
            ROLES.TENANT_TEAM,
            ROLES.SCHOOL_ADMIN
        ]
    },
    VIEW_ACTIVITY_REPORT_GENERATOR: {
        allowedRoles: [
            ROLES.ENGINEERING,
            ROLES.SUPER_ADMIN,
        ]
    },
    VIEW_TENANT_REPORT_GENERATOR: {
        allowedRoles: [
            ROLES.ENGINEERING,
            ROLES.SUPER_ADMIN,
            ROLES.SCHOOL_ADMIN
        ]
    },
    VIEW_ENGINEERING: {
        allowedRoles: [
            ROLES.ENGINEERING
        ]
    },
    VIEW_GIVING: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.ENGINEERING,
            ROLES.TENANT_ADMIN,
        ]
    },
    VIEW_SUPER_ADMIN: {
        allowedRoles: [
            ROLES.SUPER_ADMIN
        ]
    },
    VIEW_SCHOOL_DASHBOARD: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
        ]
    },
    MANAGE_FLAGGED_CONTENT: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
        ]
    },
    MANAGE_PEOPLE: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
            ROLES.SCHOOL_ADMIN
        ]
    },
    MANAGE_INTEGRATIONS: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
            ROLES.SCHOOL_ADMIN
        ]
    },
    MODERATE_CONTENT: {
        allowedRoles: [
            ROLES.SUPER_ADMIN,
            ROLES.TENANT_ADMIN,
            ROLES.SCHOOL_ADMIN,
        ]
    },
    MULTIPLE_TENANTS: {
        allowedRoles: []
    }
};

export const multipleCapabilitiesUserCan = (capabilities?: Array<Capability>) => {
    if(isArrayNullOrEmpty(capabilities)) {
        return false;
    }

    let multipleCapabilitiesPermission = false;

    capabilities.forEach((c) => {
        let singleCapability = userCan(c);
        if(singleCapability) {
            multipleCapabilitiesPermission = true;
        }
    });

    return multipleCapabilitiesPermission;
}

export const userCan = (capability: Capability, role?: Role, schoolIdFromRoute?: number) => {
    const state = store.getState();
    const { roles } = state.auth;

    // If the user is a engineer, the answer is automatically yes
    if (hasRole(roles, ROLES.ENGINEERING)) {
        return true;
    }

    if(hasRole(roles, ROLES.SUPER_ADMIN)) {
        if(capability?.allowedRoles.length === 1 && capability?.allowedRoles[0] === ROLES.ENGINEERING) {
            // We don't want super admins in pages meant for JUST engineering role
            return false;
        } else {
            // if it has any permission besides engineering, or in addition to engineering,
            // it's an automatic yes for super admins
            return true;
        }
    }

    if(capability === CAPABILITIES.MULTIPLE_TENANTS) {
        return roles.length > 1;
    }

    if (isObjectNullOrEmpty(state.schools.activeSchool) && !schoolIdFromRoute) {
        return false;
    }

    let tenantId = schoolIdFromRoute || state.schools.activeSchool.tenantId;

    if(!tenantId) {
        return false;
    }

    if (!role) {
        // Get the role for the tenant
        role = roles.find((role) => {
            return role.tenantId === tenantId;
        });
    }

    if(!role || !capability) {
        return false;
    }

    return capability.allowedRoles.includes(role.type);
};

export const hasRole = (roles: Array<Role>, role: string) => {
    return roles.find(r => r.type === role) != null;
};

export const userCanAccessSchool = (schoolId) => {
    const state = store.getState();
    const { roles } = state.auth;
    const isSuperAdmin = roles.some(role => role.type === ROLES.SUPER_ADMIN);
    const hasMatchingTenant = roles.some(role => role.tenantId === parseInt(schoolId, 10));
    return isSuperAdmin || hasMatchingTenant;
}

export const getCurrentUserRole = ({tenantId}) => {
    const state = store.getState();
    const { roles } = state.auth;

    const tenantRole = roles.find(r => r.tenantId === parseInt(tenantId, 10));

    if (!tenantRole) {
        const role = roles.find(r => r.type === ROLES.SUPER_ADMIN || r.type === ROLES.ENGINEERING);
        return role.type;
    }

    return tenantRole.type;
}

