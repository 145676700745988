import React, { useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from "react-router";

import Profile from "../../../../../../types/Profile";

import { isArrayNullOrEmpty } from "../../../../../../utils/utils";

import {
    clearAssignStudentTagsError,
    clearDeleteStudentError,
    deleteStudent,
    setStudent
} from "../../../../../../store/slices/students";
import { initiateProfilePromotion } from '../../../../../../store/slices/profilePromotion';
import { setNewRecipient } from "../../../../../../store/slices/directMessages";
import { useAppDispatch } from "../../../../../../store";
import { useTypedSelector } from "../../../../../../store/reducers";

import ErrorInline from "../../../../../Errors/ErrorInline";
import ManageStudentTags from "../../ManageTags";
import Modal from "../../../../../../components/Modal";
import SuccessModal from "../../../../../../components/Modal/SuccessModal";
import TableMoreMenu from "../../../../../../components/Table/TableMoreMenu";
import { ProfileTypes } from "../../../../../../utils/enums";

type Props = {
    getAfterDelete?: () => Promise<any>
    item: Profile
}

const StudentsTableMoreMenu: React.FC<Props> = ({
    getAfterDelete,
    item,
}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { params } = useRouteMatch();

    const [showConfirmPromoteModal, setShowConfirmPromoteModal] = useState(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showTagsModal, setShowTagsModal] = useState(false);
    const [showTagsSuccessModal, setShowTagsSuccessModal] = useState(false);

    const { postAsProfile } = useTypedSelector((state) => state.schools.activeSchool);
    const { deleteStudentError, isDeletingStudent } = useTypedSelector((state) => state.students);
    const { isInitiatingProfilePromotion, initiateProfilePromotionError } = useTypedSelector((state) => state.profilePromotion);
    const handleCloseManageTagsModal = () => {
        setShowTagsModal(false);
        dispatch(clearAssignStudentTagsError());
    }

    const handleRemoveStudent = async () => {
        try {
            await dispatch(deleteStudent({profileId: item.profileId})).unwrap();
            setShowConfirmDeleteModal(false);
            getAfterDelete && getAfterDelete();
        } catch(err) {
            console.log('StudentsTableMoreMenu handleRemoveStudent err', err);
        }
    };

    const handlePromoteStudent = async () => {
        try {
            await dispatch(initiateProfilePromotion({profileIds: [item.profileId]})).unwrap();
            setShowConfirmPromoteModal(false);
        }
        catch(err) {
            console.log('StudentsTableMoreMenu handlePromoteStudent err', err);
        }
    }

    const customActions = useMemo(
        () => {
            let actions = [];

            const itemIsMe = postAsProfile?.profileId === item.profileId;

            if(postAsProfile && !itemIsMe) {
                if(!params.profileId && item.profileType === ProfileTypes.Student) {
                    actions.push({
                        icon: 'user',
                        onClick: () => {
                            dispatch(setStudent(item));
                            //@ts-ignore
                            history.push(`/school/${item.tenant.tenantId}/${params.profileType}/students/${item.profileId}/posts`)
                        },
                        text: 'View Profile'
                    });
                }

                actions.push({
                    icon: 'dms',
                    onClick: () => {
                        if(item.conversationId) {
                            history.push(`/school/${params.schoolId}/student/messages/conversation/${item.conversationId}`);
                        } else {
                            dispatch(setNewRecipient(item));
                            history.push(`/school/${params.schoolId}/student/messages/conversation/new`);
                        }
                    },
                    text: 'Message',
                });

                actions.push({
                    icon: 'user-remove',
                    onClick: async () => {
                        setShowConfirmDeleteModal(true);
                    },
                    text: 'Remove',
                });
            }

            actions.push({
                icon: 'tag',
                onClick: async () => {
                    setShowTagsModal(true);
                },
                text: 'Manage Tags',
            });

            actions.push({
                icon: 'alert',
                onClick:  () => {
                    setShowConfirmPromoteModal(true);
                },
                text: 'Send Alumni Promotion Invite',
            })

            return actions;

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    if(isArrayNullOrEmpty(customActions)) {
        return null;
    }

    return (
        <>
            <TableMoreMenu
                customOptions={customActions}
                getAfterModerate={getAfterDelete}
                item={item}
                type="profile"
            />

            <Modal
                declineButtonOnClick={handleCloseManageTagsModal}
                show={showTagsModal}
                title="Manage Student Tags"
            >
                <ManageStudentTags
                    closeModal={handleCloseManageTagsModal}
                    user={item}
                    onSuccess={() => {
                        setShowTagsModal(false);
                        setShowTagsSuccessModal(true);
                    }}
                />
            </Modal>

            <Modal
                declineButtonOnClick={handleCloseManageTagsModal}
                show={showTagsModal}
                title="Manage Student Tags"
            >
                <ManageStudentTags
                    closeModal={handleCloseManageTagsModal}
                    user={item}
                    onSuccess={() => {
                        setShowTagsModal(false);
                        setShowTagsSuccessModal(true);
                    }}
                />
            </Modal>

            <SuccessModal
                buttonOnClick={() => {
                    setShowTagsSuccessModal(false);
                }}
                buttonText="Done"
                show={showTagsSuccessModal}
                title="Nice job!"
            >
                <p>
                    Tags have been modified.
                </p>
            </SuccessModal>

            <Modal
                confirmButtonOnClick={handleRemoveStudent}
                confirmButtonText="Remove"
                declineButtonOnClick={() => {
                    setShowConfirmDeleteModal(false);
                    dispatch(clearDeleteStudentError());
                }}
                declineButtonText="Cancel"
                show={showConfirmDeleteModal}
                showActivityIndicator={isDeletingStudent}
                title="Are you sure?"
            >
                <p>
                    {!!item.adminUser ? 'This student is connected to an admin account. ' : ''}Are you sure you want to remove this student? This action cannot be undone.
                </p>

                <ErrorInline error={deleteStudentError} />

            </Modal>

            <Modal
                confirmButtonOnClick={handlePromoteStudent}
                confirmButtonText="Promote"
                declineButtonOnClick={() => {
                    setShowConfirmPromoteModal(false);
                }}
                declineButtonText="Cancel"
                show={showConfirmPromoteModal}
                showActivityIndicator={isInitiatingProfilePromotion}
                title="Are you sure?"
            >

                <ErrorInline error={initiateProfilePromotionError} />

            </Modal>
        </>
    );
};

export default StudentsTableMoreMenu;
